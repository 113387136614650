import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { alertCircleOutline, chatbubbleEllipses, construct, create, documentText } from 'ionicons/icons';

interface ParamTypes {
    employeeID: string
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    status: string,
    createdAt: Date
}

const EmployeeDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {employeeID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [departmentName, setDepartmentName] = useState < string > ("");
    const [userID, setUserID] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [note, setNote] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleMessage = () => {

        if (!note || note === "") {
            setMessage(t("notif_message_mandatory"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("notif_title_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const notifyEmployee = () => {
          return new Promise((resolve, reject) => {
              api.post(`/employees/notification`, { userID, title, 'text': note, 'type': 'general' }).then(res => {
                  return resolve(res.data);
                }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        notifyEmployee()
        .then(data => {
          setPass(t("notif_sent"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
        }    
    }

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/employees/updatePhoto', formData).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
    
            });
          }

      let formData = new FormData();
      formData.append('image', uploadFile, 'image');
      formData.append('employeeID', employeeID);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
        
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get('/employees/' + employeeID);
                setFileName(result.data.photo);
                setUserID(result.data.userInfo.userID);
                
                if (result.data.userInfo.userID !== "none")
                {
                    const apps = await api.get(`/staffChats/getFiveByEmployee/${employeeID}`);
                    setInquiries(apps.data);
                }

                setName(result.data.userInfo.name);
                setDepartmentName(result.data.departmentInfo.name);
               
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, employeeID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_employee")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_employee")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-60 mb-60">
                        <IonCol size="12" sizeLg="6">
                            <IonRow className="mt-30">
                                <IonCol className="ion-text-center" id="open-upload-input">
                                    <div className="vertical-container">
                                        <div className="vertical-center"> 
                                        {fileName && (fileName !== "") ?
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} width="70"  />)
                                            : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="70" />)
                                        }
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                           
                            <IonPopover trigger="open-upload-input" size="cover">
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("new_photo")}*</IonLabel>
                                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>           
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton onClick={handlePhoto}
                                            size="small"
                                            color="secondary">{t("update_photo")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>

                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <p>
                                        <span className="profile-text">{name}</span><br/>
                                        <span className='pt-05'>{departmentName}</span>
                                    </p>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                            <IonRow className="ion-margin-horizontal">
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/profile/${employeeID}`)}
                                        expand="block" color="primary">{t("basic_info")}</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/account/${employeeID}`)}
                                        expand="block" color="secondary">{t("employee_account")}</IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-horizontal">
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/attendance/${employeeID}`)}
                                        expand="block" color="tertiary">{t("attendance")}</IonButton>
                                </IonCol>
                            </IonRow>
                            {authInfo.institute.task && (authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                            <IonRow className="ion-margin-horizontal">
                                <IonCol>
                                     <IonButton onClick={ () => history.push(`/app/reports/employee/${employeeID}`)}
                                         expand="block" color="success">{t("daily_reports")}</IonButton>
                                 </IonCol>
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/tasks/employee/${employeeID}`)}
                                        expand="block" color="danger">{t("view_tasks")}</IonButton>
                                </IonCol>
                                
                            </IonRow>
                            )}
                        </IonCol>
                    </IonRow>
                    {(userID !== "none") && (
                        <IonRow className="mt-10">
                         <IonCol size="12" sizeLg="6">
                             <IonCard>
                                 <IonCardHeader>
                                     <IonCardSubtitle className='info-subtitle'>{t("notification")}</IonCardSubtitle>
                                 </IonCardHeader>
                                 <IonCardContent className="ion-no-padding ion-margin-bottom">
                                     <IonRow>
                                         <IonCol>
                                             <IonCard>
                                                 <IonCardContent className="ion-no-padding">
                                                     <IonItem color="light">
                                                         
                                                         <IonLabel position="stacked" className="input-label" color="secondary">
                                                             {t("notif_title")}*
                                                         </IonLabel>
                                                             <IonInput type="text"
                                                                 className="input-field"
                                                                 placeholder={t("notif_title")}
                                                                 value={title}
                                                                 onIonInput={
                                                                     (e) => setTitle(e.detail.value!)
                                                             }></IonInput>
                                                     </IonItem>
                                                 </IonCardContent>
                                             </IonCard>
                                         </IonCol>
                                     </IonRow>
                                     <IonRow>
                                         <IonCol>
                                             <IonCard>
                                                 <IonCardContent className="ion-no-padding">
                                                     <IonItem color="light">
                                                         
                                                         <IonLabel position="stacked" className="input-label" color="secondary">
                                                             {t("notif_message")}*
                                                         </IonLabel>
                                                             <IonInput type="text"
                                                                 className="input-field"
                                                                 placeholder={t("notif_message")}
                                                                 value={note}
                                                                 onIonInput={
                                                                     (e) => setNote(e.detail.value!)
                                                             }></IonInput>
                                                     </IonItem>
                                                 </IonCardContent>
                                             </IonCard>
                                         </IonCol>
                                     </IonRow>
                                    
                                     <IonRow className="ion-margin-top">
                                         <IonCol className="ion-text-center">
                                             <IonButton onClick={() => handleMessage()}
                                                 color='secondary'>{t("send_notif")}</IonButton>
 
                                         </IonCol>
                                     </IonRow>
                                           
                                 </IonCardContent>
                             </IonCard>
                         </IonCol>
                         <IonCol size="12" sizeLg="6">
                             <IonCard>
                                 <IonCardHeader>
                                     <IonRow>
                                        <IonCol size="6">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("recent_inquiries")}</IonCardSubtitle>
                                        </IonCol>
                                        {(authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                        <IonCol size="6" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/chats/employee/${employeeID}`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                        )}
                                    </IonRow>
                                 </IonCardHeader>
                                 <IonCardContent className="ion-no-padding ion-margin-bottom">
                                 {(inquiries.length > 0) ? (
                                 <>
                                     <IonRow className="ion-padding-vertical">
                                         <IonCol>
                                             <IonList className="ion-no-padding">
                                                {(inquiries.map((inquiry) => {
                                                     return (
                                                         <IonItem detail={true}
                                                             detailIcon={construct}
                                                             lines='full'
                                                             button={true}
                                                             key={inquiry._id}
                                                             onClick={
                                                                 () => history.push(`/app/chat/${inquiry._id}`)
                                                             }>
                                                             <IonIcon slot="start"
                                                                 icon={(inquiry.type === "task") ? create : (inquiry.type === "leave") ? documentText : chatbubbleEllipses}
                                                                 color={(inquiry.type === "task") ? 'primary' : (inquiry.type === "leave") ? 'tertiary' : (inquiry.status === 'closed') ? 'danger' : 'success'}/>
                                                             <IonLabel className="list-label">
                                                                 <h3>{inquiry.title}<br/>{formatDated(inquiry.createdAt)}</h3>
                                                             </IonLabel>
                                                         </IonItem>
                                                     )
                                                 }))
                                                 } 
                                             </IonList>
                                         </IonCol>
                                     </IonRow>
                                 </>
                                 ) : (
                                     <IonRow className="ion-padding-vertical">
                                         <IonCol>
                                             <IonItem lines="none" className='red-card ion-margin'>
                                                 <IonIcon icon={alertCircleOutline}
                                                 slot="start"
                                                 color="danger" />
                                                 <IonLabel className="list-title">
                                                         <h3>{t("no_inquiries")}</h3></IonLabel>
 
                                             </IonItem>
                                         </IonCol>
                                     </IonRow>
                                 )}
 
                                 </IonCardContent>
                             </IonCard>
                         </IonCol>  
                     </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default EmployeeDetail;