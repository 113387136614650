import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Chat {
    _id: string,
    title: string,
    status: string,
    employee: ChatEmployee,
    createdAt: Date,
    taskInfo: TaskInfo
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface Employee {
    _id: string,
    userInfo: UserInfo,
    isActive: boolean
}

interface UserInfo {
    userID: string,
    name: string
}

interface Department {
    _id: string,
    name: string
}

interface ParamTypes {
    employeeID?: string
}

const EmployeeTasks: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [chats, setChats] = useState < Chat[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [selectedEmployee, setSelectedEmployee] = useState <string> ("");
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);
        
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getByDepartment/${departmentID}`);
                setEmployees(studs.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getAllInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            setShowLoading(false);
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

        setSelectedEmployee("");
        setChats([]);
        
    }

    const handleEmployee = (employeeID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/staffChats/getTaskByEmployee/${employeeID}`);
                setChats(result.data.tasks);
                setTotal(result.data.total);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedEmployee(employeeID);
    }

    const loadItems = async () => {

        if (!selectedEmployee || selectedEmployee === "") {
            setMessage(t("select_employee"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
                const result = await api.get(`/staffChats/getTaskByEmployee/${selectedEmployee}?skip=${limit}`);
                if(result.data.tasks && result.data.tasks.length)
                {
                    setChats([...chats, ...result.data.tasks]);
                }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setChats(storedState.chats || []);
                setEmployees(storedState.employees || []);
                setDepartments(storedState.departments || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setSelectedEmployee(storedState.selectedEmployee || "");
                setDepartment(storedState.department || "");
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {

                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                        setDepartments(res.data);
                            
                            if (employeeID)
                            {
                                const stud = await api.get(`/employees/${employeeID}`);  
                                setDepartment(stud.data.departmentInfo.departmentID);
            
                                const studs = await api.get(`/employees/getByDepartment/${stud.data.departmentInfo.departmentID}`);
                                setEmployees(studs.data);
                                setSelectedEmployee(stud.data.userInfo.userID);
            
                                const result = await api.get(`/staffChats/getTaskByEmployee/${stud.data.userInfo.userID}`);
                                setChats(result.data.tasks);
                                setTotal(result.data.total);
                            }
                            else
                            {
                                setDepartment("");
                                setEmployees([]);
                                setSelectedEmployee("");
                                setChats([]);
                                setTotal(0);
                            }
                            setSkip(0);
                        

                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                    setDepartments(res.data);
                        
                        if (employeeID)
                        {
                            const stud = await api.get(`/employees/${employeeID}`);  
                            setDepartment(stud.data.departmentInfo.departmentID);
        
                            const studs = await api.get(`/employees/getByDepartment/${stud.data.departmentInfo.departmentID}`);
                            setEmployees(studs.data);
                            setSelectedEmployee(stud.data.userInfo.userID);
        
                            const result = await api.get(`/staffChats/getTaskByEmployee/${stud.data.userInfo.userID}`);
                            setChats(result.data.tasks);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            setDepartment("");
                            setEmployees([]);
                            setSelectedEmployee("");
                            setChats([]);
                            setTotal(0);
                        }
                        setSkip(0);

                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, employeeID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            chats,
            departments,
            employees,
            total,
            skip,
            selectedEmployee,
            department,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [chats, departments, employees, total, skip, selectedEmployee, department, scrollPosition, history.location.pathname, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("employee_tasks")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("employee_tasks")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
      
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_emp")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_emp")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleEmployee(e.detail.value)
                                                }>
                                                    {employees.map((employee) => (
                                                        (employee.isActive) &&
                                                        (<IonSelectOption key={employee._id} value={`${employee.userInfo.userID}`} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("title")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                            {t("current_status")}
                            </IonCol>
                           
                            <IonCol size="3" className='table-heading'>
                            {t("dates")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(chats.length > 0) ? (chats.map((chat, i) => {
                                return (
                                    <IonRow key={chat._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(chat._id)
                                    }
                                    onDoubleClick={
                                        () => history.push(`/app/task/${chat._id}`)
                                    }>
                                        
                                        <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                    <IonRadio value={chat._id} className='table-icon' color="secondary" />
                                                        <IonText className='ion-margin-start text-capitalize'>{chat.title}</IonText>
                                                        <IonBadge className='ml-05' color={(chat.taskInfo.priority === "high") ? "danger" : (chat.taskInfo.priority === "medium") ? "warning" : "medium"}>
                                                         {t(chat.taskInfo.priority)}
                                                        </IonBadge>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                         {t(chat.status)}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                            
                                            <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down'>
                                                {t(chat.status)} 
                                               
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(chat.taskInfo.startDate)} {t("to")} {formatDate(chat.taskInfo.endDate)}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/task/${chat._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_tasks")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(chats.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${chats.length} ${t("of")} ${total}`} 
                                    </IonCol>
                                </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_task"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/task/${current}/edit`);
                                            }

                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("edit_task")}</IonButton>

                           
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default EmployeeTasks;