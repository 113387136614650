import {
    AlertInput,
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CapacitorHttp } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { useHistory } from 'react-router-dom';
import { alertCircleOutline, busOutline, chatbubbles, chevronForward, chevronForwardOutline, create, notifications, time } from 'ionicons/icons';
import { SendIntent } from "send-intent";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Geolocation } from '@capacitor/geolocation';
import { Location } from "@capacitor-community/background-geolocation";
import BackgroundGeolocation from "../../components/BackgroundGeolocation";
import {useAuth} from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import CircularsSwiper from '../../components/ui/CircularsSwiper';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Trip {
    _id: string,
    name: string,
    type: string,
    students: TripStudent[],
    startedAt: Date
}
interface TripStudent {
    studentID: string,
    name: string
}

interface Route {
    _id: string,
    name: string,
    type: string,
    startTime: string,
    students: TripStudent[],
    isActive: boolean
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

const DriverHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ongoingTrip, setOngoingTrip] = useState < Trip[] > ([]);
    const [showAlert4, setShowAlert4] = useState(false);
    const [students, setStudents] = useState < AlertInput[] > ([]);
    const [drop, setDrop] = useState < Route > ();
    const [refreshed, setRefreshed] = useState < number > (0);
    const [routes, setRoutes] = useState < Route[] > ([]);
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [isSuspended, setIsSuspended] = useState < boolean > (false);
    const [pickup, setPickUp] = useState < number > (0);
    const [dropR, setDropR] = useState < number > (0);
    const [attendance, setAttendance]= useState < Attendance > ();
    const [unread, setUnread] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };
   
    const startTrip = (rout: Route) => {

        if (rout.type === 'pickup')
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const upTrip = (routeID: string) => {
                return new Promise((resolve, reject) => {
                  api.post('/trips/addBusRoute', { routeID }).then(res => {
                    return resolve(res.data.tripID);
                  }).catch(err => reject(err));
        
                });
              }
          
            upTrip(rout._id)
              .then(data => {

                    setShowLoading(false);
                    history.push(`/app/trip/ongoing/${data}`);
              })
              .catch(error => {
                  if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("try_again"));
                setIserror(true);
                setShowLoading(false);
              });

        }
        else if (rout.type === 'drop')
        {
            let studs: Array < AlertInput > = [];
            for (let i = 0; i < rout.students.length; i++) {
                
                let studObj: AlertInput = {
                    name: 'student'+i,
                    type: 'checkbox',
                    label: rout.students[i].name,
                    value: rout.students[i].studentID,
                    checked: true
                  };

                studs.push(studObj);
                    
            }
            setDrop(rout);
            setStudents(studs);
            setShowAlert4(true);
        }

    }

    const startDrop = (data: any) => {

        if (drop)
        {
            let studs: Array < TripStudent > = [];

            for (let i = 0; i < drop.students.length; i++) {

                if (data.indexOf(drop.students[i].studentID) > -1) {
                    studs.push(drop.students[i]);
                }
                    
            }

            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const upTrip = (routeID: string, students: TripStudent[]) => {
                return new Promise((resolve, reject) => {
                  api.post('/trips/addBusRoute', { routeID, 'students': students }).then(res => {
                    return resolve(res.data.tripID);
                  }).catch(err => reject(err));
        
                });
              }
          
              setShowLoading(true);
            upTrip(drop._id, studs)
              .then(data => {

                    setShowLoading(false);
                    history.push(`/app/trip/ongoing/${data}`);
              })
              .catch(error => {
                  if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("try_again"));
                setIserror(true);
                setShowLoading(false);
              });
        }
    }

    const finishTrips = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upTrip = () => {
            return new Promise((resolve, reject) => {
                api.get('/drivers/finish/'+authInfo.user._id).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));   
            });
        }
    
        setShowLoading(true);
      upTrip()
        .then(() => {

            setMessage(t("all_trips_finished"));
            setIserror(true);
            setShowLoading(false);
            setRefreshed(Math.random());
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("try_again"));
          setIserror(true);
          setShowLoading(false);
        });
        
      }
   
    useEffect(() => {

        let watchID: string = "";

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upUser = (push: Token) => {
            return new Promise((resolve, reject) => {
              api.put('/users/'+authInfo.user._id, { 'push': push.value }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
          }

          const register = () => {
            
            // If new device, this will run everytime
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
    
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: Token) => {
                    if(!authInfo.user.push)
                    {
                        upUser(token);
                    }
                    else if (authInfo.user.push !== token.value)
                    {
                        upUser(token);
                    }
                  
                }
            );
    
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    setMessage(t("push_error"));
                    setIserror(true);
                }
            );
    
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {

                }
            );
    
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    
                }
            );
        }

        const fetchData = async () => {
            setShowLoading(true);
            try {

                if (!authInfo.institute.transport)
                {
                    setIsSuspended(true);
                   
                }

                const circ = await api.get(`/circulars/getSomeByRole/${authInfo.user.instituteID}/${authInfo.user.role}`);
                setCirculars(circ.data);

                const routei = await api.get(`/routes/getByDriver/${authInfo.user._id}`);
                setRoutes(routei.data);

                if (routei.data.length > 0)
                {
                    let drops = 0;
                    let pickups = 0;
                
                    for (let i = 0; i < routei.data.length; i++)
                    {
                        if(routei.data[i].type === 'drop')
                        {
                            drops = drops + 1;
                        }
                        if(routei.data[i].type === 'pickup')
                        {
                            pickups = pickups + 1;
                        }
                    }
                    setPickUp(pickups);
                    setDropR(drops);

                }

                const ongoing = await api.get(`/trips/ongoing/${authInfo.user._id}`);
                setOngoingTrip(ongoing.data);
                
                const chat = await api.get(`/staffChats/getOneByEmployee/${authInfo.user._id}`);
                setChats(chat.data);

                const task = await api.get(`/staffChats/getHomeByEmployee/${authInfo.user._id}`);
                setTasks(task.data);

                const entry = await api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`);
                setAttendance(entry.data);

                const count = await api.get(`/notifications/getCountUser/${authInfo.user._id}`);
                setUnread(count.data);

                setShowLoading(false);

                if ((ongoing.data.length > 0) && isPlatform("capacitor"))
                {
                        Geolocation.checkPermissions().then((res) => {
                        if (res.location !== 'granted') {
                            Geolocation.requestPermissions().then((ress) => {
                                if (ress.location === 'denied') {
                                    setMessage(t("bg_denied"));
                                    setIserror(true);
                                }
                                else if (ress.location === 'granted') {
        
                                    BackgroundGeolocation.addWatcher(
                                        {
                                            backgroundMessage: t("tracking_your_location"),
                                            backgroundTitle: t("ongoing_route"),
                                            requestPermissions: false,
                                            stale: true,
                                            distanceFilter: 20
                                        },
                                        function callback(location, error) {
                                            if (error)
                                            {
                                                setMessage(t("location_issue"));
                                                setIserror(true);
                                            }
                                            if (location) {
                                                sendLocation(location); 
                                            }
        
                                        }
                                    ).then(function retain_callback_id(the_id) {
                                        watchID = the_id;
                                    });
                               
        
                                }
                            });

                        }
                        else {

                                BackgroundGeolocation.addWatcher(
                                    {
                                        backgroundMessage: t("tracking_your_location"),
                                        backgroundTitle: t("ongoing_route"),
                                        requestPermissions: false,
                                        stale: true,
                                        distanceFilter: 20
                                    },
                                    function callback(location, error) {
                                        if (error)
                                        {
                                            setMessage(t("location_issue"));
                                            setIserror(true);
                                        }
                                        if (location) {
                                            sendLocation(location);
                                        }
                                    }
                                ).then(function retain_callback_id(the_id) {
                                    watchID = the_id;
                                });
                          
                        }
                    }).catch(err =>  {
                        setMessage(t("location_issue"));
                        setIserror(true);
                    });
               
            }
              

            const sendLocation = async (position: Location) => {
                try {
                    await CapacitorHttp.request({
                        method: "PUT",
                        url: process.env.REACT_APP_API_URL + "/drivers/" + authInfo.user._id,
                        headers: {
                        Authorization: "Bearer " + authInfo.token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        },
                        data: {
                        lat: position.latitude,
                        lon: position.longitude,
                        },
                    });
                }   catch (error : any) {

                    setMessage(t("location_network"));
                    setIserror(true);
                }
            };
                
        } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
                setShowLoading(false);
            }
            
        };

        const checkIntent = () => {
           
            SendIntent.checkSendIntentReceived().then((result : any) => {
                if (result) {
                        
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                    
                }
                else
                {
                    fetchData();
                }
            })
            .catch((err: any) => {
                fetchData();
            });

        }

        const fetchVersion = async () => {

            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                else
                {
                    if (isPlatform('android') && isPlatform('capacitor'))
                    {
                        checkIntent();
                    }
                    else
                    {
                        fetchData();
                    }

                    if (isPlatform('capacitor'))
                    {

                        PushNotifications.checkPermissions().then((res) => {
                            if (res.receive !== 'granted') {
                            PushNotifications.requestPermissions().then((ress) => {
                                if (ress.receive === 'denied') {
                                    setMessage(t("push_denied"));
                                    setIserror(true);
                                }
                                else if (ress.receive === 'granted') {
                                    register();
                                }
                            });
                            }
                            else {
                                register();
                            }
                        });
                    }

                }

                
            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }

            }
            
        };
         
        fetchVersion();
        

        return () => {
            if(watchID !== "")
            {
                BackgroundGeolocation.removeWatcher({id: watchID});
            }
        }

    }, [authInfo, refreshed]);

    return (
        <IonPage>
             <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonAlert
                isOpen={showAlert4}
                onDidDismiss={() => setShowAlert4(false)}
                header={t("select_students")}
                inputs={students}
                buttons={[
                    {
                    text: t("cancel"),
                    role: 'cancel'
                    },
                    {
                    text: t("start"),
                    handler: (data) => {
                        startDrop(data);
                    }
                    }
                ]}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isSuspended}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("suspended")}
                    message={t("account_suspended")}
                    buttons={[`${t("logout")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("ongoing_trips")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {
                            (ongoingTrip.length > 0) ? (ongoingTrip.map((trip) => {
                                return (
                                        <IonCard className={trip.type === 'drop' ? 'announce-card' : 'circular-card'} key={trip._id} 
                                               routerLink={`/app/trip/ongoing/${trip._id}`}>
                                             <IonCardContent>
             
                                                    <IonRow>
                                                        <IonCol size="3">
                                                            <IonIcon className='second-icon mt-05'
                                                                icon={busOutline}
                                                                color={trip.type === 'drop' ? 'secondary' : 'primary'} />
                                                            </IonCol>
                                                            <IonCol size="8">
                                                                <IonText className='driver-route-text'>{trip.name}<br/>
                                                                {t("started_at")} {formatDateTime(trip.startedAt)}
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol size="1" className='mt-05'>
                                                                
                                                                        <IonIcon className='second-icon'
                                                                            icon={chevronForwardOutline}
                                                                            color='dark'/>
                                                              
                                                            </IonCol>
                                                    </IonRow>
                    
                                            </IonCardContent>
                                            
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className='red-card'>

                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger"
                                                            size="large" />
                                                        <IonLabel className="list-title"><h3>{t("no_ongoing")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }
                        </IonCol>
                    </IonRow>

                    {(ongoingTrip.length > 0) && (
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonButton expand="block" color="dark" onClick={finishTrips}>{t("finish_trips")}</IonButton>    
                            </IonCol>
                        </IonRow>
                    )}

                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("pickup_routes")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(routes.length > 0) && (routes.map((rout) => 
                                (rout.type === 'pickup') &&
                                (
                                    <IonCard className="action-card-c" key={rout._id} >
                                        <IonCardContent>

                                                <IonRow>
                                                    <IonCol size="3">
                                                        <IonIcon className='first-icon'
                                                            icon={busOutline}
                                                            color='primary'/>
                                                        </IonCol>
                                                        <IonCol size="9" className="ion-text-right">
                                                            <IonText className='driver-route-text'>{rout.name}<br/>
                                                            {t("start_time")}: {(rout.startTime)}
                                                            </IonText>
                                                        </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton expand="block" className="second-button" color="light"
                                                        onClick={() => startTrip(rout)}
                                                        >{t("start")}</IonButton>

                                                    </IonCol>
                                                    <IonCol>
                                                        <IonButton expand="block" className="third-button" color="primary"
                                                        onClick={() => history.push(`/app/route/preview/${rout._id}`)}
                                                        >{t("route_preview")}</IonButton>

                                                    </IonCol>
                                                </IonRow>

                                        </IonCardContent>
                                    </IonCard>
                                    )
                                ))
                            }
                            {(pickup === 0) && (
                            <IonCard className='red-card'>

                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger"
                                            size="large" />
                                        <IonLabel className="list-title"><h3>{t("no_pickup")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("drop_routes")}
                                </IonTitle>   
                        </IonCol>
                       
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(routes.length > 0) && (routes.map((rout) => 
                                (rout.type === 'drop') &&
                                    (
                                        <IonCard className="action-card-b" key={rout._id} >
                                            <IonCardContent>

                                                    <IonRow>
                                                        <IonCol size="3">
                                                            <IonIcon className='first-icon'
                                                                icon={busOutline}
                                                                color='secondary'/>
                                                            </IonCol>
                                                            <IonCol size="9" className="ion-text-right">
                                                                <IonText className='driver-route-text'>{rout.name}<br/>
                                                                {t("start_time")}: {(rout.startTime)}
                                                                </IonText>
                                                            </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton expand="block" className="first-button" color="light"
                                                            onClick={() => startTrip(rout)}
                                                            >{t("start")}</IonButton>

                                                        </IonCol>
                                                        <IonCol>
                                                            <IonButton expand="block" className="third-button" color="secondary"
                                                            onClick={() => history.push(`/app/route/preview/${rout._id}`)}
                                                            >{t("route_preview")}</IonButton>

                                                        </IonCol>
                                                    </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                    )
                                   ))
                                }
                                {(dropR === 0) && (
                                <IonCard className='red-card'>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger"
                                                size="large" />
                                            <IonLabel className="list-title"><h3>{t("no_drop")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                                )}
                        </IonCol>
                    </IonRow>

                    {(attendance) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("attendance_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    (attendance.status === 'in') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attendance.status === 'in') ? 'success' : 'danger'
                                                    }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{t("your_out_time")} </h3>
                                                                <p>on {formatDated(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{t("your_in_time")} </h3>
                                                                <p>on {formatDated(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {(attendance.status === 'in') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={() => history.push('/app/user/attendance')}>{t("mark_exit")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                </>
                )}

                {circulars && (circulars.length > 0) && 
                (<CircularsSwiper circulars={circulars} />)}
                
                    <IonRow className="ion-margin-vertical">
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>
                    {(tasks.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_tasks")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/user/tasks`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(tasks.map((chat, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={chat._id}
                                                            onClick={
                                                                () => history.push(`/app/user/task/${chat._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={create}
                                                                color='secondary'/>
                                                            <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                <h3>{chat.title}
                                                                <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                    {t(chat.taskInfo!.priority)}
                                                                </IonBadge>
                                                                <br/> 
                                                                {t(chat.status)} <br/>
                                                                {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                </h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                
                                    
                                </IonCardContent>
                            </IonCard>
                        )}

                    {(chats.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_chats")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/user/chats`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(chats.map((chat, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={chat._id}
                                                            onClick={
                                                                () => history.push(`/app/user/chat/${chat._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={chatbubbles}
                                                                color='secondary'/>
                                                            <IonLabel className="list-label text-capitalize">
                                                                <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                
                                    
                                </IonCardContent>
                            </IonCard>

                    )}
                                </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default DriverHome;
