import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import TransporterHome from "../pages/Home/TransporterHome";
import ManageDrivers from "../pages/Employee/Dashboard/ManageDrivers";
import InstituteLocation from "../pages/Admin/School/InstituteLocation";

import StudentDetail from "../pages/Admin/Student/StudentDetail";
import StudentLocation from "../pages/Admin/Student/StudentLocation";
import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import Drivers from "../pages/Employee/Driver/Drivers";
import AddDriver from "../pages/Employee/Driver/AddDriver";
import EmployeeDetail from "../pages/Admin/Staff/EmployeeDetail";
import BasicInfo from "../pages/Admin/Staff/BasicInfo";
import AccountInfo from "../pages/Admin/Staff/AccountInfo";
import EmployeeAttendance from "../pages/Admin/Staff/EmployeeAttendance";
import StaffAttend from "../pages/Admin/Staff/StaffAttend";
import AttendView from "../pages/Admin/Staff/AttendView";
import StaffEntryExit from "../pages/Admin/Staff/StaffEntryExit";
import StaffLeaves from "../pages/Admin/Staff/StaffLeaves";
import ChatDetail from "../pages/Admin/Staff/Chat/ChatDetail";

import DriverStatus from "../pages/Employee/Driver/DriverStatus";
import DriverLog from "../pages/Employee/Driver/DriverLog";

import Routes from "../pages/Admin/Route/Routes";
import AddRoute from "../pages/Admin/Route/AddRoute";
import RouteDetail from "../pages/Admin/Route/RouteDetail";
import RouteStudent from "../pages/Admin/Route/RouteStudent";
import CompletedTrips from "../pages/Admin/Trip/CompletedTrips";
import CompletedTrip from "../pages/Admin/Trip/CompletedTrip";
import OngoingTrips from "../pages/Admin/Trip/OngoingTrips";
import OngoingTrip from "../pages/Admin/Trip/OngoingTrip";
import TripsAlerts from "../pages/Admin/Trip/TripsAlerts";
import Vehicles from "../pages/Admin/Vehicle/Vehicles";
import AddVehicle from "../pages/Admin/Vehicle/AddVehicle";
import VehicleDetail from "../pages/Admin/Vehicle/VehicleDetail";
import VehicleExpenses from "../pages/Admin/Vehicle/VehicleExpenses";
import TransportExpense from "../pages/Admin/Transaction/TransportExpense";

import MyTasks from "../pages/Employee/Task/MyTasks";
import MyTaskDetail from "../pages/Employee/Task/MyTaskDetail";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import UserNotifications from "../pages/User/UserNotifications";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const TransporterTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={TransporterHome} />

          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/tracking" exact={true} component={OngoingTrips} />
          <Route path="/app/trip/ongoing/:tripID" exact={true} component={OngoingTrip} />
          <Route path="/app/config/institute/location" exact={true} component={InstituteLocation} />

          <Route path="/app/drivers" exact={true} component={ManageDrivers} />
          <Route path="/app/drivers/manage" exact={true} component={Drivers} />
          <Route path="/app/drivers/add" exact={true} component={AddDriver} />
          <Route path="/app/employee/:employeeID" exact={true} component={EmployeeDetail} />
          <Route path="/app/employee/profile/:employeeID" exact={true} component={BasicInfo} />
          <Route path="/app/employee/account/:employeeID" exact={true} component={AccountInfo} />
          <Route path="/app/employee/attendance/:employeeID" exact={true} component={EmployeeAttendance} />
          <Route path="/app/attend/staff/mark/:role?" exact={true} component={StaffAttend} />
          <Route path="/app/attend/staff/view/:role?" exact={true} component={AttendView} />
          <Route path="/app/attend/staff/leave/:role?" exact={true} component={StaffLeaves} />
          <Route path="/app/attend/staff/entryexit/:role?" exact={true} component={StaffEntryExit} />
          <Route path="/app/attendance/driver/status" exact={true} component={DriverStatus} />
          <Route path="/app/attendance/driver/log/:employeeID?" exact={true} component={DriverLog} />
          <Route path="/app/chat/:chatID" exact={true} component={ChatDetail} />

          <Route path="/app/student/:studentID" exact={true} component={StudentDetail} />
          <Route path="/app/student/location/:studentID" exact={true} component={StudentLocation} />

          <Route path="/app/routes" exact={true} component={Routes} />
          <Route path="/app/routes/add" exact={true} component={AddRoute} />
          <Route path="/app/route/:routeID" exact={true} component={RouteDetail} />
          <Route path="/app/mapstudents/:routeID/:studentID" exact={true} component={RouteStudent} />
          <Route path="/app/trips/completed" exact={true} component={CompletedTrips} />
          <Route path="/app/trip/detail/:tripID" exact={true} component={CompletedTrip} />

          <Route path="/app/trips/alerts" exact={true} component={TripsAlerts} />
          <Route path="/app/vehicles" exact={true} component={Vehicles} />
          <Route path="/app/vehicles/add" exact={true} component={AddVehicle} />
          <Route path="/app/vehicle/:vehicleID" exact={true} component={VehicleDetail} />
          <Route path="/app/vehicle/expenses/:vehicleID" exact={true} component={VehicleExpenses} />
          <Route path="/app/transactions/transport/:vehicleID?" exact={true} component={TransportExpense} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:taskID" exact={true} component={MyTaskDetail} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />

          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to="/app/home" />
          <Redirect from="*" to="/app"/>
        </Switch>
      </IonRouterOutlet>
  );
};

export default TransporterTabs;
