import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {Link, useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { informationCircleOutline } from 'ionicons/icons';
import SwitchBranch from '../../../components/SwitchBranch';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const AddHeadDues: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [monthly, setMonthly] = useState < boolean > (false);
    const [quaterly, setQuaterly] = useState < boolean > (false);
    const [annually, setAnnually] = useState < boolean > (false);
    const [mDuration, setMduration] = useState < string > ("");
    const [qDuration, setQduration] = useState < string > ("");
    const [aDuration, setAduration] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

    
    const handleSubmit = () => {

        if(!monthly && !quaterly && !annually)
        {
            setMessage(t("head_mandatory"));
            setIserror(true);
            return;
        }
        if (monthly && mDuration === "")
        {
            setMessage(t("monthly_duration"));
            setIserror(true);
            return;
        }
        if (quaterly && qDuration === "")
        {
            setMessage(t("quaterly_duration"));
            setIserror(true);
            return;
        }
        if (annually && aDuration === "")
        {
            setMessage(t("annualy_duration"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("due_date_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const schoolDues = ( dueDate: string) => {
          return new Promise((resolve, reject) => {
            api.post(`/dues/addAll`, { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, dueDate, monthly, quaterly, annually, mDuration, qDuration, aDuration }).then(res => {
      
              return resolve(res.data);
              
            }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
      schoolDues(selectedDate)
        .then((data: any) => {
          
          setPass(t("total_added")+data.sum+"\n"+t("added_students")+data.num);
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_dues_head")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_dues_head")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {(authInfo.institute.type === "school") ? t("head_dues_info") : t("head_dues_insti")} <Link to={`/app/dues/addMany`}>{t("click_here")}</Link>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("dues_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                            
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("due_date")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("due_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("fee_heads")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                            <IonRow className="mt-30">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="6" className='table-heading'>
                                                {t("frequency")}
                                        </IonCol>
                                        <IonCol size="6" className='table-heading'>
                                                {t("due_duration")}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="monthly" checked={monthly} 
                                                onClick={e => setMonthly(!monthly)} >
                                                {t("monthly")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field' >
                                            <IonItem>
                                                
                                                <IonSelect placeholder={t("due_duration")}
                                                    className='ion-no-padding' 
                                                    style={{'maxWidth': '100%'}} 
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    value={mDuration}
                                                    onIonChange={
                                                        (e) => setMduration(e.detail.value!)
                                                    }
                                                    >
                                                    <IonLabel>{t("due_duration")}</IonLabel>
                                                    <IonSelectOption value="Jan">{t("jan")}</IonSelectOption>
                                                    <IonSelectOption value="Feb">{t("feb")}</IonSelectOption>      
                                                    <IonSelectOption value="Mar">{t("mar")}</IonSelectOption>   
                                                    <IonSelectOption value="Apr">{t("apr")}</IonSelectOption>   
                                                    <IonSelectOption value="May">{t("may")}</IonSelectOption>
                                                    <IonSelectOption value="Jun">{t("jun")}</IonSelectOption>      
                                                    <IonSelectOption value="Jul">{t("jul")}</IonSelectOption>   
                                                    <IonSelectOption value="Aug">{t("aug")}</IonSelectOption>   
                                                    <IonSelectOption value="Sep">{t("sep")}</IonSelectOption>
                                                    <IonSelectOption value="Oct">{t("oct")}</IonSelectOption>      
                                                    <IonSelectOption value="Nov">{t("nov")}</IonSelectOption>   
                                                    <IonSelectOption value="Dec">{t("dec")}</IonSelectOption>   
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="quaterly" checked={quaterly} 
                                                onClick={e => setQuaterly(!quaterly)} >
                                                {t("quaterly")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field'>
                                            <IonItem>
                                                
                                                 <IonSelect placeholder={t("due_duration")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={qDuration}
                                                        onIonChange={
                                                            (e) => setQduration(e.detail.value!)
                                                        }
                                                        >
                                                        <IonLabel>{t("due_duration")}</IonLabel>
                                                        <IonSelectOption value="Jan-Mar">{t("q4")}</IonSelectOption>
                                                        <IonSelectOption value="Apr-Jun">{t("q1")}</IonSelectOption>
                                                        <IonSelectOption value="Jul-Sep">{t("q2")}</IonSelectOption>      
                                                        <IonSelectOption value="Oct-Dec">{t("q3")}</IonSelectOption>
                                                        <IonSelectOption value="Feb-Apr">{t("q5")}</IonSelectOption>
                                                        <IonSelectOption value="Mar-May">{t("q6")}</IonSelectOption>      
                                                        <IonSelectOption value="May-Jul">{t("q7")}</IonSelectOption>   
                                                        <IonSelectOption value="Jun-Aug">{t("q8")}</IonSelectOption> 
                                                        <IonSelectOption value="Aug-Oct">{t("q9")}</IonSelectOption>
                                                        <IonSelectOption value="Sep-Nov">{t("q10")}</IonSelectOption>
                                                        <IonSelectOption value="Nov-Jan">{t("q11")}</IonSelectOption>      
                                                        <IonSelectOption value="Dec-Feb">{t("q12")}</IonSelectOption>  
                                                        
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="annually" checked={annually} 
                                                onClick={e => setAnnually(!annually)} >
                                                {t("annually")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field'>
                                            <IonItem>
                                                
                                                <IonSelect placeholder={t("due_duration")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={aDuration}
                                                        onIonChange={
                                                            (e) => setAduration(e.detail.value!)
                                                        }
                                                        >
                                                        <IonLabel>{t("due_duration")}</IonLabel>
                                                        {sessions.map((session) => (
                                                            (session.isActive) && 
                                                            (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                        ))}
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </div>
                                
                            </IonCol>
                        </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_dues_head")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddHeadDues;