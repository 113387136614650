import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, busOutline, chevronForwardOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Trip {
    _id: string,
    name: string,
    type: string,
    students: TripStudent[],
    driver: TripDriver,
    scheduledAt: Date,
    startedAt: Date,
    finishedAt: Date
}
interface TripStudent {
    studentID: string,
    name: string,
    order: number
}
interface TripDriver {
    driverID: string,
    name: string
}


const OngoingTrips: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ongoingTrips, setOngoingTrips] = useState < Trip[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

      const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      };

    const finishTrips = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upTrip = () => {
            return new Promise((resolve, reject) => {
                api.get('/trips/ongoing/finishByInstitute/'+authInfo.user.instituteID).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));   
            });
        }
    
        setShowLoading(true);
      upTrip()
        .then(() => {

            setMessage(t("trips_finished"));
            setIserror(true);
            setShowLoading(false);
            setRefreshed(Math.random());
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
          setIserror(true);
          setShowLoading(false);
        });
        
      }
   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/trips/ongoing/getByInstitute/${authInfo.user.instituteID}`);
                setOngoingTrips(result.data);
                setShowLoading(false);
            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            }
        };

     
        fetchData();

    }, [authInfo, refreshed]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/transport`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("realtime_tracking")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("realtime_tracking")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-no-padding ion-margin-top max-xl">
                        
                        {
                            (ongoingTrips.length > 0) ? (ongoingTrips.map((trip) => {
                                return (
                                    <IonCol key={trip._id} size="12" sizeLg="6" className="ion-no-padding ion-no-margin">
                                        <IonCard className={trip.type === 'drop' ? "announce-card ion-margin-top" : "circular-card ion-margin-top"} 
                                            routerLink={`/app/trip/ongoing/${trip._id}`} >
                                            <IonCardContent>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="3">
                                                            <IonIcon className='first-icon mt-05'
                                                                icon={busOutline}
                                                                color={trip.type === 'drop' ? 'secondary' : 'primary'} />
                                                            </IonCol>
                                                            <IonCol size="8">
                                                                <IonText className='action-text'>{trip.name} ({trip.type})<br/>
                                                                    {t("started")}: {formatDateTime(trip.startedAt)}<br/>
                                                                    {t("no_of_students")}: {trip.students.length}<br/>
                                                                    {t("driver")}: {trip.driver.name}
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol size="1" className='mt-20'>
                                                                
                                                                        <IonIcon className='second-icon'
                                                                            icon={chevronForwardOutline}
                                                                            color='dark'/>
                                                              
                                                            </IonCol>
                                                    </IonRow>
                                                   
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                        );
                                        })) : (
                                        <IonCol>
                                            <IonCard className='red-card'>

                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger"
                                                            size="large" />
                                                        <IonLabel className="list-title"><h3>{t("no_ongoing")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        )
                                    }

                    </IonRow>
                </IonGrid>
            </IonContent>
            {(ongoingTrips.length > 0) && (
                <IonFooter className='ion-padding footer-shade'>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonButton onClick={finishTrips}
                                    className="first-button"
                                    fill="clear">{t("finish_trips")}</IonButton>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonFooter>
            )}
        </IonPage>
    );
};

export default OngoingTrips;
