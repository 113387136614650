import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    workshopID: string
}

const UpdateWorkshop: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {workshopID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [dur, setDur] = useState < number > (0);
    const [durr, setDurr] = useState < string > ("");
    const [admnFees, setAdmnFees] = useState < number > (0);
    const [name, setName] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ('');
    const [showAlert, setShowAlert] = useState < boolean > (false);

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {

                    const api = axios.create({
                        baseURL: process.env.REACT_APP_API_URL,
                        timeout: parseInt(process.env.REACT_APP_API_TO!),
                        headers: {
                            'Authorization': 'Bearer ' + authInfo.token
                          }
                      });

                    const upStud = ( formData: FormData) => {
                        return new Promise((resolve, reject) => {
                          api.post('/admissionCourses/updatePhoto', formData).then(res => {
                            return resolve(res.data.course.name);
                          }).catch(err => reject(err));
                
                        });
                      }
            
                    let formData = new FormData();
                    formData.append('course', file, 'course');
                    formData.append('courseID', workshopID);
              
                    setShowLoading(true);
                    upStud(formData)
                    .then(async (data) => {
                        
                        const blob = await fileToDataUri(file);
                        setImageURL(blob);   
                        setShowLoading(false);
                    })
                    .catch(error => {
                        if (error.response !== undefined)
                            setMessage(error.response.data.message);
                        else
                            setMessage(t("something_went_wrong"));
            
                        setIserror(true);
                        setShowLoading(false);
                    });
                    
                    
                }
            } 
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("workshop_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("workshop_desc_mandatory"));
            setIserror(true);
            return;
        }

        if (!durr || durr === "" || dur === 0) {
            setMessage(t("workshop_dur_mandatory"));
            setIserror(true);
            return;
        }

        const duration = dur + " " + durr;

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const upCourse = (applicationFees: number, startDate: string | string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/admissionCourses/'+workshopID, { name, duration, applicationFees, startDate, text }).then(res => {
                    return resolve(res.data.name);
              }).catch(err => reject(err));
            });
          }

            
          setShowLoading(true);
            upCourse(admnFees, selectedDate)
            .then(data => {
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
    }

    const handleDelete = () => {
 
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.delete(`/admissionCourses/${workshopID}`).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
      deleteApp()
        .then(() => {
  
          setPass(t("workshop_removed"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/admissionCourses/' + workshopID);
                
                setName(result.data.name);
                setText(result.data.text);
                
                setImageURL(`${process.env.REACT_APP_STATIC_S3}/images/${result.data.photo}`);
                setAdmnFees(result.data.applicationFees);
                setSelectedDate(result.data.startDate);   
                const duration = result.data.duration.split(" ");
                setDur(parseInt(duration[0]));
                setDurr(duration[1]);
              
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, workshopID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/workshops`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("update_workshop")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("update_workshop")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/workshops`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete_workshop")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("workshop_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("workshop_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("workshop_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("workshop_duration")}*
                                                    </IonLabel>
                                                    <IonRow>
                                                        <IonCol size="6">
                                                            <IonInput type="number"
                                                                className="input-field"
                                                                placeholder={t("workshop_duration")}
                                                                value={dur}
                                                                onIonInput={
                                                                    (e) => setDur(parseInt(e.detail.value!))
                                                            }></IonInput>
                                                        </IonCol>
                                                        <IonCol size="6">
                                                            <IonSelect value={durr}
                                                                style={{'maxWidth': '100%'}} 
                                                                className="input-field"
                                                                placeholder={t("workshop_duration")}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                onIonChange={
                                                                    (e) => setDurr(e.detail.value)
                                                                }>
                                                                <IonSelectOption value="Hour(s)">{t("hour_s")}</IonSelectOption> 
                                                                <IonSelectOption value="Day(s)">{t("day_s")}</IonSelectOption>
                                                                <IonSelectOption value="Month(s)">{t("month_s")}</IonSelectOption> 
                                                            </IonSelect>
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                   
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" color="secondary">{t("application_fees")}*</IonLabel>
                                                <IonInput type="number"
                                                    value={admnFees}
                                                    placeholder={t("application_fees")}
                                                    onIonInput={
                                                        (e) => setAdmnFees(parseInt(e.detail.value!))
                                                }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("workshop_start")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("workshop_start")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                           <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                <IonLabel position="stacked" color="secondary" className="input-label">{t("course_file")}*</IonLabel>
                                                <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)}></input>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {imageURL && (
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                        
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="320" />
        
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("workshop_desc")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("workshop_desc")}*</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                           
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_workshop")}</IonButton>

                            <IonButton onClick={() => setShowAlert(true)}
                                className="fifth-button"
                                fill="clear">{t("delete")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default UpdateWorkshop;
