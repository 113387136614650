import {
    IonButtons,
    IonContent,
    IonHeader,
    IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonCard,
    IonCardContent,
    IonItem,
    IonMenuButton,
    IonText,
    isPlatform,
    IonAvatar,
    IonLoading,
    IonAlert,
    IonPopover,
    IonButton
} from '@ionic/react';
import {
    accessibilityOutline,
    accessibilitySharp,
    logOutOutline,
    logOutSharp,
    personOutline,
    personSharp,
    refreshOutline,
    refreshSharp
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import {useAuth} from "../../components/AuthContext";
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import axios from 'axios';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    color: string;
}

const Profile: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [employeeID, setEmployeeID] = useState < string > ("");

    let appPages: AppPage[] = [];

    if (authInfo.user.role === "Parent") {
        appPages = [
            {
                title: t("personal_details"),
                url: '/app/user/profile/personal',
                iosIcon: personOutline,
                mdIcon: personSharp,
                color: 'tertiary'

            }, {
                title: t("students_details"),
                url: '/app/user/profile/student',
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                color: 'success'
            }, {
                title: t("change_password"),
                url: '/app/user/profile/password',
                iosIcon: refreshOutline,
                mdIcon: refreshSharp,
                color: 'primary'

            }
        ];
    } else {
        appPages = [
            {
                title: t("personal_details"),
                url: '/app/user/profile/personal',
                iosIcon: personOutline,
                mdIcon: personSharp,
                color: 'tertiary'

            }, {
                title: t("change_password"),
                url: '/app/user/profile/password',
                iosIcon: refreshOutline,
                mdIcon: refreshSharp,
                color: 'primary'

            }
        ];
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
        }    
      }

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/employees/updatePhoto', formData).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
    
            });
          }

      let formData = new FormData();
      formData.append('image', uploadFile, 'image');
      formData.append('employeeID', employeeID);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {

                if ((authInfo.user.role === "Driver") || (authInfo.user.role === "Transporter") || (authInfo.user.role === "Teacher") || (authInfo.user.role === "Accountant")
                || (authInfo.user.role === "Academic") || (authInfo.user.role === "AdmissionHead") || (authInfo.user.role === "Inventory") || (authInfo.user.role === "Employee"))
                {
                    const result = await api.get('/employees/getByUID/' + authInfo.user._id);
                    setFileName(result.data.photo);
                    setEmployeeID(result.data._id);
                }
             
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_profile")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_profile")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            window.location.reload();
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard className="profile-card">
                                        <IonCardContent>
                                            <IonRow >
                                                <IonCol size="3" className='ion-text-right' id="open-upload-input">
                                                    <IonAvatar className="leader-avatar">
                                                    {fileName && (fileName !== "") ?
                                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} />)
                                                        : (<img src={`assets/images/avatar1.png`} alt={t("profile_pic")} />)
                                                    }
                                                    </IonAvatar>
                                                </IonCol>
                                                <IonCol size="9" className='pl-08 pt-08 mt-05'>
                                                    <IonText className='profile-name'>{authInfo.user.name}</IonText><br/>
                                                    <IonText className='profile-email'>{authInfo.user.email}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {((authInfo.user.role === "Driver") || (authInfo.user.role === "Transporter") || (authInfo.user.role === "Teacher") || (authInfo.user.role === "Accountant")
                             || (authInfo.user.role === "Academic") || (authInfo.user.role === "AdmissionHead") || (authInfo.user.role === "Inventory") || (authInfo.user.role === "Employee")) &&
                            (
                            <IonPopover trigger="open-upload-input">
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("new_photo")}*</IonLabel>
                                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>           
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton onClick={handlePhoto}
                                            size="small"
                                            color="secondary">{t("update_photo")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                            )}

                            {
                            appPages.map((appPage, index) => {
                            return (
                            <IonRow key={index} >
                                <IonCol>
                                    <IonCard className={`ion-margin-top ${appPage.color}-card`}>
                                        <IonCardContent className="ion-padding">                                   
                                            <IonItem 
                                                detail={true}
                                                lines='none'
                                                button={true}
                                                onClick={
                                                    () => {
                                                        history.push(appPage.url);
                                                    }
                                            }>
                                                <IonIcon slot="start"
                                                    ios={
                                                        appPage.iosIcon
                                                    }
                                                    md={
                                                        appPage.mdIcon
                                                    }
                                                    color={appPage.color} />
                                                <IonLabel className="list-label-2" color={appPage.color}> {
                                                    appPage.title
                                                } </IonLabel>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                            })
                            }
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-margin-top red-card">
                                        <IonCardContent className="ion-padding">
                                            <IonItem button={true}
                                                onClick={
                                                    async () => {
                                                        await logOut();
                                                        history.replace('/login');
                                                }
                                                }
                                                lines="none"
                                                detail={true}>
                                                <IonIcon slot="start"
                                                    ios={logOutOutline}
                                                    md={logOutSharp}
                                                    color="danger" />
                                                <IonLabel className="list-label-2" color="danger">{t("logout")}</IonLabel>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Profile;
