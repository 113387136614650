import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { businessOutline, busOutline, call, locateSharp, location, navigate, notifications, qrCodeOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import { StartNavigation } from "@proteansoftware/capacitor-start-navigation";
import { CapacitorHttp } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { Toast } from '@capacitor/toast';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Location } from "@capacitor-community/background-geolocation";
import {NativeAudio} from '@capacitor-community/native-audio';
import BackgroundGeolocation from "../../components/BackgroundGeolocation";
import { useAuth } from "../../components/AuthContext";

interface ParamTypes {
    tripID: string
}

interface TripStudent {
    studentID: string,
    name: string,
    pname: string,
    address: string,
    fileName: string,
    lat: number,
    lon: number,
    phone: string,
    status: string
}

interface Institute {
    _id: string,
    name: string,
    phone: string,
    lat: number,
    lon: number,
    eta: number
}

const libraries = ["places" as "places"];

const OngoingTrip: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {tripID} = useParams < ParamTypes > ();
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert2, setShowAlert2] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [institute, setInstitute] = useState < Institute > ();
    const [startedDate, setStartedDate] = useState < string > ("");
    const [vehicleName, setVehicleName] = useState < string > ("");
    const [vehiclePlate, setVehiclePlate] = useState < string > ("");
    const [selectedStudentID, setSelectedStudentID] = useState < string > ("");
    const [selectedStudentName, setSelectedStudentName] = useState < string > ("");
    const [locate, setLocation] = useState < Location > ();
    const [response, setResponse] = useState < any > (null);
    const [respTime, setRespTime] = useState <number> ();
    const [isDir, setIsDir] = useState < boolean > (false);
    const [scannerActive, setScannerActive] = useState < boolean > (false);
    const mapRef = useRef < any > ();

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
    });

    if (loadError)
    {
        setMessage(t("maps_error"));
        setIserror(true);
    }

    const handleMapLoad = (ref: any) => {
        mapRef.current = ref;
        if (!isDir && locate)
        {
            mapRef.current.setCenter({lat: locate.latitude, lng: locate.longitude });
        }
    }

    const mapStyles = {        
        height: "62vh",
        width: "100%"
    };

    const secondsToEta = (d: number) => {
        let eta: number;
        eta = respTime! + d*1000;

        let etaDate: Date;
        etaDate = new Date(eta);
        return etaDate.toLocaleTimeString('en-US'); 
    }

    const createWay = () => {
        let way: any[] = [];

        for (let i = 0; i < students.length; i++) {
            if (students[i].status === 'upcoming' || students[i].status === 'active')
            {
                way.push({location: new window.google.maps.LatLng(students[i].lat, students[i].lon), stopover: true});
            }
        }

        return way;
    };

    const formatDate = (value: string) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const fitCenter = () => {
        if(response) {
            if(mapRef.current) {
                mapRef.current.fitBounds(response.routes[0].bounds);
            }
        }
        else {
            if(mapRef.current && locate) {
                mapRef.current.setCenter({lat: locate.latitude, lng: locate.longitude });
            }
        }
    }

    const directionsCallback =  (resp: any) => {
    
        if (resp !== null) {
          if (resp.status === 'OK') {
            setResponse(resp);
            setRespTime(Date.now());
            if(mapRef.current)
            {
                mapRef.current.fitBounds(resp.routes[0].bounds);
            }

            let sec: number = 0;
            for (let i = 0; i < resp.routes[0].legs.length; i++) {
                sec = sec + resp.routes[0].legs[i].duration.value;
            }
            let insti: Institute;
            insti = institute!;
            insti.eta = sec;
            setInstitute(insti);

          } else {
            if(mapRef.current && locate) {
                mapRef.current.setCenter({lat: locate.latitude, lng: locate.longitude });
            }
            setPass(t("directions_error"));
            setIspass(true);
          }
        }
        else{
            if(mapRef.current && locate) {
                mapRef.current.setCenter({lat: locate.latitude, lng: locate.longitude });
            }
            setPass(t("directions_error"));
            setIspass(true);
        }
    }

    const handleNavigation = (latitude: number, longitude: number, studentName: string) => {

        if(longitude && latitude)
        {
            StartNavigation.launchMapsApp({
                latitude: latitude,
                longitude: longitude,
                name: studentName
              });
        }
       
    }

    const openScanner = async () => {
        try {
            const status = await BarcodeScanner.checkPermission({ force: true });

            if (status.granted) {
        
                await BarcodeScanner.hideBackground();
                document.body.classList.add('scanner-active');
                document.getElementById("ongoing-trip")?.classList.add('hide');
                setScannerActive(true);

                const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] });

                if (result.hasContent) {
                    handleScan(result.content); 
                }
                else {
                    openScanner();
                    await Toast.show({
                        text: t("qr_error"),
                    });
                }

            }

            if (status.denied) {
                setMessage1(t("camera_denied"));
                setIserror1(true);
            }

        } catch (error: any) {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    };

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        document.getElementById("ongoing-trip")?.classList.remove('hide');
        setScannerActive(false);
        await BarcodeScanner.showBackground();
        await BarcodeScanner.stopScan();
    };

    const handleScan = async (data: string) => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upTrip = (studentID: string, attendStatus: string) => {
          return new Promise((resolve, reject) => {
            api.post('/trips/scanQR', { tripID, studentID, attendStatus }).then(res => {
              return resolve(res.data.tripStudents);
            }).catch(err => reject(err));
          });
        }

        const status = (type === 'pickup') ? 'picked' : 'left';
    
        const studentID = data.split("/").pop();

        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {
            setShowLoading(true);
            upTrip(studentID, status)
            .then(async (res: any) => {
                NativeAudio.play({
                    assetId: 'attend'
                });
                setStudents(res);
                setShowLoading(false);
                openScanner();
                await Toast.show({
                    text: t("qr_success"),
                });
            })
            .catch(async (error) => {
                setShowLoading(false);
                openScanner();
                if (error.response !== undefined)
                {
                    await Toast.show({
                        text: error.response.data.message,
                    });
                }
                else
                {
                    await Toast.show({
                        text: t("qr_error"),
                    });
                }
            });
        }
        else 
        {
            openScanner();
            await Toast.show({
                text: t("qr_error"),
            });
        }
        
    }

    const handleAbs = (studendID: string, studentName: string) => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upTrip = (tripID: string, studentID: string) => {
          return new Promise((resolve, reject) => {
            api.put('/trips/'+tripID, { 'studentID': studentID, 'studentStatus': 'absent' }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    setShowLoading(true);
      upTrip(tripID, studendID)
        .then(data => {
            let studs: Array < TripStudent > = [];
            let active: number = 0;
            for (let i = 0; i < students.length; i++) {
                if (students[i].studentID === studendID) {
                    studs[i] = students[i];
                    studs[i].status = 'disabled';
                    active = i;
                }
                else 
                {
                    studs[i] = students[i];
                }       
            }
            if (studs.length !== (active+1))
            {
                studs[active+1].status = 'active';
            }
            setStudents(studs);

            setPass(studentName+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("try_again"));
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    const handleStatus = (studentID: string, studentName: string) => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

  
        const upTrip = (studentStatus: string) => {
          return new Promise((resolve, reject) => {
            api.put('/trips/'+tripID, { studentID, studentStatus }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }

        let status: string;

        status = (type === 'pickup') ? 'picked' : 'dropped';
    
        setShowLoading(true);
        upTrip(status)
            .then(data => {
                let studs: Array < TripStudent > = [];
                let active: number = 0;
                for (let i = 0; i < students.length; i++) {
                if (students[i].studentID === studentID) {
                    studs[i] = students[i];
                    studs[i].status = 'disabled';
                    active = i;
                }
                else 
                {
                    studs[i] = students[i];
                }       
            }
            if (studs.length !== (active+1))
            {
                studs[active+1].status = 'active';
            }
            
            setStudents(studs);
            setPass(studentName+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("try_again"));
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    const handleLocation = async () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upStud = (studentID: string, lat: number, lon: number) => {
          return new Promise((resolve, reject) => {
            api.put('/students/'+studentID, { 'lat': lat, 'lon': lon }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }

        setShowLoading(true);
        try {
            if (selectedStudentID)
            {
                const position = await Geolocation.getCurrentPosition();
                upStud(selectedStudentID, position.coords.latitude, position.coords.longitude)
                .then(data => {
        
                    setPass(data+t("has_been_updated"));
                    setIspass(true);
                    setShowLoading(false);
                
                });
            }

            setShowLoading(false);

        }
        catch(error: any) {
            if (error.response !== undefined) 
                setPass(error.response.data.message);
            else 
                setPass(error.message);
            
            setIspass(true);
            setShowLoading(false);  
        }
    }

    const handleInsti = async () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upInsti = (instituteID: string, lat: number, lon: number) => {
          return new Promise((resolve, reject) => {
            api.put('/institutes/'+instituteID, { 'lat': lat, 'lon': lon }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }

        setShowLoading(true);

        try {
            if (institute)
            {
                const position = await Geolocation.getCurrentPosition();
                upInsti(institute._id, position.coords.latitude, position.coords.longitude)
                .then(data => {
          
                    setPass(data+t("has_been_updated"));
                    setIspass(true);
                    setShowLoading(false);
                  
                });
            }
            setShowLoading(false);
            
        }
        catch(error: any) {
            if (error.response !== undefined) 
                setPass(error.response.data.message);
            else 
                setPass(error.message);
            
            setIspass(true);
            setShowLoading(false);  
        }
    }

    const handleNotif = (studentID: string) => {
    
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
        const notifyStudent = () => {
          return new Promise((resolve, reject) => {
              api.post(`/students/notification`, { studentID, 'title': 'Ongoing Trip', 'text': `${type} alert by driver. Track Now`, 'type': 'transport' }).then(res => {
                  return resolve(res.data); 
                }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        notifyStudent()
        .then(data => {
          setPass(t("parent_notified"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setPass(error.response.data.message);
          else
              setPass(t("try_again"));
  
          setIspass(true);
          setShowLoading(false);
        });
        
    }

    const handleNotifAll = () => {

          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
        const notifyAll = () => {
          return new Promise((resolve, reject) => {
              api.get(`/trips/notification/${tripID}`).then(res => {
                  return resolve(res.data); 
                }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        notifyAll()
        .then(data => {
          setPass(t("checkpoint_sent"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setPass(error.response.data.message);
          else
              setPass(t("try_again"));
  
          setIspass(true);
          setShowLoading(false);
        });
        
    }

    const finishTrip = () => {
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upTrip = () => {
            return new Promise((resolve, reject) => {

                api.put('/trips/'+tripID, { 'status': 'completed' }).then(res => {
                    api.put('/drivers/'+authInfo.user._id, { 'isActive': 'disable' }).then(resu => {
                    
                        return resolve(res.data.name);
                        
                        }).catch(err => reject(err));
                }).catch(err => reject(err));
        
            });
        }
    
        setShowLoading(true);
        upTrip()
        .then(data => {

            setMessage(data+t("has_completed"));
            setIserror(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
            else
              setMessage(t("try_again"));

            setIserror(true);
            setShowLoading(false);
        });
        
    }
   
    useEffect(() => {

        let watchID: string = "";
        let check: boolean = false;

        NativeAudio.preload({
            assetId: "attend",
            assetPath: "attend.mp3",
            audioChannelNum: 1,
            isUrl: false
        });

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/trips/start/' + tripID);

                setStudents(result.data.tripStudents);

                if (result.data.tripStudents.length < 24)
                {
                    check = true;
                    for (let i = 0; i < result.data.tripStudents.length; i++) {
                        if (isNaN(result.data.tripStudents[i].lat) || isNaN(result.data.tripStudents[i].lon))
                        {
                            check = false;
                            break;
                        }
                    }
                    setIsDir(check);
                }
                
                setName(result.data.trip.name);
                setType(result.data.trip.type);

                setStartedDate(result.data.trip.startedAt);

                const insti = await api.get(`/institutes/${result.data.trip.instituteID}`);
                setInstitute(insti.data);

                const routei = await api.get('/routes/' + result.data.trip.routeID);
                if (routei.data.vehicle)   
                {
                    setVehicleName(routei.data.vehicle.name);
                    setVehiclePlate(routei.data.vehicle.plate);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        const sendLocation = async (position: Location) => {
            try {
                await CapacitorHttp.request({
                    method: "PUT",
                    url: process.env.REACT_APP_API_URL + "/drivers/" + authInfo.user._id,
                    headers: {
                    Authorization: "Bearer " + authInfo.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    },
                    data: {
                        lat: position.latitude,
                        lon: position.longitude,
                    },
                });

            }  catch (error : any) {

                setMessage(t("location_network"));
                setIserror(true);
            }
        };

        if (isPlatform("capacitor"))
        {
            Geolocation.checkPermissions().then((res) => {
                if (res.location !== 'granted') {
                        Geolocation.requestPermissions().then((ress) => {
                        if (ress.location === 'denied') {
                            setMessage(t("bg_denied"));
                            setIserror(true);
                        }
                        else if (ress.location === 'granted') {
                                BackgroundGeolocation.addWatcher(
                                    {
                                        backgroundMessage: t("tracking_your_location"),
                                        backgroundTitle: t("ongoing_route"),
                                        requestPermissions: false,
                                        stale: true,
                                        distanceFilter: 20
                                    },
                                    function callback(location, error) {
                                        if (error)
                                        {
                                            setMessage(t("location_issue"));
                                            setIserror(true);
                                        }
                                        if (location) {
                                            setLocation(location);
                                            sendLocation(location);
                                            if (!check)
                                            {
                                                if(mapRef.current) {
                                                    mapRef.current.setCenter({lat: location.latitude, lng: location.longitude });
                                                }
                                            }
                                        }

                                    }
                                ).then(function retain_callback_id(the_id) {
                                    watchID = the_id;
                                    fetchData();
                                });


                        }
                    });          
                }
                else {
                        BackgroundGeolocation.addWatcher(
                            {
                                backgroundMessage: t("tracking_your_location"),
                                backgroundTitle: t("ongoing_route"),
                                requestPermissions: false,
                                stale: true,
                                distanceFilter: 20
                            },
                            function callback(location, error) {
                                if (error)
                                {
                                    setMessage(t("location_issue"));
                                    setIserror(true);
                                }
                                if (location) {
                                    setLocation(location);
                                    sendLocation(location);
                                    if (!check)
                                    {
                                        if(mapRef.current) {
                                            mapRef.current.setCenter({lat: location.latitude, lng: location.longitude });
                                        }
                                    }
                                }
                            }
                        ).then(function retain_callback_id(the_id) {
                            watchID = the_id;
                            fetchData();
                        });
                
                }
            }).catch(err =>  {
                setMessage(t("location_issue"));
                setIserror(true);
            });

        }
        else
        {
            setMessage(t("unsupported_device"));
            setIserror(true);
            
        }

       return () => {
            if(watchID !== "")
            {
                BackgroundGeolocation.removeWatcher({id: watchID});
            }
            stopScan();
            NativeAudio.unload({
                assetId: 'attend'
            });
        }

    }, [authInfo, tripID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("ongoing_trip")}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleNotifAll} > 
                            <IonIcon 
                                icon={notifications}
                                color='danger'/>
                        </IonButton>
                        <IonButton onClick={openScanner} >
                            <IonIcon 
                                icon={qrCodeOutline}
                                color="warning" />
                        </IonButton>
                       
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" id="ongoing-trip">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("ongoing_trip")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app`)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("update")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                    <IonAlert
                        isOpen={showAlert}
                        onDidDismiss={() => setShowAlert(false)}
                        cssClass="first-alert"
                        header={t("confirm")}
                        message={`${t("confirm_location_1")} ${selectedStudentName}${t("confirm_location_2")}`}
                        buttons={[
                            {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                            },
                            {
                            text: t("yes"),
                            handler: () => {
                                handleLocation();
                            }
                            }
                        ]}
                        />

                    <IonAlert
                        isOpen={showAlert2}
                        onDidDismiss={() => setShowAlert2(false)}
                        cssClass="first-alert"
                        header={t("confirm")}
                        message={`${t("confirm_location_1")} ${institute?.name}${t("confirm_location_2")}`}
                        buttons={[
                            {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                            },
                            {
                            text: t("yes"),
                            handler: () => {
                                handleInsti();
                            }
                            }
                        ]}
                        />

                    <IonAlert isOpen={iserror1}
                        cssClass="first-alert"
                        onDidDismiss={
                            () => {
                                setIserror1(false);
                                BarcodeScanner.openAppSettings();
                            }
                        }
                        header={t("error")}
                        message={message1}
                        buttons={
                            [`${t("open_settings")}`]
                        }/>

                <IonGrid className="ion-no-padding">
                  
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin">
                            <IonCard className="grey-shade">
                                <IonCardContent className="ion-no-padding map-container">
                                    {locate && institute && isLoaded ? (

                                        <GoogleMap
                                        onLoad={handleMapLoad}
                                        mapContainerStyle={mapStyles}
                                        zoom={14}
                                        clickableIcons={false}
                                        options={{disableDefaultUI: true}}
                                        >
                                            <Marker position={{lat: institute.lat, lng: institute.lon}} icon={"assets/images/school-gps.png"} />
                                            <InfoBox
                                                options={{closeBoxURL: ''}}
                                                position={new window.google.maps.LatLng(institute.lat, institute.lon)}
                                                >
                                                <div style={{ backgroundColor: '#086e7d', opacity: 1, padding: 2, width: '80px' }}>
                                                    <div style={{ fontSize: 11, color: `#FFFFFF`, fontWeight: 600 }}>
                                                    {institute.name}
                                                    </div>
                                                </div>
                                            </InfoBox>

                                            <Marker zIndex={999} position={{lat: locate.latitude, lng: locate.longitude}} icon={"assets/images/dot.gif"} />

                                            {
                                                students && students.map(student => {
                                                    if (isNaN(student.lat) || isNaN(student.lon))
                                                    {
                                                        return null;
                                                    }
                                                    else
                                                    {
                                                        let myLocation = {lat: student.lat, lng: student.lon};
                                                        return (
                                                            <Marker key={student.studentID} 
                                                            position={myLocation} 
                                                            icon={"assets/images/self-gps.png"} />
                                                         
                                                        )
                                                    }

                                                })
                                            }

                                                {(response === null) && (isDir) && (window.google) && (
                                                    <DirectionsService
                                                    options={{
                                                        destination: {lat: institute.lat, lng: institute.lon},
                                                        origin: {lat: locate.latitude, lng: locate.longitude},
                                                        waypoints: createWay(),
                                                        travelMode: window.google.maps.TravelMode.DRIVING
                                                        
                                                    }}
                                                    callback={directionsCallback}
                                                    />
                                                )}

                                                {(response !== null) && (
                                                    <DirectionsRenderer
                                                    options={{ 
                                                        directions: response,
                                                        preserveViewport: true,
                                                        markerOptions: {
                                                            visible: false
                                                        }
                                                    }}
                                                    />
                                                )}

                                                <IonIcon onClick={fitCenter} size="large" icon={locateSharp} className="bottom-right" />
                                                
                                        </GoogleMap>
                                    ) : ( 
                                    <div className="load-body">
                                        <div className="load-wrapper">
                                            <span className="circle circle-1"></span>
                                            <span className="circle circle-2"></span>
                                            <span className="circle circle-3"></span>
                                            <span className="circle circle-4"></span>
                                            <span className="circle circle-5"></span>

                                        </div>
                                        <div className="load-title">
                                                {t("loading_maps")}
                                            </div>
                                    </div>
                                   )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className={type === 'drop' ? "action-card-b" : "action-card-c"}>
                                <IonCardContent>
                                    <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol size="2" className="mt-05">
                                                <IonIcon className='third-icon'
                                                    icon={busOutline}
                                                    color={type === 'drop' ? 'secondary' : 'primary'} />
                                            </IonCol>
                                            <IonCol size="10">
                                                <IonText className='action-text'>{t("trip")}: {name} ({type})<br/>
                                                {t("started")}: {startedDate && formatDate(startedDate)}
                                                    {(vehicleName !== "") && (
                                                    <>
                                                        <br/>{t("vehicle")}: {vehicleName} ({vehiclePlate})
                                                    </>
                                                    )}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("trip_students")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            
                            {students.map((student) => {
                                return (
                                <IonCard key={student.studentID} className={(student.status === 'active') ? "active-group" : ""}>
                                    <IonCardContent>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="2">
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            {student.fileName ?
                                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.fileName}`} alt="Profile Pic" width="70" />)
                                                            : (<img src={`assets/images/avatar.png`} alt="Profile Pic" width="70" />)
                                                            }

                                                        </div>
                                                    </div>
                                                    </IonCol>
                                                    <IonCol size="10" className="ion-no-padding">
                                                    
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                    
                                                            <IonText className='first-meta'>
                                                                {student.name}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => document.location.href = `tel:${student.phone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='secondary'/></IonButton>
                                                                    <IonButton onClick={() => handleNavigation(student.lat, student.lon, student.name)} > <IonIcon 
                                                                    icon={navigate}
                                                                    color='primary'/></IonButton>
                                                                    <IonButton onClick={() => handleNotif(student.studentID)} > <IonIcon 
                                                                    icon={notifications}
                                                                    color='success'/></IonButton>
                                                                   
                                                            </IonButtons>
                                                                    
                                                        </IonItem>
                                                    </IonCol>
                                                    
                                            </IonRow>
                                            <IonRow className="ion-padding-top">
                                                <IonCol>
                                                    <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                    {(student.status === 'active') && (
                                                        <div className="blinking"></div> 
                                                    )}
                                                        <IonText className='first-meta'>
                                                        {student.pname} <br/>
                                                        {student.address}
                                                        </IonText>
                                                        <IonButtons slot="end">
                                                               
                                                                    <IonButton onClick={() => {
                                                                        setSelectedStudentID(student.studentID);
                                                                        setSelectedStudentName(student.name);
                                                                        setShowAlert(true);
                                                                    }
                                                                    } > <IonIcon 
                                                                    icon={location}  
                                                                    color='secondary'/></IonButton>
                                                            </IonButtons>
                                                        </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton expand="block" className="first-button" fill="clear" onClick={() => handleStatus(student.studentID, student.name)} > {(type === 'pickup') ? t("picked_up") : t("dropped")}</IonButton>
                                                </IonCol>
                                                <IonCol>
                                                    <IonButton expand="block" className="first-button" fill="clear" onClick={() => handleAbs(student.studentID, student.name)} >{t("absent")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                )})
                            }
                            </IonCol>
                        </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("destination")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {institute && (
                                    <IonCard className="tertiary-card">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="1" className="mt-20">
                                                        <IonIcon size="large"
                                                            icon={businessOutline}
                                                            color='tertiary'/>
                                                        </IonCol>
                                                        <IonCol size="11">
                                                          
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                           
                                                            <IonText className='first-meta'>
                                                            {institute.name}<br/>
                                                            {institute.eta ? (t("eta")+': ' + secondsToEta(institute.eta)) : t("eta_unavailable")}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                                    <IonButton onClick={() => setShowAlert2(true) }> <IonIcon 
                                                                    icon={location}
                                                                    color='primary'/></IonButton>
                                                                     <IonButton onClick={() => handleNavigation(institute.lat, institute.lon, institute.name)} > <IonIcon 
                                                                                    icon={navigate}
                                                                                    color='primary'/></IonButton>
                                                                    <IonButton onClick={() => document.location.href = `tel:${institute?.phone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='tertiary'/></IonButton>
                                                                    
                                                            </IonButtons>
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    
                                    </IonCard>    
                                )}          
                                
                            </IonCol>
                        </IonRow>
                   
                </IonGrid>
            </IonContent>
            <IonFooter className='footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            {scannerActive && (
                                <IonButton className="first-button" 
                                    fill="clear" 
                                    onClick={stopScan}>
                                        {t("stop_scan")}
                                </IonButton>
                            )}
                            {!scannerActive && (
                            <IonButton className="fifth-button" 
                                fill="clear" 
                                onClick={finishTrip}>
                                    {t("finish_trip")}
                            </IonButton>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default OngoingTrip;
