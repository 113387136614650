import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    students?: string[],
    isActive: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    sessionID?: string
}

const Classes: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {sessionID} = useParams < ParamTypes > ();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const loadItems = async () => {

        if (!session || session === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            
            const result = await api.get(`/classes/listInstitute/${authInfo.user.instituteID}/${session}?skip=${limit}`);

            if(result.data.classes && result.data.classes.length)
            {
                setClasses([...classes, ...result.data.classes]);
            }
            
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSession = () => {

        if (!session || session === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/listInstitute/${authInfo.user.instituteID}/${session}`);

                setClasses(result.data.classes);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }


    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
      }


    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setSessions(storedState.sessions || []);
                setSession(storedState.session || '');
                setClasses(storedState.classes || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {

                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                        
                        const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                        setSessions(res.data);
                        
                        if (sessionID)
                        {
                            setSession(sessionID);
                            const result = await api.get(`/classes/listInstitute/${authInfo.user.instituteID}/${sessionID}`);
                            setClasses(result.data.classes);
                            setTotal(result.data.total);
                        
                        }
                        else
                        {
                            setSession('');
                            setClasses([]);
                            setTotal(0);
                            
                        }
                        setSkip(0);
                    
                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    
                    if (sessionID)
                    {
                        setSession(sessionID);
                        const result = await api.get(`/classes/listInstitute/${authInfo.user.instituteID}/${sessionID}`);
                        setClasses(result.data.classes);
                        setTotal(result.data.total);
                    } 
                    else
                    {
                        setSession('');
                        setClasses([]);
                        setTotal(0);
                        
                    }
                    setSkip(0);
                
                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }

        setInitialRender(false);

    }, [authInfo, sessionID, history.action, history.location.pathname, t]);

     // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            sessions,
            session,
            classes,
            total,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [sessions, session, classes, total, skip, scrollPosition, history.location, initialRender]);
     

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/essentials`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("manage_classes") : t("manage_batches")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("manage_classes") : t("manage_batches")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" color="secondary" className='input-label'>
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => setSession(e.detail.value)
                                                }>

                                                    {sessions.length > 0 && sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXl="3" size="6" className="ion-padding">
                            <IonButton onClick={() => handleSession() }
                                color="primary" className='mt-15'>{(authInfo.institute.type === "school") ? t("view_classes") : t("view_batches")}
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    <div className="mt-10 ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonText>
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("total_students")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(classes.length > 0) ? (classes.map((classi, i) => {
                            return (
                                <IonRow key={classi._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={() => setCurrent(classi._id)}
                                    onDoubleClick={() => history.push(`/app/class/${classi._id}`)} >
                                    
                                        <IonCol size="8" className='table-field' >
                                            <IonRadio value={classi._id} className='table-icon' color="secondary" />
                                            <IonText className='ion-margin-start'>{classi.name}</IonText>
                                        </IonCol>
                                        <IonCol size="3" className='table-field'>
                                            {classi.students ? classi.students.length : "0"}
                                        </IonCol>
                                        <IonCol size="1" >
                                                <IonIcon 
                                                icon={construct}
                                                color={classi.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/class/${classi._id}`)
                                                }
                                                />
  
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{(authInfo.institute.type === "school") ? t("no_classes") : t("no_batches")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(classes.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${classes.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                            <IonCol className='min-xxxl'>
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/students/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_students")}</IonButton>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/class/promote/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{(authInfo.institute.type === "school") ? t("promote_class") : t("promote_batch")}</IonButton>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/attend/class/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_attendance")}</IonButton>

                                {authInfo.institute.communication && (
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/circulars/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("manage_comms")}</IonButton>
                                )}

                        {((authInfo.user.role === "Super") || (authInfo.user.role === "Admin") || (authInfo.user.role === "Academic")) && authInfo.institute.timetable && (
                            <>
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/class/subjects/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("map_subjects")}</IonButton>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/class/timetables/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("map_time_table")}</IonButton>
                                </>
                                )}

                                {((authInfo.user.role === "Super") || (authInfo.user.role === "Admin") || (authInfo.user.role === "Academic")) && !authInfo.institute.timetable && (
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/batch/schedule/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("update_schedule")}</IonButton>
                                )}

                                {((authInfo.user.role === "Super") || (authInfo.user.role === "Admin") || (authInfo.user.role === "Accountant")) && authInfo.institute.payment && (
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/fees/class/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("map_fee_structure")}</IonButton>
                                )}

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/class/idcards/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("print_id_cards")}</IonButton>

                                <IonButton 
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("export_excel")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                        
                        
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Classes;
