import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Item {
    _id: string,
    store: StoreInterface,
    name: string,
    price: number,
    quantity: number,
    stock: boolean,
    isActive: boolean
}

interface StoreInterface {
    storeID: string,
    storeName: string
}

interface Store {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    storeID?: string
}

const Items: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {storeID} = useParams < ParamTypes > ();
    const [stores, setStores] = useState < Store[] > ([]);
    const [items, setItems] = useState < Item[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [store, setStore] = useState < string > ("all");
    const [availability, setAvailability] = useState < string > ("all");
    const [searchText, setSearchText] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [stock, setStock] = useState < boolean > (false);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            
            const result = await api.post(`/items/listByInstitute/${authInfo.user.instituteID}?skip=${limit}`, {storeID: store, availability, searchText});

            if(result.data.items && result.data.items.length)
            {
                setItems([...items, ...result.data.items]);
            }
            
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSubmit = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/items/listByInstitute/${authInfo.user.instituteID}`, {storeID: store, availability, searchText});

                setItems(result.data.items);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }


    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
      }


    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setStores(storedState.stores || []);
                setStore(storedState.store || 'all');
                setItems(storedState.items || []);
                setAvailability(storedState.availability || 'all');
                setSearchText(storedState.searchText || '');
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {

                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                        
                        const res = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                        setStores(res.data);
                        
                        if (storeID)
                        {
                            setStore(storeID);
                            const result = await api.post(`/items/listByInstitute/${authInfo.user.instituteID}`, {storeID, availability: 'all', searchText: ''});

                            setItems(result.data.items);
                            setTotal(result.data.total);
                        
                        }
                        else
                        {
                            setStore("all");
                            setItems([]);
                            setTotal(0);
                        }
                        setSkip(0);
                    
                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    
                    const res = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                    setStores(res.data);
                    
                    if (storeID)
                    {
                        setStore(storeID);
                        const result = await api.post(`/items/listByInstitute/${authInfo.user.instituteID}`, {storeID, availability: 'all', searchText: ''});

                        setItems(result.data.items);
                        setTotal(result.data.total);
                    
                    }
                    else
                    {
                        setStore("all");
                        setItems([]);
                        setTotal(0);
                    }
                    setSkip(0);
                
                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }

        setInitialRender(false);

    }, [authInfo, storeID, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            stores,
            store,
            items,
            availability,
            searchText,
            total,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [stores, store, items, availability, searchText, total, skip, scrollPosition, history.location, initialRender]);
     

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_items")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_items")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-lg">
                   
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("item_store")}</IonLabel>
                                            <IonSelect value={store}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("item_store")}
                                                    onIonChange={
                                                        (e) => setStore(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {stores.map((store) => (
                                                        (store.isActive) &&
                                                        (<IonSelectOption key={store._id} value={`${store._id}`}>{store.name} </IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("item_availability")}
                                            </IonLabel>
                                            <IonSelect value={availability}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("type")}
                                                onIonChange={
                                                    (e) => setAvailability(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="out">{t("out_of_stock")}</IonSelectOption>
                                                <IonSelectOption value="low">{t("low_availability")}</IonSelectOption> 
                                                <IonSelectOption value="high">{t("high_availability")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_item")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_item")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
               
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_items")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="5" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("item_name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("store_name")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("available_quantity")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current}>

                        {(items.length > 0) ? (items.map((item, i) => {
                            return (
                                <IonRow key={item._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={() => {
                                        setCurrent(item._id);
                                        setStock(item.stock);
                                    }}
                                    onDoubleClick={() => history.push(`/app/item/${item._id}`)} >
                                    
                                        <IonCol sizeLg="5" size="8" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={item._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start'>{item.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                    {item.store.storeName}
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                        <IonCol sizeLg="3" size="0" className='table-field text-capitalize ion-hide-lg-down'>
                                            {item.store.storeName}
                                        </IonCol>
                                        <IonCol size="3" className='table-field'>
                                            {item.stock ? item.quantity : t("na")}
                                        </IonCol>
                                        <IonCol size="1" >
                                                <IonIcon 
                                                icon={construct}
                                                color={item.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/item/${item._id}`)
                                                }
                                                />
  
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_items")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(items.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${items.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                            <IonCol className='min-lg'>
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_item"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/item/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("manage_item")}</IonButton>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_item"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/item/ledger/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_ledger")}</IonButton>

                                {stock && (
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_item"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/inventory/transact/buy/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("buy_item")}</IonButton>
                                )}
                                
                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_item"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/inventory/transact/sell/${current}`);
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("sell_item")}</IonButton>

                                <IonButton 
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("export_excel")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                        
                        
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Items;
