import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, attach, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Circular {
    _id: string,
    title: string,
    type?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}
interface ParamTypes {
    classID?: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInfo {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}


const Circulars: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [circulars, setCirculars] = useState < Circular[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [isNot, setIsNot] = useState < boolean > (false);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [year, setYear] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInfo[] > ([]);
    const [classi, setClassi] = useState < string > ("all");
    const [searchText, setSearchText] = useState < string > ("");
    const [type, setType] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
      }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
        else
        {
            return t("pick_date");
        }
      };

    const loadItems = async () => {

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!year || year === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }
        
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }


        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    
                    const result = await api.post(`/circulars/listByTeacher/${authInfo.user._id}?skip=${limit}`, {classi, type, toDOB, fromDOB, searchText});
                    if(result.data.circulars && result.data.circulars.length)
                    {
                        setCirculars([...circulars, ...result.data.circulars]);
                    }
                    
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const result = await api.post(`/circulars/listByInstitute/${authInfo.user.instituteID}?skip=${limit}`, {year, classi, type, toDOB, fromDOB, searchText});
                    if(result.data.circulars && result.data.circulars.length)
                    {
                        setCirculars([...circulars, ...result.data.circulars]);
                    }
                }

            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSubmit = () => {

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!year || year === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {

                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                { 
                    const result = await api.post(`/circulars/listByTeacher/${authInfo.user._id}`, {classi, type, toDOB, fromDOB, searchText});
                    setCirculars(result.data.circulars);
                    setTotal(result.data.total);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const result = await api.post(`/circulars/listByInstitute/${authInfo.user.instituteID}`, {year, classi, type, toDOB, fromDOB, searchText});
                    setCirculars(result.data.circulars);
                    setTotal(result.data.total);
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    const handleSession = (sessionID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setYear(sessionID);
        setClassi("all");
    }

    useEffect(() => {

            if (history.action === "POP") {
                // Check if data is present in local storage
                const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');
    
                if (Object.keys(storedState).length > 0) 
                {
                    // Data is present in local storage, use it to initialize state
                    setCirculars(storedState.circulars || []);
                    setSessions(storedState.sessions || []);
                    setClasses(storedState.classes || []);
                    setTotal(storedState.total || 0);
                    setSkip(storedState.skip || 0);
                    setYear(storedState.year || "");
                    setClassi(storedState.classi || "all");
                    setType(storedState.type || "all");
                    setToDate(storedState.toDate || undefined);
                    setFromDate(storedState.fromDate || undefined);
                    setSearchText(storedState.searchText || "");
                    setSkip(storedState.skip || 0);
                   
                    setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
                } 
                else 
                {

                    const api = axios.create({
                        baseURL: process.env.REACT_APP_API_URL,
                        timeout: parseInt(process.env.REACT_APP_API_TO!),
                        headers: {
                            'Authorization': 'Bearer ' + authInfo.token
                        }
                    });

                    const fetchUp = async () => {
                        setShowLoading(true);
                        try {

                            const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                            setSessions(res.data);

                            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                            {

                                const teacher = await api.get(`/employees/getByUID/${authInfo.user._id}`);
                                if(teacher.data.classID === "none")
                                {
                                    setIsNot(true);
                                }
                                else
                                {
                                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                                    setClasses(classy.data);
                                }
                            }
                            else
                            {
                                setClasses([]);
                            }

                            if (classID)
                            {
                                const classInfo = await api.get(`/classes/${classID}`);
                                setYear(classInfo.data.sessionID);
                                const resu = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                                setClasses(resu.data);
                                setClassi(classID);

                                const result = await api.post(`/circulars/listByInstitute/${authInfo.user.instituteID}`, {'year': classInfo.data.sessionID, 'classi': classID, 'type': 'all', 'toDOB': '', 'fromDOB': '', 'searchText': ''});
                                setCirculars(result.data.circulars);
                                setTotal(result.data.total);
                            }
                            else
                            {
                                setCirculars([]);
                                setTotal(0);
                                setYear("");
                            }
                            setSkip(0);
                            setShowLoading(false);

                        } catch (error : any) {
                            if (error.response !== undefined) 
                                setMessage(error.response.data.message);
                            else 
                                setMessage(t("something_went_wrong"));
                            setIserror(true);
                            setShowLoading(false);
                        }
                    };

                    fetchUp();

                }
            }
            else
            {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                        setSessions(res.data);

                        if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                        {

                            const teacher = await api.get(`/employees/getByUID/${authInfo.user._id}`);
                            if(teacher.data.classID === "none")
                            {
                                setIsNot(true);
                            }
                            else
                            {
                                const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                                setClasses(classy.data);
                            }
                        }
                        else
                        {
                            setClasses([]);
                        }

                        if (classID)
                        {
                            const classInfo = await api.get(`/classes/${classID}`);
                            setYear(classInfo.data.sessionID);
                            const resu = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                            setClasses(resu.data);
                            setClassi(classID);

                            const result = await api.post(`/circulars/listByInstitute/${authInfo.user.instituteID}`, {'year': classInfo.data.sessionID, 'classi': classID, 'type': 'all', 'toDOB': '', 'fromDOB': '', 'searchText': ''});
                            setCirculars(result.data.circulars);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            setCirculars([]);
                            setTotal(0);
                            setYear("");
                        }
                        setSkip(0);
                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                };

                fetchUp();
            }

            setInitialRender(false);

    }, [authInfo, classID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            circulars,
            classes,
            total,
            skip,
            sessions,
            year,
            classi,
            type,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [circulars, classes, total, skip, sessions, year, classi, type, toDate, fromDate, searchText, scrollPosition, history.location, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("view_class_comm") : t("view_batch_comm")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade"  ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("view_class_comm") : t("view_batch_comm")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isNot}
                    onDidDismiss={() => {
                        history.replace("/app");
                    }}
                    header={t("unauthorized")}
                    message={t("unauth_msg")}
                    buttons={[`${t("close")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                    {((authInfo.user.role !== 'Teacher' && authInfo.user.role !== 'Employee') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                       <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={year}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => setClassi(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} </IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("type")}
                                            </IonLabel>
                                            <IonSelect value={type}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("type")}
                                                onIonChange={
                                                    (e) => setType(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("comm_from")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("comm_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("ok")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("comm_to")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("comm_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_title")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_title")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
               
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("manage_comms")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="7" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("type")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("title")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="1">
                                <IonIcon 
                                    icon={attach}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(circulars.length > 0) ? (circulars.map((circular, i) => {
                                return (
                                    <IonRow key={circular._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(circular._id)
                                    }
                                    onDoubleClick={() => history.push(`/app/circular/${circular._id}`)}>
                                        
                                            <IonCol sizeLg="4" size="7" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        <IonRadio value={circular._id} className='table-icon' color="secondary" />
                                                        <IonText className='ion-margin-start text-capitalize'>{circular.type}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {circular.title}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            
                                            <IonCol sizeLg="3" size="0" className='table-field text-capitalize ion-hide-lg-down'>
                                                {circular.title}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(circular.createdAt)}
                                            </IonCol>
                                            
                                            <IonCol size="1" >
                                                {circular.filename && (
                                                    <IonIcon 
                                                    icon={attach}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/circular/${circular._id}`)
                                                    }
                                                    />
                                                )}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/circular/${circular._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_comms")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                            {(circulars.length > 0) &&
                               <IonRow>
                               <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                                </IonCol>
                            </IonRow>
                            }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${circulars.length} ${t("of")} ${total}`} 
                                </IonCol>
                             </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                        
                           
                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_circular"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/circular/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("manage_comm")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/circular/add`)
                                        
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("new_circular")}</IonButton>


                                 </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Circulars;