import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import AccountantHome from "../pages/Home/AccountantHome";
import ManageDues from "../pages/Employee/Dashboard/ManageDues";
import ManageFees from "../pages/Employee/Dashboard/ManageFees";
import ManageCash from "../pages/Employee/Dashboard/ManageCash";
import ManagePayroll from "../pages/Employee/Dashboard/ManagePayroll";

import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";
import Transactions from "../pages/Admin/Transaction/Transactions";

import Students from "../pages/Admin/Student/Students";
import StudentDetail from "../pages/Admin/Student/StudentDetail";
import StudentLedger from "../pages/Admin/Student/StudentLedger";

import AddHeadDues from "../pages/Admin/Due/AddHeadDues";
import AddDues from "../pages/Admin/Due/AddDues";
import AddManyDues from "../pages/Admin/Due/AddManyDues";
import ScheduledInvoices from "../pages/Admin/Invoice/ScheduledInvoices";
import UnclearedInvoices from "../pages/Admin/Invoice/UnclearedInvoices";
import ClearedInvoices from "../pages/Admin/Invoice/ClearedInvoices";
import StudentInvoices from "../pages/Admin/Invoice/StudentInvoices";
import InvoiceDetail from "../pages/Admin/Invoice/InvoiceDetail";

import ViewDues from "../pages/Admin/Due/ViewDues";
import DuesReports from "../pages/Admin/Due/DuesReports";
import CollectFee from "../pages/Admin/Due/CollectFee";
import CollectReceipts from "../pages/Admin/Due/CollectReceipts";
import CollectReports from "../pages/Admin/Due/CollectReports";
import Concessions from "../pages/Admin/Due/Concessions";
import ReceiptDetail from "../pages/Admin/Transaction/ReceiptDetail";

import TransportExpense from "../pages/Admin/Transaction/TransportExpense";
import AddExpense from "../pages/Admin/Transaction/AddExpense";
import ExpenseReports from "../pages/Admin/Transaction/ExpenseReports";
import Bills from "../pages/Admin/Transaction/Bills";
import AddBill from "../pages/Admin/Transaction/AddBill";
import BillDetail from "../pages/Admin/Transaction/BillDetail";

import Fees from "../pages/Admin/Fee/Fees";
import AddFee from "../pages/Admin/Fee/AddFee";
import StudentFee from "../pages/Admin/Fee/StudentFee";
import ClassFee from "../pages/Admin/Fee/ClassFee";
import FeeDetail from "../pages/Admin/Fee/FeeDetail";
import FeeHeads from "../pages/Admin/Fee/FeeHeads";
import AddFeeHead from "../pages/Admin/Fee/AddFeeHead";
import FeeHeadDetail from "../pages/Admin/Fee/FeeHeadDetail";

import MyTasks from "../pages/Employee/Task/MyTasks";
import MyTaskDetail from "../pages/Employee/Task/MyTaskDetail";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import UserNotifications from "../pages/User/UserNotifications";

import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const AccountantTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={AccountantHome} />
          <Route path="/app/dues" exact={true} component={ManageDues} />
          <Route path="/app/fees" exact={true} component={ManageFees} />
          <Route path="/app/cash" exact={true} component={ManageCash} />
          <Route path="/app/payroll" exact={true} component={ManagePayroll} />

          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />
          <Route path="/app/transactions" exact={true} component={Transactions} />
          <Route path="/app/students/:classID?" exact={true} component={Students} />
          <Route path="/app/student/:studentID" exact={true} component={StudentDetail} />
          <Route path="/app/student/ledger/:studentID" exact={true} component={StudentLedger} />

          <Route path="/app/dues/add/:studentID?" exact={true} component={AddDues} />
          <Route path="/app/dues/addMany" exact={true} component={AddManyDues} />
          <Route path="/app/dues/addHead" exact={true} component={AddHeadDues} />
          <Route path="/app/invoices/scheduled" exact={true} component={ScheduledInvoices} />   
          <Route path="/app/invoices/uncleared" exact={true} component={UnclearedInvoices} />   
          <Route path="/app/invoices/cleared" exact={true} component={ClearedInvoices} />
          <Route path="/app/invoices/student/:studentID?" exact={true} component={StudentInvoices} />
          <Route path="/app/invoice/:invoiceID" exact={true} component={InvoiceDetail} />

          <Route path="/app/dues/view" exact={true} component={ViewDues} />   
          <Route path="/app/dues/reports" exact={true} component={DuesReports} />   
          <Route path="/app/collection/:studentID?" exact={true} component={CollectFee} />
          <Route path="/app/collections/receipts" exact={true} component={CollectReceipts} />   
          <Route path="/app/collections/reports" exact={true} component={CollectReports} />   
          <Route path="/app/concessions" exact={true} component={Concessions} />
          <Route path="/app/receipt/:receiptID" exact={true} component={ReceiptDetail} />
          
          <Route path="/app/transactions/transport/:vehicleID?" exact={true} component={TransportExpense} />
          <Route path="/app/transactions/expense" exact={true} component={AddExpense} /> 
          <Route path="/app/expense/reports" exact={true} component={ExpenseReports} />  
          <Route path="/app/bills/list/:tag?" exact={true} component={Bills} />
          <Route path="/app/bills/add/:uri?" exact={true} component={AddBill} />
          <Route path="/app/bill/:billID" exact={true} component={BillDetail} />
          
          <Route path="/app/fees/manage" exact={true} component={Fees} />
          <Route path="/app/fees/add" exact={true} component={AddFee} />
          <Route path="/app/fees/student/:studentID?" exact={true} component={StudentFee} />
          <Route path="/app/fees/class/:classID?" exact={true} component={ClassFee} />
          <Route path="/app/fee/:feeID" exact={true} component={FeeDetail} />
          <Route path="/app/feeheads" exact={true} component={FeeHeads} />
          <Route path="/app/feeheads/add" exact={true} component={AddFeeHead} />
          <Route path="/app/feehead/:feeHeadID" exact={true} component={FeeHeadDetail} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:taskID" exact={true} component={MyTaskDetail} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />
          
          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />

          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to="/app/home" />
          <Redirect from="*" to="/app"/>
        </Switch>
      </IonRouterOutlet>
  );
};

export default AccountantTabs;
