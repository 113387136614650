import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, informationCircle, medical } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchStudent from '../../../components/SwitchStudent';

interface Incident {
    _id: string,
    type: string,
    createdAt: Date
}

interface Student {
    _id: string,
    instituteID: string,
    name: string,
    dob: Date,
    gender?: string,
    bloodType?: string,
    isDisabled?: boolean,
    disabilityInfo?: string,
    medicalInfo?: string,
    isActive: boolean
}

const Incidents: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [incidents, setIncidents] = useState < Incident[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [skip, setSkip] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDated = (value: Date) => {

        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    
    };

    const loadItems = async () => {

        if (student)
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });
    
            let limit = skip+30;
    
            try {
                const result = await api.post(`/incidents/getByStudent/${student._id}?skip=${limit}`, {'duration': "twelve", 'toDOB': "", 'fromDOB': ""});
    
                if(result.data.incidents && result.data.incidents.length)
                {
                    setIncidents([...incidents, ...result.data.incidents]);
                }
                    
                setSkip(limit);
    
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                    else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
        }

    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                const result = await api.post(`/incidents/getByStudent/${student.data._id}`, {'duration': "twelve", 'toDOB': "", 'fromDOB': ""});
                setIncidents(result.data.incidents);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_medical_records")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_medical_records")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            
                            {student && (
                            <IonRow>
                                <IonCol className="ion-padding">
                                    <IonCard className='medical-card' routerLink={`/app/user/profile/student`}>
                                        <IonCardHeader>
                                            <IonCardTitle className='mt-10'>
                                                {t("student_data")}
                                                <IonIcon 
                                                className='ion-float-right'
                                                color="danger"
                                                size="large"
                                                icon={medical}/>
                                            </IonCardTitle>
                                            
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("basic_info")}</IonCardSubtitle>
                                            <IonRow className="ion-padding text-dark">
                                                <IonCol size="5" className='text-bold'>
                                                    {t("student_name")}:<br/>
                                                    {t("student_dob")}:<br/>
                                                    {t("student_blood")}:
                                                </IonCol>
                                                <IonCol size="7">
                                                    <IonText className="text-capitalize">{student.name}</IonText><br/>
                                                    {formatDated(student.dob)}<br/>
                                                    {student.bloodType}
                                                </IonCol>
                                            </IonRow>
                                            {(student.isDisabled) && (
                                            <>
                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("disability_info")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {student.disabilityInfo}
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                            )}

                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("medical_condition")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {student.medicalInfo && (student.medicalInfo !== "") ? student.medicalInfo : t("no_medical_info")}
                                                    </IonCol>
                                                </IonRow>

                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                       
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                        <IonTitle className='list-title'>
                                        {t("incidents")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                                
                            <div className="ion-table">

                                <IonRow className="table-title ion-padding ion-margin-top">

                                    <IonCol size="6" className='table-heading'>
                                        {t("date")}
                                        
                                    </IonCol>

                                    <IonCol size="5" className='table-heading'>
                                        {t("type")}
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonIcon 
                                            icon={informationCircle}
                                            color="light"
                                            className='table-icon'
                                            />
                                    </IonCol>
                                </IonRow>
                                

                                {(incidents.length > 0) ? (incidents.map((incident, i) => {
                                    return (
                                        <IonRow key={incident._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onClick={
                                            () => history.push(`/app/incident/${incident._id}`)
                                        }>
                                            
                                                <IonCol size="6" className='table-field' >
                                                    
                                                    {formatDated(incident.createdAt)}
                                                    
                                                </IonCol>
                                                <IonCol sizeLg="5" className='table-field'>
                                                    {incident.type}
                                                </IonCol>
                                                <IonCol size="1">
                                                    <IonIcon 
                                                        icon={informationCircle}
                                                        color="dark"
                                                        className='table-icon mt-10'
                                                        
                                                    />
                                                    
                                                </IonCol>
                                            
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_incidents")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                               
                                </div>

                                {(incidents.length > 0) &&
                                <IonRow>
                                    <IonCol>
                                        <IonInfiniteScroll
                                            onIonInfinite={async (ev) => {
                                                await loadItems();
                                                ev.target.complete();
                                            }}
                                        >
                                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                        </IonInfiniteScroll>
                                    </IonCol>
                                </IonRow>
                                }

                            </IonCol>
                        </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Incidents;