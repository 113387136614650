import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct, checkboxOutline} from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    dob: Date,
    gender?: string,
    due: number,
    isActive: boolean
}
interface ClassInfo {
    className: string,
    session: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface ParamTypes {
    classID?: string
}

const Students: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [students, setStudents] = useState < Student[] > ([]);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("all");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [searchText, setSearchText] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [year, setYear] = useState < string > ("");
    const [gender, setGender] = useState < string > ("all");
    const [category, setCategory] = useState < string > ("all");
    const [house, setHouse] = useState < string > ("all");
    const [blood, setBlood] = useState < string > ("all");
    const [houses, setHouses] = useState < string[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
    }

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

      const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

      const handleSubmit = () => {

        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/students/listInstitute/${authInfo.user.instituteID}`, {year, classi, gender, category, house, blood, toDOB, fromDOB, searchText});
                setStudents(result.data.students);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;
       
        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        try {
            const result = await api.post(`/students/listInstitute/${authInfo.user.instituteID}?skip=${limit}`, {year, classi, gender, category, house, blood, toDOB, fromDOB, searchText});

            if(result.data.students && result.data.students.length)
            {
                setStudents([...students, ...result.data.students]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSession = (sessionID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        const sessionName = sessions.find(val => val._id === sessionID)!.name;
        setYear(sessionName);
        setSession(sessionID);
        setClassi("all");
    }

    const withdrawStudent = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const withdraw = () => {
            return new Promise((resolve, reject) => {
                api.get(`/students/withdraw/${current}`).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
          }
    
          setShowLoading(true);
        withdraw()
          .then(data => {
            setPass(data+t("has_been_withdrawn"));
            setIspass(true);
            setShowLoading(false);
          })
          .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
    
            setIserror(true);
            setShowLoading(false);
          });
    }

    const graduateStudent = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const withdraw = () => {
            return new Promise((resolve, reject) => {
                api.get(`/students/graduate/${current}`).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
          }
    
        setShowLoading(true);
        withdraw()
          .then(data => {
            setPass(data+t("has_been_graduated"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
    }

    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setStudents(storedState.students || []);
                setClasses(storedState.classes || []);
                setSessions(storedState.sessions || []);
                setHouses(storedState.houses || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setSession(storedState.session || "");
                setYear(storedState.year || "");
                setClassi(storedState.classi || "all");
                setGender(storedState.gender || "all");
                setCategory(storedState.category || "all");
                setHouse(storedState.house || "all");
                setBlood(storedState.blood || "all");
                setToDate(storedState.toDate || undefined);
                setFromDate(storedState.fromDate || undefined);
                setSearchText(storedState.searchText || "");
               
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {

                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                        const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                        setSessions(res.data);

                        const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);

                        if(insti.data.houses)
                        {
                            setHouses(insti.data.houses);
                        }
                        else
                        {
                            setHouses([]);
                        }

                        if (classID)
                        {
                            const result = await api.get(`/classes/${classID}`);
                            setSession(result.data.sessionID);
                            setYear(result.data.year);

                            const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                            setClasses(query.data);
                            setClassi(classID);
                            const studs = await api.post(`/students/listInstitute/${authInfo.user.instituteID}`, {year: result.data.year, classi: classID, gender: "all", category: "all", house: "all", blood: "all", toDOB: "", fromDOB: "", searchText: ""});
                            setStudents(studs.data.students);
                            setTotal(studs.data.total);
                        }
                        else
                        {
                            setSession("");
                            setYear("");
                            setClasses([]);
                            setClassi("");
                            setStudents([]);
                            setTotal(0);
                        }
                        setSkip(0);

                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);

                    const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);

                    if(insti.data.houses)
                    {
                        setHouses(insti.data.houses);
                    }
                    else
                    {
                        setHouses([]);
                    }

                    if (classID)
                    {
                        const result = await api.get(`/classes/${classID}`);
                        setSession(result.data.sessionID);
                        setYear(result.data.year);

                        const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                        setClasses(query.data);
                        setClassi(classID);
                        const studs = await api.post(`/students/listInstitute/${authInfo.user.instituteID}`, {year: result.data.year, classi: classID, gender: "all", category: "all", house: "all", blood: "all", toDOB: "", fromDOB: "", searchText: ""});
                        setStudents(studs.data.students);
                        setTotal(studs.data.total);
                    }
                    else
                    {
                        setSession("");
                        setYear("");
                        setClasses([]);
                        setClassi("");
                        setStudents([]);
                        setTotal(0);
                    }
                    setSkip(0);

                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, classID, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            students,
            sessions,
            classes,
            houses,
            total,
            skip,
            session,
            year,
            classi,
            gender,
            category,
            house,
            blood,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [students, sessions, classes, houses, total, skip, session, year, classi, gender, category, house, blood, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_students")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_withdraw")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            withdrawStudent();
                        }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("confirm_graduate")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            graduateStudent();
                        }
                        }
                    ]}
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xxl">
                       
                        <IonCol size="1.6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_student")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_student")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => setClassi(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_category")}
                                        </IonLabel>
                                        <IonSelect value={category}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("student_category")}
                                            onIonChange={
                                                (e) => setCategory(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                            <IonSelectOption value="ews">{t("ews")}</IonSelectOption> 
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {(authInfo.institute.type === "school") && (
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_house")}
                                        </IonLabel>
                                        <IonSelect value={house}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("student_house")}
                                            onIonChange={
                                                (e) => setHouse(e.detail.value)
                                            }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            { houses.map((house, i) => {
                                                    
                                                return (<IonSelectOption key={i} value={`${house}`}>{house}</IonSelectOption>);
                                                    
                                                })
                                            }
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_gender")}
                                            </IonLabel>
                                            <IonSelect value={gender}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("student_gender")}
                                                onIonChange={
                                                    (e) => setGender(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_blood")}
                                            </IonLabel>
                                            <IonSelect value={blood}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                                placeholder={t("student_blood")}
                                                onIonChange={
                                                    (e) => setBlood(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="A+">A+</IonSelectOption>
                                                <IonSelectOption value="A-">A-</IonSelectOption> 
                                                <IonSelectOption value="B+">B+</IonSelectOption>
                                                <IonSelectOption value="B-">B-</IonSelectOption>      
                                                <IonSelectOption value="O+">O+</IonSelectOption>
                                                <IonSelectOption value="O-">O-</IonSelectOption>      
                                                <IonSelectOption value="AB+">AB+</IonSelectOption>
                                                <IonSelectOption value="AB-">AB-</IonSelectOption>         
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_students")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="3" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {(authInfo.institute.type === "school") ? t("class") : t("batch")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                            {t("dob")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("gender")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("dues")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(students.length > 0) ? (students.map((student, i) => {
                            return (
                                <IonRow key={student._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => setCurrent(student._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/student/${student._id}`)
                                }>
                                    
                                        <IonCol sizeLg="3" size="8" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={student._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{student.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {student.classInfo.className} &#8226; {student.gender && t(student.gender)}
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>

                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {student.classInfo.className}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="3" className='table-field'>
                                            {formatDated(student.dob)}
                                        </IonCol>
                                        <IonCol size="2" className='table-field text-capitalize ion-hide-lg-down'>
                                            {student.gender && t(student.gender)}
                                        </IonCol>
                                        <IonCol size="2" className='table-field ion-hide-lg-down'>
                                            {student.due}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color={student.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${student._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(students.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                   
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${students.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-xxxl'>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("manage_student")}</IonButton>

                                    {((authInfo.user.role === "Super") || (authInfo.user.role === "Admin") || (authInfo.user.role === "Accountant") || (authInfo.user.roles.includes("Accountant"))) && authInfo.institute.payment && (
                                    <>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/collection/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("collect_fees")}</IonButton>   

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/ledger/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_ledger")}</IonButton>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/invoices/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_invoices")}</IonButton>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/dues/add/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("generate_invoice")}</IonButton>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/fees/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("map_fee_structure")}</IonButton>   
                                    
                                    </>
                                    )}

                                   
                                    {((authInfo.user.role === "Super") || (authInfo.user.role === "Admin") || (authInfo.user.role === "Academic") || (authInfo.user.roles.includes("Academic"))) && (
                                    <>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    setShowAlert(true);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("withdraw_student")}</IonButton>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    setShowAlert1(true);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("graduate_student")}</IonButton>

                                    {authInfo.institute.communication && (
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/inquiries/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_communications")}</IonButton>
                                    )}

                                    {authInfo.institute.medical && (
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/incidents/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_records")}</IonButton>
                                    )}

                                    {authInfo.institute.reportcard && (
                                    <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_cards")}</IonButton>
                                    )}
                                    
                                    {!authInfo.institute.timetable && (
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/submissions/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_submissions")}</IonButton>
                                    )}
                                </>
                                )}
                              
                                <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("export_excel")}</IonButton>


                                </IonCol>
                            </IonRow>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Students;
