import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface Due {
    _id: string,
    item: Item,
    qty: number,
    amount: number,
    createdAt: Date
}
interface Item {
    itemID: string,
    itemName: string
}
interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date
}

interface ParamTypes {
    vendorID: string
}

const VendorLedger: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {vendorID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [transactions, setTransactions] = useState < Transaction[] > ([]);
    const [dues, setDues] = useState < Due[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("");
    const [due, setDue] = useState < number > (0);
    const [balance, setBalance] = useState < number > (0);
    const [name, setName] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

      const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };


      const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/transactions/listVendor/${vendorID}`, {duration, toDOB, fromDOB});
                setTransactions(result.data.transactions);

                const res = await api.post(`/itemTransactions/listByVendor/${vendorID}`, {duration, toDOB, fromDOB});
                setDues(res.data.credits);
                console.log(res.data.credits);
                
                setBalance(res.data.total - result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }


    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/vendors/${vendorID}`);
                setName(stud.data.name);
                setDue(stud.data.due);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, vendorID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_ledger")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_ledger")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("due_duration")}
                                            </IonLabel>
                                            <IonSelect value={duration}
                                                placeholder={t("due_duration")}
                                                onIonChange={
                                                    (e) => setDuration(e.detail.value)
                                                }>
                                                <IonSelectOption value="one">{t("last_month")}</IonSelectOption>
                                                <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                     
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol size="6" className='ion-text-center'>
                           <span className="text-capitalize">{name}</span>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_transactions")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className=" mt-10">
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("dues")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                    {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                    {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>
                               

                                {(dues.length > 0) ? (dues.map((due, i) => {
                                    return (
                                        <IonRow key={due._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                                <IonCol size="1" className='table-field' >
                                                    {i+1}.
                                                    
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    {formatDated(due.createdAt)}
                                                </IonCol>
                                                <IonCol size="5" className='table-field'>
                                                   {due.qty} x {due.item.itemName}
                                                </IonCol>
                                                <IonCol size="3" className='table-field ion-text-right'>
                                                    {due.amount}
                                                </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_dues")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                                </div>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("payments")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                    {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                    {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>
                               

                                {(transactions.length > 0) ? (transactions.map((tran, i) => {
                                    return (
                                        <IonRow key={tran._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                             
                                                <IonCol size="1" className='table-field' >
                                                    {i+1}.
                                                    
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    {formatDated(tran.dated)}
                                                </IonCol>
                                                <IonCol size="5" className='table-field'>
                                                    {tran.remarks}
                                                </IonCol>
                                                <IonCol size="3" className='table-field ion-text-right'>
                                                    {Math.abs(tran.amount)}
                                                </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_payments")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                                </div>
                        </IonCol>
                    </IonRow>
                    
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            <span>{t("page_balance")} {balance}</span><span className="ion-margin-start">{t("total_balance")} {due}</span>
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                            <IonButton 
                                onClick={
                                    () => history.push(`/app/vendors/payment/${vendorID}`)
                                       
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("make_payment")}</IonButton>

                           <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>

                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default VendorLedger;