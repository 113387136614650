import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

const AddLeave: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [dates, setDates] = useState < string | string[] > ([]);
    const [upload, setUpload] = useState < File > ();
    
    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
            if (null !== (fileChangeEvent.target.files))
            {
                if(fileChangeEvent.target.files[0]){
                    const file = fileChangeEvent.target.files[0];  
                    if (file && file.name && file.type) {
                        setUpload(file);
                        
                    }
                } 
            }
        }

    const formatDate = (value: string | string[]) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else if (value && value.length)
        {
            return (value.map((tag, i) => (<IonText className='tran-tag' key={i}>{tag}</IonText>)));
           
        }
        else
        {
            return t("leave_dates");
        }
      };

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("leave_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("cause_mandatory"));
            setIserror(true);
            return;
        }

        if (dates.length === 0)
        {
            setMessage(t("leave_dates_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addInq = () => {
          return new Promise((resolve, reject) => {
            api.post('/staffChats/add', { 'employeeID': authInfo.user._id, title, text, type: 'leave', dates }).then(res => {
      
              return resolve(res.data.staffChat.title);
              
            }).catch(err => reject(err));
  
          });
        }

        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/staffChats/attach/add', formData).then(res => {
                return resolve(res.data.staffChat.title);
              }).catch(err => reject(err));
            });
          }

          if (upload) {
            
            let formData = new FormData();

            formData.append('employeeID', authInfo.user._id);
            formData.append('title', title);
            formData.append('type', 'leave');
            formData.append('text', text);
            formData.append('dates', JSON.stringify(dates));
            formData.append('attachment', upload, 'attachment');

            setShowLoading(true);
            addAttach(formData)
                .then(data => {
                    setPass(data+t("has_been_submitted"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                setShowLoading(true);
                addInq()
                .then(data => {
                
                    setPass(data + t("has_been_submitted"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }

      }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/user/leaves"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("leave_request")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>



            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("leave_request")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/user/leaves`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("leave_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("leave_title")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("leave_title")}
                                                value={title}
                                                onIonInput={
                                                    (e) => setTitle(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            
                                            <IonLabel className="input-field ion-text-wrap" color="secondary">{formatDate(dates)}</IonLabel>
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("leave_dates")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={dates}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        multiple={true}
                                                        onIonChange={ev => setDates(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                             
                                    <IonItem>
                                        <IonLabel position="floating" className="input-label" color="secondary">{t("leave_text")}*</IonLabel>
                                        <IonTextarea rows={8} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        >
                                        <IonLabel position="stacked" color="secondary">{t("attachement")}</IonLabel>
                                        <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={ev => onFileChange(ev)}></input>
                                    </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("submit")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddLeave;
