import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Workshop {
    _id: string,
    name: string,
    status: string,
    attendees: number,
    startDate: Date
}

const Workshops: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [workshops, setWorkshops] = useState < Workshop[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [status, setStatus] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [searchText, setSearchText] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
    
    const customAlertOptions = {
        header: t("change_status"),
        translucent: true,
      };
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

    const handleStatus = (status: string, id: string, index: number) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const upCourse = () => {
            return new Promise((resolve, reject) => {
              api.put('/admissionCourses/'+id, { status }).then(res => {
                    return resolve(res.data.name);
              }).catch(err => reject(err));
            });
          }

            
          setShowLoading(true);
            upCourse()
            .then(data => {

                let subs = [...workshops];
                workshops[index].status = status;
                setWorkshops(subs);
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
    }



    const handleSubmit = () => {


        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/admissionCourses/list/${process.env.REACT_APP_APP_ID}`, {status, toDOB, fromDOB, searchText});
                setWorkshops(result.data.workshops);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;
       

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        try {
            const result = await api.post(`/admissionCourses/list/${process.env.REACT_APP_APP_ID}?skip=${limit}`, {status, toDOB, fromDOB, searchText});

            if(result.data.workshops && result.data.workshops.length)
            {
                setWorkshops([...workshops, ...result.data.workshops]); 
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setWorkshops(storedState.workshops || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setStatus(storedState.status || "all");
                setToDate(storedState.toDate || undefined);
                setFromDate(storedState.fromDate || undefined);
                setSearchText(storedState.searchText || "");
               
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
        }
        setInitialRender(false);

    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            workshops,
            total,
            skip,
            status,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [workshops, total, skip, status, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_workshops")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_workshops")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false)
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_workshop")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_workshop")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("workshop_status")}</IonLabel>
                                            <IonSelect value={status}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("workshop_status")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setStatus(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                    <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                    <IonSelectOption value="cancelled">{t("cancelled")}</IonSelectOption>

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_workshops")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("workshop_details")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("status")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("workshop_start")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("attendees")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(workshops.length > 0) ? (workshops.map((workshop, i) => {
                                return (
                                    <IonRow key={workshop._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(workshop._id)
                                    }
                                    onDoubleClick={() => history.push(`/app/workshop/update/${workshop._id}`)}>
                                        
                                            <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol size='2'>
                                                        <IonRadio value={workshop._id} className='table-icon' color="secondary" />
                                                    </IonCol>
                                                    <IonCol size="10">
                                                        <IonText className='text-capitalize'>{workshop.name}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel className="display-table">
                                                        <span className='table-select'>{workshop.startDate && formatDate(workshop.startDate)} &#8226; &nbsp;</span>
                                                        <IonSelect value={workshop.status}
                                                                className='table-select'
                                                                interfaceOptions={customAlertOptions}
                                                                placeholder={t("status")}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                onIonChange={
                                                                    (e) => handleStatus(e.detail.value, workshop._id, i)
                                                                }>
                                                            
                                                            <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                            <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                            <IonSelectOption value="cancelled">{t("cancelled")}</IonSelectOption>
                                                                
                                                            </IonSelect> 
                                                            
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                              
                                            </IonCol>
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                
                                                    <IonSelect value={workshop.status}
                                                        className='table-select'
                                                        interfaceOptions={customAlertOptions}
                                                        placeholder={t("status")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => handleStatus(e.detail.value, workshop._id, i)
                                                        }>
                                                    
                                                    <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                    <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                    <IonSelectOption value="cancelled">{t("cancelled")}</IonSelectOption>
                                                        
                                                    </IonSelect>
                                               
                                            </IonCol>
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {workshop.startDate && formatDate(workshop.startDate)}
                                                        
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                            {workshop.attendees}
                                            </IonCol>
                                            <IonCol size="1" >
                                                <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => history.push(`/app/workshop/update/${workshop._id}`)
                                                    }
                                                />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_workshops")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(workshops.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${workshops.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_workshop"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/workshop/update/${current}`);
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_details")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_workshop"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/workshop/attendees/${current}`);
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_attendees")}</IonButton>


                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Workshops;