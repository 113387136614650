import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircleOutline, alertCircleOutline, eye, informationCircleOutline, trash } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import {useAuth} from '../../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface Feed {
    _id: string,
    title: string,
    media: string,
    isActive: boolean
}

const Gallery: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [feed, setFeed] = useState < Feed[] > ([]);
    const [current, setCurrent] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedPic, setSelectedPic] = useState < string > ("");
    const [showPopover, setShowPopover] = useState < boolean > (false);
    const [type, setType] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
            
        }
            
      }

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("gallery_format"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("pic_title_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upInstitute = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/feeds/add', formData).then(res => {
        
                return resolve(res.data.feed.title);
                
              }).catch(err => reject(err));
    
            });
          }

          
      // Change uploadFile to blob in native implementation using capacitor plugin
      let formData = new FormData();
      formData.append('feed', uploadFile, uploadFile.name);
      formData.append('instituteID', authInfo.user.instituteID);
      formData.append('title', title);

      setShowLoading(true);
    upInstitute(formData)
        .then(data => {
           setShowPopover(false);
            setPass(title+t("has_been_uploaded"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
        
      }

      const handleDelete = () => {

          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.delete(`/feeds/${selectedPic}`).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }

        setShowLoading(true);
      deleteApp()
        .then(() => {
  
          setPass(t("pic_deleted"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    const handleSubmit = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upInsti = (media: string) => {
          return new Promise((resolve, reject) => {
            api.put('/institutes/'+authInfo.user.instituteID, { 'admnPhoto': media }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        if (current !== "")
        {
            setShowLoading(true);
            upInsti(current)
            .then(data => {
    
                setPass(t("selected_pic_has")+data+t("cover_photo"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
            });
        }
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/institutes/' + authInfo.user.instituteID);
                setType(result.data.type);

                if (result.data.admnPhoto)
                {
                    setCurrent(result.data.admnPhoto);
                }
                else
                {
                    setCurrent("");
                }

                const feed = await api.get(`/feeds/getByInstitute/${authInfo.user.instituteID}`);
                setFeed(feed.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("picture_gallery")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("picture_gallery")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_deletion")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

               <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {(type === "school") ? t("gallery_info") : t("gallery_insti")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec'>
                                {t("preview")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding ion-margin-top">
                    <IonRow className="ion-no-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <Swiper 
                                modules={[Autoplay]}
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}            
                                autoplay={{
                                    delay: 3000
                                }}
                            >

                                {(feed.length > 0) && feed.map((post) => (
                                (post.media) &&
                                    (
                                    <SwiperSlide key={post._id}>
                                        <img src={`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`} alt={post.title}  style={{'width': '100%', 'height': 'auto'}} />
                                    </SwiperSlide>  
                                    )
                                ))}
                                
                            </Swiper>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'> 
                        
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    onClick={() => setShowPopover(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("upload_picture")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover size='cover' isOpen={showPopover} onDidDismiss={e => setShowPopover(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={title}
                                                        placeholder={t("title")}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("picture")}*</IonLabel>
                                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                                                
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handlePhoto}
                                            expand="block"
                                            color="secondary">{t("upload_picture")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>

                        

                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("all_pictures")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                          <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>
                             {(feed.length > 0) ? (feed.map((post, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={post._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            >
                                            <IonRadio slot="start" value={post.media} ></IonRadio>
                                            
                                            <IonLabel className='list-label'>
                                                <h3>{post.title} </h3>
                                            </IonLabel>
                                            <IonButtons slot="end">
                                                <IonButton onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`, post.title)}>
                                                    <IonIcon 
                                                    icon={eye}
                                                    color="dark"
                                                    
                                                    />
                                                 </IonButton>
                                                <IonButton onClick={() => {
                                                                        setSelectedPic(post._id);
                                                                        setShowAlert(true);
                                                                    }}>
                                                    <IonIcon 
                                                    icon={trash}
                                                    color="danger"
                                                    
                                                    />
                                                 </IonButton>
                                            </IonButtons> 
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_pictures")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } 
                                </IonRadioGroup>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("set_as_cover")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Gallery;
