import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, alertCircleOutline, closeCircle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    feeID: string
}

interface Component {
    head: FeeHead,
    frequency: string,
    amount: number
}

interface FeeHead {
    headID: string,
    name: string
}

interface AllHead {
    _id: string,
    name: string,
    isActive: boolean
}
const FeeDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {feeID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [components, setComponents] = useState < Component[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [heads, setHeads] = useState < AllHead[] > ([]);

    const handleAmount = (amt: string, index: number) => {

        let comps = [...components];
        comps[index].amount = parseInt(amt);
        setComponents(comps);
    
    }

    const handleHead = (headID: string, index: number) => {

        const head = heads.find(val => val._id === headID);
        if (head)
        {
            const name = head.name;
            const feehead: FeeHead = {
                headID,
                name
            };
            let comps = [...components];
            comps[index].head = feehead;
            setComponents(comps);
        }
    }

    const handleFreq = (freq: string, index: number) => {

        let comps = [...components];
        comps[index].frequency = freq;
        setComponents(comps);

    }

    const handleDelete = (index: number) => {
        let comps = [...components];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
        }
        setComponents(comps);
    }

    const handleAdd = () => {
        let comps = [...components];
        comps.push({head: {headID: "", name: ""}, frequency: '', amount: 0});
        setComponents(comps);
    }

    const handleSection = (section: string) => {
    
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

    const toggleFee = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/fees/${feeID}`, { 'isActive': isActive }).then(res => {
    
                return resolve(res.data.title);
                
              }).catch(err => reject(err));

        });
      }
      if ((active !== "") && (section !== active))
      {
          setShowLoading(true);
            toggleFee(section)
            .then(data => {
        
                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("fee_title_mandatory"));
            setIserror(true);
            return;
        }

        if (components.length < 1)
        {
            setMessage(t("fee_comp_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < components.length; i++)
        {
            if (!components[i].head || !components[i].frequency || !components[i].amount 
                || components[i].head.headID === '' || components[i].head.name === '' || components[i].frequency === '' || components[i].amount === 0)
                {
                    setMessage(t("fee_comp_invalid"));
                    setIserror(true);
                    return;
                }
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upFee = (title: string, components: Component[]) => {
            return new Promise((resolve, reject) => {
              api.put('/fees/'+feeID, { title, components }).then(res => {
        
                return resolve(res.data.title);
                
              }).catch(err => reject(err));
    
            });
          }
      
          setShowLoading(true);
        upFee(title, components)
          .then(data => {
    
              setPass(data+t("has_been_updated"));
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
          });
  
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/fees/' + feeID);

                setTitle(result.data.title);
                setComponents(result.data.components);
                
              
                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                const res = await api.get(`/heads/getAllInstitute/${result.data.instituteID}`);
                setHeads(res.data);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, feeID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/fees"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_fee_struct")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_fee_struct")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("fee_struct_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("fee_struct_title")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("fee_struct_title")}
                                                value={title}
                                                onIonInput={
                                                    (e) => setTitle(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("fee_struct_comps")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        <div className="ion-table">

                            <IonRow className="table-title ion-padding">
                                <IonCol size="4" className='table-heading heading-fixed mt-05'>
                                        {t("fee_head")}
                                </IonCol>
                                <IonCol size="4" className='table-heading mt-05'>
                                        {t("fee_frequency")}
                                </IonCol>
                                <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                        {t("fee_amount")}
                                </IonCol>

                                <IonCol size="1">
                                    <IonIcon 
                                        icon={addCircle}
                                        color="light"
                                        className='table-icon ml-05'
                                        onClick={handleAdd}
                                        size="large"
                                        />
                                </IonCol>
                            </IonRow>
                       

                            {(components.length > 0) ? (components.map((component, i) => {
                                return (
                                    <IonRow key={i} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            <IonCol size="4" className={((i%2) === 0) ? 'table-field field-fixed' : 'table-field field-fixed-alt'} >
                                                <IonItem>
                                                    <IonSelect value={component.head.headID}
                                                            className='ion-no-padding' 
                                                            style={{'maxWidth': '100%'}} 
                                                            placeholder={t("fee_head")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleHead(e.detail.value!, i)
                                                        }>
                                                            <IonLabel>{t("fee_frequency")}</IonLabel>
                                                            {heads.length > 0 && heads.map((head) => (
                                                                (head.isActive) && 
                                                                (<IonSelectOption key={head._id} value={head._id}>{head.name}</IonSelectOption>)
                                                            ))}
  
                                                    </IonSelect>
                                                </IonItem>
                                            </IonCol>

                                            <IonCol size="4" className='table-field' >
                                                <IonItem>
                                                    <IonSelect placeholder={t("fee_frequency")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={component.frequency}
                                                        onIonChange={
                                                            (e) => handleFreq(e.detail.value!, i)
                                                        }
                                                        >
                                                        <IonLabel>{t("fee_frequency")}</IonLabel>
                                                        <IonSelectOption value="once">{t("once")}</IonSelectOption>
                                                        <IonSelectOption value="annually">{t("annually")}</IonSelectOption>      
                                                        <IonSelectOption value="quaterly">{t("quaterly")}</IonSelectOption>   
                                                        <IonSelectOption value="monthly">{t("monthly")}</IonSelectOption>   
                                                    </IonSelect>
                                                    
                                                </IonItem>
                                               
                                            </IonCol>

                                            <IonCol size="3" className='table-field ' >
                                                <IonItem className='fee-comp-height'>
                                                    <IonInput type="number"
                                                        className='ion-text-right'
                                                        placeholder='0'
                                                        value={component.amount}
                                                        onIonInput={
                                                            (e) => e.detail.value && handleAmount(e.detail.value, i)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    className='mt-15 ml-05'
                                                     icon={closeCircle}
                                                     color='dark'
                                                     onClick={() => handleDelete(i)}
                                                     size="large"
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_fee_comps")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 

                        </div>
                    </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_fee_struct")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default FeeDetail;
