import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import {useAuth} from '../../../components/AuthContext';
import { close } from 'ionicons/icons';
import { Toast } from '@capacitor/toast';
import {NativeAudio} from '@capacitor-community/native-audio';
import { useTranslation } from "react-i18next";

const ScanExit: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");


    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      };

    const handleExit = async (data : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const exit = ( studentID: string) => {
            return new Promise((resolve, reject) => {
              api.get('/attendances/exit/' + studentID).then(res => {
        
                return resolve(res.data.student);
                
              }).catch(err => reject(err));
    
            });
          }


        const studentID = data.split("/").pop();


        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {
            setShowLoading(true);
            exit(studentID)
            .then(async (res: any) => {
                NativeAudio.play({
                    assetId: 'attend'
                });
                setShowLoading(false);
                openScanner();
                await Toast.show({
                    text: `${res.name} ${t("marked_exit")} ${formatDateTime(new Date(Date.now()))}`,
                  });
            })
            .catch(async (error) => {
                setShowLoading(false);
                openScanner();
              if (error.response !== undefined)
                {
                    await Toast.show({
                    text: error.response.data.message,
                    });
                }
              else
                  {
                    await Toast.show({
                        text: t("qr_error"),
                      });
                  }
            });
        }
        else 
        {
            openScanner();
            await Toast.show({
                text: t("qr_error"),
              });
        }

    }

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        await BarcodeScanner.showBackground();
        await BarcodeScanner.stopScan();
    };


    const openScanner = async () => {

        try {
                const status = await BarcodeScanner.checkPermission({ force: true });

                if (status.granted) {
            
                    await BarcodeScanner.hideBackground();
                    document.body.classList.add('scanner-active');

                    const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] });

                    if (result.hasContent) {
                        handleExit(result.content); 
                    }
                    else {
                        openScanner();
                        await Toast.show({
                            text: t("qr_error"),
                        });
                    }

                }

                if (status.denied) {
                    setMessage1(t("camera_denied"));
                    setIserror1(true);
                }

        } catch (error: any) {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
      };

    useEffect(() => {

        NativeAudio.preload({
            assetId: "attend",
            assetPath: "attend.mp3",
            audioChannelNum: 1,
            isUrl: false
        });
        openScanner();

        return () => {
            stopScan();
            NativeAudio.unload({
                assetId: 'attend'
              });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_exit")}</IonTitle>
                    <IonButtons slot="end">

                            <IonButton onClick={() => {
                                stopScan();
                                history.push(`/app`);
                            }}>
                                <IonIcon icon={close} color="secondary" size="large" />
                            </IonButton>
                      
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_exit")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIserror(false);
                            history.push(`/app`);
                        }
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIserror1(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("open_settings")}`]
                    }/>

            </IonContent>
        </IonPage>
    );
};

export default ScanExit;
