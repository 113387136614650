import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonNote,
    IonPage,
    IonPopover,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../components/AuthContext";
import { addCircleOutline, alertCircleOutline, chevronForwardOutline, imageOutline, informationCircleOutline, star, starOutline, trashOutline, } from 'ionicons/icons';
import {Calendar, Day} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { PhotoViewer } from '@capacitor-community/photoviewer';

interface Attach {
    caption: string,
    filename: string
}

interface FileUpload {
    caption: string,
    file: File,
    imageURL: string
}

interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

const DailyReports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [today, setToday] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [pop, setPop] = useState < boolean > (false);
    const [work, setWork] = useState < string > ("");
    const [files, setFiles] = useState < Attach[] > ([]);
    const [fileUploads, setFileUploads] = useState < FileUpload[] > ([]);
    const [caption, setCaption] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < string[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [reportID, setReportID] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");
    const [rating, setRating] = useState < number > (0);
    const [mode, setMode] = useState < string > ("");

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    })

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                        t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                        ];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    }

    // Can get multiple files
    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];
            for (let i=0; i < fileChangeEvent.target.files.length; i++)
            {
                if(fileChangeEvent.target.files[i]){
                    const file = fileChangeEvent.target.files[i];  
                    if (file && file.name && file.type) {

                        theUploads.push(file);
                        const blob = await fileToDataUri(file);
                        theURLs.push(blob);
                    }
                } 
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        }
    }

    const checkPast = (choosen : Day, aaj: Day) => {
        const dateString = choosen.month+"/"+choosen.day+"/"+choosen.year;
        const dateObject = new Date(dateString);
        const aajString = aaj.month+"/"+aaj.day+"/"+aaj.year;
        const aajObject = new Date(aajString);

        if(dateObject < aajObject) return true;
        else return false;
    }

    // Handle IonDate Popover Change (Displayed Month)
    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);
            const onDate = new Date(theDate);

            const minDate = {
                day: 1,
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
                
            }
            setMinDay(minDate);
            setSelectedDay(minDate);

            const maxDate = {
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
            }
            setMaxDay(maxDate);
            
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/dailyReports/getOneByMonth/${authInfo.user._id}/${theDate}`);

                    let result: Array < Attend > = [];
                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);

                    const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': minDate});
                    if (res.data)
                    {
                        setReportID(res.data._id);
                        setWork(res.data.work);
                        setFiles(res.data.files);
                        setResponse(res.data.response);
                        setRating(res.data.rating);
                    }
                    else
                    {
                        setReportID("");
                        setWork("");
                        setFiles([]);
                        setResponse("");
                        setRating(0);
                    }

                    setShowLoading(false);
                
                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    // Handle Day Change on Calendar
    const handleDate = async (dated : Day) => {

        setSelectedDay(dated);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': dated});
                if (res.data)
                {
                    setReportID(res.data._id);
                    setWork(res.data.work);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                    setMode(res.data.mode);
                }
                else
                {
                    setReportID("");
                    setWork("");
                    setFiles([]);
                    setResponse("");
                    setRating(0);
                    setMode("");
                }

                
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();     
    }

    const handleAdd = () => {

        if (!caption || caption === "") {
            setMessage(t("caption_mandatory"));
            setIserror(true);
            return;
        }

        if (!upload.length || !imageURL.length || (upload.length !== imageURL.length)) {
            setMessage(t("file_mandatory"));
            setIserror(true);
            return;
        }

        let uploads = [...fileUploads];
        for (let i=0; i < upload.length; i++)
        {
            uploads.push({caption: `${caption} (${i+1})`, file: upload[i], imageURL: imageURL[i]});
        }
        
        setFileUploads(uploads);
        setUpload([]);
        setImageURL([]);
        setPop(false);

    }

    const deleteImage = (index: number) => {

        let uploads = [...fileUploads];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFileUploads(uploads);

    }

    // Delete already saved image
    const handleDelete = (index: number) => {

        let uploads = [...files];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
              }
          });

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/dailyReports/'+reportID, { files: uploads }).then(res => {
        
                    return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        setShowLoading(true);
        upReport()
        .then(data => {
            setFiles(uploads);
            setPass(t("report_updated"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
    }

    // Submit report if Today ie mode = 'submit', else Save Report mode = 'save'
    const handleReport = (mode: string) => {

        if (!work || work === "") {
            setMessage(t("work_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDay) {
            setMessage(t("event_date"));
            setIserror(true);
            return;
        }

        const byear = selectedDay.year;
        const bmonth = selectedDay.month;
        const zeromonth = ('0'+bmonth).slice(-2);
        const bday = selectedDay.day;
        const zeroday = ('0'+bday).slice(-2);
        const calendarDate = new Date(`${byear}-${zeromonth}-${zeroday}T06:00:00.000`);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        //If reportID !== "" && fileUploads.length === 0 => Just update work
        //If reportID !== "" && fileUploads.length > 0 => update work & send files
        //If reportID === "" && fileUploads.length === 0 -> add report with just work
        //If reportID === "" && fileUploads.length > 0 -> add report with work and files

        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/dailyReports/add', {'instituteID': authInfo.user.instituteID, 'employeeID': authInfo.user._id, 'name': authInfo.user.name, work, calendarDate, mode}).then(res => {
        
                return resolve(res.data.dailyReport);
                
              }).catch(err => reject(err));
    
            });
          }

        const addReportAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/dailyReports/attach/add', formData).then(res => {
                    return resolve(res.data.dailyReport);
              }).catch(err => reject(err));
            });
          }

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/dailyReports/'+reportID, { work, mode }).then(res => {
        
                    return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
            api.post('/dailyReports/attach/update', formData).then(res => {
                return resolve(res.data.dailyReport);
            }).catch(err => reject(err));
            });
        }


        setShowLoading(true);

        if (reportID !== "")
        {
            if (fileUploads.length === 0)
            {
                upReport()
                .then(data => {
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                let formData = new FormData();

                formData.append('reportID', reportID);
                formData.append('work', work);
                formData.append('mode', mode);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('report[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                upAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
        }
        else
        {
            if (fileUploads.length === 0)
            {
                addReport()
                .then(data => {
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                let formData = new FormData();

                formData.append('instituteID', authInfo.user.instituteID);
                formData.append('employeeID', authInfo.user._id);
                formData.append('name', authInfo.user.name);
                formData.append('work', work);
                formData.append('theDate', `${byear}-${zeromonth}-${zeroday}T06:00:00.000`);
                formData.append('mode', mode);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('report[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                addReportAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
                // Default selected on IonDate Popover - max date removed to allow saving of future reports
    
                const minDate = {
                    day: 1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setMinDay(minDate);

                const maxDate = {
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setMaxDay(maxDate);

                const events = await api.get(`/dailyReports/getOneByMonth/${authInfo.user._id}/${onDate.toISOString()}`);
                let result: Array < Attend > = [];
                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                
                const todayDate = {
                    day: onDate.getDate(),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setSelectedDay(todayDate);
                setToday(todayDate);

                const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': todayDate});
                if (res.data)
                {
                    setReportID(res.data._id);
                    setWork(res.data.work);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                    setMode(res.data.mode);
                }
                
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);  
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, refreshed]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/user/tasks`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("daily_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("daily_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
   

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("report_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                   
                            <Calendar 
                                value={selectedDay}
                                onChange={handleDate}
                                minimumDate={minDay}
                                maximumDate={maxDay}
                                shouldHighlightWeekends={true}
                                customDaysClassName={attend}
                                calendarClassName="custom-calendar"
                            />
                       
                         )}
                        </IonCol>
                    </IonRow>
                    {(selectedDay && today && (
                    <>
                        {(rating !== undefined) && (rating !== 0) && (
                        <>
                        <IonRow className="mt-30">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("admin_rating")}
                                    </IonTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-10">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                   
                                        {(rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                            </IonCol>
                        </IonRow>
                        </>
                        )}
                        {(response !== undefined) && (response !== "") && (
                        <>
                        <IonRow className="mt-30">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("admin_response")}
                                    </IonTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonLabel position="floating" color="dark">{t("response_details")}</IonLabel>
                                            <IonTextarea rows={6} autoGrow={true} value={response} readonly={true}></IonTextarea>
                                        </IonItem>
                                                
                                            
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        </>
                        )}
                        <IonRow className="ion-margin-top">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonNote className='border-bottom-sec ion-padding'>
                                    {t("work_details_for")} {formatDated(selectedDay)}<br/>
                                    {(mode === "submit") ? t("report_submitted") : (mode === "save") ? t("report_saved") : t("report_not") }
                                    </IonNote>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonLabel position="floating" color="dark">{t("work_details")}</IonLabel>
                                            <IonTextarea rows={6} autoGrow={true} value={work} disabled={checkPast(selectedDay, today)} onIonInput={
                                                    (e) => setWork(e.detail.value!)
                                            }></IonTextarea>
                                        </IonItem>
                                                
                                            
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {!checkPast(selectedDay, today) && (
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard className='action-card-one'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem lines="none"
                                        button={true}
                                        onClick={() => setPop(true)}
                                        >
                                            <IonIcon icon={addCircleOutline}
                                                slot="start"
                                                color="secondary" />
                                            <IonLabel className="action-title-one"><h3>{t("add_file")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                                <IonPopover size='cover' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonLabel position="stacked" color="secondary">{t("file_caption")}</IonLabel>
                                                        <IonInput type="text"
                                                            value={caption}
                                                            placeholder={t("file_caption")}
                                                            onIonInput={
                                                                (e) => setCaption(e.detail.value !)
                                                        }></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("select_files")}*</IonLabel>
                                                        <input type="file" accept=".jpg, .jpeg, .png" multiple={true} onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                                                    
                                                    </IonItem>
                                                
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-padding-horizontal">
                                            <IonButton onClick={handleAdd}
                                                expand="block"
                                                color="secondary">{t("add_files")}</IonButton>

                                        </IonCol>
                                    </IonRow>
                                </IonPopover>
                            </IonCol>
                        </IonRow>
                        )}
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                {(files.length > 0) && (files.map((afile, i) => {
                                    return (

                                        <IonCard key={i}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonItem lines="none"
                                                button={false}
                                                >
                                                    <IonIcon icon={imageOutline}
                                                        slot="start"
                                                        color="tertiary" 
                                                        onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/reports/${afile.filename}`, afile.caption)} />
                                                    <IonLabel className="action-title-two" onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/reports/${afile.filename}`, afile.caption)}><h3>{afile.caption}</h3></IonLabel>
                                                    {(mode === "save") && (
                                                    <IonIcon icon={trashOutline}
                                                        slot="end"
                                                        color="danger"
                                                        onClick={() => handleDelete(i)} />
                                                    )}
                                                    <IonIcon icon={chevronForwardOutline}
                                                        slot="end"
                                                        color="dark"
                                                        onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/reports/${afile.filename}`, afile.caption)} />

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}))}

                                    {(fileUploads.length > 0) && (fileUploads.map((afile, i) => {
                                    return (

                                        <IonCard key={i}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonItem lines="none"
                                                button={false}
                                                >
                                                    <IonIcon icon={imageOutline}
                                                        slot="start"
                                                        color="tertiary"
                                                        onClick={() => openImage(afile.imageURL, afile.caption)} />
                                                    <IonLabel className="action-title-two" onClick={() => openImage(afile.imageURL, afile.caption)}><h3>{afile.caption}</h3></IonLabel>
                                                    <IonIcon icon={trashOutline}
                                                        slot="end"
                                                        color="danger"
                                                        onClick={() => deleteImage(i)} />
                                                    <IonIcon icon={chevronForwardOutline}
                                                        slot="end"
                                                        color="dark"
                                                        onClick={() => openImage(afile.imageURL, afile.caption)} />

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}))}

                                    {(fileUploads.length === 0) && (files.length === 0) && (
                                        <IonCard className='red-card'>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start" 
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_files_uploaded")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}
                            </IonCol>
                        </IonRow>

                    </>
                    ))}

                </IonGrid>
            </IonContent>
            {selectedDay && today && !checkPast(selectedDay, today) && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(JSON.stringify(selectedDay) === JSON.stringify(today)) && (
                            <IonButton onClick={() => handleReport('submit')}
                                className="first-button"
                                fill="clear"
                                > {t("submit_daily_report")}</IonButton>
                            )}
                            {(mode !== "submit") && (
                            <IonButton onClick={() => handleReport('save')}
                                className="second-button"
                                fill="clear"
                                > {t("save_daily_report")}</IonButton>
                            )}

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
}

export default DailyReports;
