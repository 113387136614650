import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import AcademicHome from "../pages/Home/AcademicHome";
import Student from "../pages/Admin/Dashboard/Student";
import ManageTeachers from "../pages/Employee/Dashboard/ManageTeachers";
import ManageClasses from "../pages/Employee/Dashboard/ManageClasses";
import ManageTimeTables from "../pages/Employee/Dashboard/ManageTimeTables";
import ManageReportCards from "../pages/Employee/Dashboard/ManageReportCards";
import ManageSchedule from "../pages/Employee/Dashboard/ManageSchedule";
import ManageCertificates from "../pages/Employee/Dashboard/ManageCertificates";

import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import Teachers from "../pages/Employee/Teacher/Teachers";
import AddTeacher from "../pages/Employee/Teacher/AddTeacher";
import EmployeeDetail from "../pages/Admin/Staff/EmployeeDetail";
import BasicInfo from "../pages/Admin/Staff/BasicInfo";
import AccountInfo from "../pages/Admin/Staff/AccountInfo";
import EmployeeAttendance from "../pages/Admin/Staff/EmployeeAttendance";
import StaffAttend from "../pages/Admin/Staff/StaffAttend";
import AttendView from "../pages/Admin/Staff/AttendView";
import StaffLeaves from "../pages/Admin/Staff/StaffLeaves";
import StaffEntryExit from "../pages/Admin/Staff/StaffEntryExit";
import TeacherStatus from "../pages/Employee/Teacher/TeacherStatus";
import TeacherLog from "../pages/Employee/Teacher/TeacherLog";

import EntryExit from "../pages/Admin/Attendance/EntryExit";
import StudentLog from "../pages/Admin/Attendance/StudentLog";
import InstituteLog from "../pages/Admin/Attendance/InstituteLog";
import Attend from "../pages/Admin/Attendance/Attend";
import AttendClass from "../pages/Admin/Attendance/AttendClass";
import AttendStatus from "../pages/Admin/Attendance/AttendStatus";
import LeaveApplications from "../pages/Admin/Attendance/LeaveApplications";
import ChatDetail from "../pages/Admin/Staff/Chat/ChatDetail";

import ScanEntry from "../pages/Admin/Attendance/ScanEntry";
import ScanExit from "../pages/Admin/Attendance/ScanExit";
import ScanPresent from "../pages/Admin/Attendance/ScanPresent";

import Classes from "../pages/Admin/Class/Classes";
import AddClass from "../pages/Admin/Class/AddClass";
import PromoteClass from "../pages/Admin/Class/PromoteClass";
import ClassDetail from "../pages/Admin/Class/ClassDetail";
import ClassIdCards from "../pages/Admin/Class/ClassIdCards";
import ClassSubjects from "../pages/Admin/Class/ClassSubjects";
import ClassTimeTables from "../pages/Admin/Class/ClassTimeTables";

import Students from "../pages/Admin/Student/Students";
import AddStudent from "../pages/Admin/Student/AddStudent";
import StudentDetail from "../pages/Admin/Student/StudentDetail";
import StudentLocation from "../pages/Admin/Student/StudentLocation";
import StudentAttendance from "../pages/Admin/Student/StudentAttendance";
import StudentIncidents from "../pages/Admin/Student/StudentIncidents";
import StudentGuardians from "../pages/Admin/Student/StudentGuardians";
import StudentProfile from "../pages/Admin/Student/StudentProfile";
import ParentProfile from "../pages/Admin/Student/ParentProfile";
import WithdrawnStudents from "../pages/Admin/Student/WithdrawnStudents";
import GraduatedStudents from "../pages/Admin/Student/GraduatedStudents";

import Circulars from "../pages/Admin/Circular/Circulars";
import AddCircular from "../pages/Admin/Circular/AddCircular";
import CircularDetail from "../pages/Admin/Circular/CircularDetail";
import AddInquiry from "../pages/Admin/Inquiry/AddInquiry";
import PendingInquiries from "../pages/Admin/Inquiry/PendingInquiries";
import ProcessedInquiries from "../pages/Admin/Inquiry/ProcessedInquiries";
import StudentInquiries from "../pages/Admin/Inquiry/StudentInquiries";
import InquiryDetail from "../pages/Admin/Inquiry/InquiryDetail";

import ReportCards from "../pages/Admin/Academic/ReportCard/ReportCards";
import GenerateReportCard from "../pages/Admin/Academic/ReportCard/GenerateReportCard";
import ReportCardDetail from "../pages/Admin/Academic/ReportCard/ReportCardDetail";
import Formats from "../pages/Admin/Academic/ReportCard/Formats";
import AddFormat from "../pages/Admin/Academic/ReportCard/AddFormat";
import FormatDetail from "../pages/Admin/Academic/ReportCard/FormatDetail";

import Certificates from "../pages/Admin/Academic/Certificate/Certificates";
import UploadCertificate from "../pages/Admin/Academic/Certificate/UploadCertificate";
import GenerateCertificate from "../pages/Admin/Academic/Certificate/GenerateCertificate";

import AddCourse from "../pages/Admin/Academic/Course/AddCourse";
import Courses from "../pages/Admin/Academic/Course/Courses";
import CourseDetail from "../pages/Admin/Academic/Course/CourseDetail";
import CourseTopics from "../pages/Admin/Academic/Course/CourseTopics";
import TopicDetail from "../pages/Admin/Academic/Course/TopicDetail";
import QuestionDetail from "../pages/Admin/Academic/Course/QuestionDetail";
import BatchSchedule from "../pages/Admin/Academic/BatchSchedule";
import Leaderboards from "../pages/Admin/Academic/Leaderboards";

import AssessmentDetail from "../pages/Admin/Academic/Submission/AssessmentDetail";
import SubmissionDetail from "../pages/Admin/Academic/Submission/SubmissionDetail";
import StudentSubmissions from "../pages/Admin/Academic/Submission/StudentSubmissions";
import TopicSubmissions from "../pages/Admin/Academic/Submission/TopicSubmissions";

import Subjects from "../pages/Admin/Subject/Subjects";
import AddSubject from "../pages/Admin/Subject/AddSubject";
import SubjectDetail from "../pages/Admin/Subject/SubjectDetail";

import TimeTables from "../pages/Admin/TimeTable/TimeTables";
import TimeSlots from "../pages/Admin/TimeTable/TimeSlot/TimeSlots";
import AddSlot from "../pages/Admin/TimeTable/TimeSlot/AddSlot";
import SlotDetail from "../pages/Admin/TimeTable/TimeSlot/SlotDetail";
import AddTimeTableOne from "../pages/Admin/TimeTable/AddTimeTableOne";
import AddTimeTableTwo from "../pages/Admin/TimeTable/AddTimeTableTwo";
import UpdateTimeTableOne from "../pages/Admin/TimeTable/UpdateTimeTableOne";
import UpdateTimeTableTwo from "../pages/Admin/TimeTable/UpdateTimeTableTwo";
import DayTimeTable from "../pages/Admin/TimeTable/DayTimeTable";
import TeacherTimeTable from "../pages/Admin/TimeTable/TeacherTimeTable";
import ClassTimeTable from "../pages/Admin/TimeTable/ClassTimeTable";
import Substitutes from "../pages/Admin/TimeTable/Substitute/Substitutes";
import SubHistory from "../pages/Admin/TimeTable/Substitute/SubHistory";

import MyTasks from "../pages/Employee/Task/MyTasks";
import MyTaskDetail from "../pages/Employee/Task/MyTaskDetail";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import UserNotifications from "../pages/User/UserNotifications";
import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";

import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const AcademicTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={AcademicHome} />
          <Route path="/app/classes" exact={true} component={ManageClasses} />
          <Route path="/app/teachers" exact={true} component={ManageTeachers} />
          <Route path="/app/student" exact={true} component={Student} />
          <Route path="/app/timetables" exact={true} component={ManageTimeTables} />
          <Route path="/app/cards" exact={true} component={ManageReportCards} />
          <Route path="/app/certificates" exact={true} component={ManageCertificates} />
          <Route path="/app/schedule" exact={true} component={ManageSchedule} />

          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/teachers/manage" exact={true} component={Teachers} />
          <Route path="/app/teachers/add" exact={true} component={AddTeacher} />
          <Route path="/app/employee/:employeeID" exact={true} component={EmployeeDetail} />
          <Route path="/app/employee/profile/:employeeID" exact={true} component={BasicInfo} />
          <Route path="/app/employee/account/:employeeID" exact={true} component={AccountInfo} />
          <Route path="/app/employee/attendance/:employeeID" exact={true} component={EmployeeAttendance} />
          <Route path="/app/attend/staff/mark/:role?" exact={true} component={StaffAttend} />
          <Route path="/app/attend/staff/view/:role?" exact={true} component={AttendView} />
          <Route path="/app/attend/staff/leave/:role?" exact={true} component={StaffLeaves} />
          <Route path="/app/attend/staff/entryexit/:role?" exact={true} component={StaffEntryExit} />
          <Route path="/app/attendance/teacher/status" exact={true} component={TeacherStatus} />
          <Route path="/app/attendance/teacher/log/:employeeID?" exact={true} component={TeacherLog} />
          <Route path="/app/chat/:chatID" exact={true} component={ChatDetail} />

          <Route path="/app/attend" exact={true} component={Attend} />
          <Route path="/app/attend/status" exact={true} component={AttendStatus} />
          <Route path="/app/attend/class/:classID?" exact={true} component={AttendClass} />
          <Route path="/app/attend/leave" exact={true} component={LeaveApplications} />
          <Route path="/app/entryexit" exact={true} component={EntryExit} />
          <Route path="/app/entry/log/:student?" exact={true} component={StudentLog} />
          <Route path="/app/attend/log" exact={true} component={InstituteLog} />

          <Route path="/app/scan/entry" exact={true} component={ScanEntry} />
          <Route path="/app/scan/exit" exact={true} component={ScanExit} />
          <Route path="/app/scan/present" exact={true} component={ScanPresent} />

          <Route path="/app/classes/manage" exact={true} component={Classes} />
          <Route path="/app/class/add" exact={true} component={AddClass} />
          <Route path="/app/class/promote/:classID?" exact={true} component={PromoteClass} />
          <Route path="/app/class/:classID" exact={true} component={ClassDetail} />
          <Route path="/app/class/idcards/:classID" exact={true} component={ClassIdCards} />
          <Route path="/app/class/subjects/:classID" exact={true} component={ClassSubjects} />
          <Route path="/app/class/timetables/:classID" exact={true} component={ClassTimeTables} />

          <Route path="/app/students/:classID?" exact={true} component={Students} />
          <Route path="/app/student/add" exact={true} component={AddStudent} />
          <Route path="/app/student/withdrawn" exact={true} component={WithdrawnStudents} />
          <Route path="/app/student/graduated" exact={true} component={GraduatedStudents} />
          <Route path="/app/student/:studentID" exact={true} component={StudentDetail} />
          <Route path="/app/student/location/:studentID" exact={true} component={StudentLocation} />
          <Route path="/app/student/attendance/:studentID" exact={true} component={StudentAttendance} />
          <Route path="/app/student/profile/:studentID" exact={true} component={StudentProfile} />
          <Route path="/app/student/parent/:parentID" exact={true} component={ParentProfile} />
          <Route path="/app/student/incidents/:studentID" exact={true} component={StudentIncidents} />
          <Route path="/app/student/guardians/:studentID" exact={true} component={StudentGuardians} />
          
          <Route path="/app/subjects" exact={true} component={Subjects} />
          <Route path="/app/subjects/add" exact={true} component={AddSubject} />
          <Route path="/app/subject/:subjectID" exact={true} component={SubjectDetail} />

          <Route path="/app/reportcard/view" exact={true} component={ReportCards} />
          <Route path="/app/reportcard/generate/:studentID?" exact={true} component={GenerateReportCard} />
          <Route path="/app/reportcard/:reportcardID" exact={true} component={ReportCardDetail} />
          <Route path="/app/reportcard/formats" exact={true} component={Formats} />
          <Route path="/app/reportcard/formats/add" exact={true} component={AddFormat} />
          <Route path="/app/reportcard/format/:formatID" exact={true} component={FormatDetail} />

          <Route path="/app/certificates/view" exact={true} component={Certificates} />
          <Route path="/app/certificate/upload/:studentID?" exact={true} component={UploadCertificate} />
          <Route path="/app/certificate/generate/:studentID?" exact={true} component={GenerateCertificate} />
          
          <Route path="/app/courses/add" exact={true} component={AddCourse} />
          <Route path="/app/courses/view" exact={true} component={Courses} />
          <Route path="/app/course/:courseID" exact={true} component={CourseDetail} />
          <Route path="/app/course/:courseID/topics/:chapterID" exact={true} component={CourseTopics} />
          <Route path="/app/courses/topic/:topicID" exact={true} component={TopicDetail} />
          <Route path="/app/courses/topic/:topicID/:questionID" exact={true} component={QuestionDetail} />
          <Route path="/app/batch/schedule/:classID?" exact={true} component={BatchSchedule} />

          <Route path="/app/submissions/student/:studentID?" exact={true} component={StudentSubmissions} />
          <Route path="/app/submissions/topic/:topicID?" exact={true} component={TopicSubmissions} />
          <Route path="/app/submission/:submissionID" exact={true} component={SubmissionDetail} />
          <Route path="/app/assessment/:evaluationID" exact={true} component={AssessmentDetail} />
          <Route path="/app/leaderboards" exact={true} component={Leaderboards} />

          <Route path="/app/timeslots" exact={true} component={TimeSlots} />
          <Route path="/app/timeslots/add" exact={true} component={AddSlot} />
          <Route path="/app/timeslot/:timeslotID" exact={true} component={SlotDetail} />
          <Route path="/app/timetables" exact={true} component={TimeTables} />
          <Route path="/app/timetables/day" exact={true} component={DayTimeTable} />
          <Route path="/app/timetables/teacher" exact={true} component={TeacherTimeTable} />
          <Route path="/app/timetables/add/class/:classID" exact={true} component={AddTimeTableOne} />
          <Route path="/app/timetables/add" exact={true} component={AddTimeTableOne} />
          <Route path="/app/timetables/add/subjects" exact={true} component={AddTimeTableTwo} />
          <Route path="/app/timetable/:timetableID" exact={true} component={ClassTimeTable} />
          <Route path="/app/timetable/update/:timetableID" exact={true} component={UpdateTimeTableOne} />
          <Route path="/app/timetable/update/:timetableID/subjects" exact={true} component={UpdateTimeTableTwo} />
          <Route path="/app/substitutes" exact={true} component={Substitutes} />
          <Route path="/app/substitutes/history" exact={true} component={SubHistory} />

          <Route path="/app/circulars/:classID?" exact={true} component={Circulars} />
          <Route path="/app/circular/add" exact={true} component={AddCircular} />
          <Route path="/app/circular/:circularID" exact={true} component={CircularDetail} />
          <Route path="/app/inquiries/new" exact={true} component={AddInquiry} />
          <Route path="/app/inquiries/pending" exact={true} component={PendingInquiries} />
          <Route path="/app/inquiries/processed" exact={true} component={ProcessedInquiries} />
          <Route path="/app/inquiries/student/:studentID?" exact={true} component={StudentInquiries} />
          <Route path="/app/inquiry/:inquiryID" exact={true} component={InquiryDetail} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:taskID" exact={true} component={MyTaskDetail} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />

          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to="/app/home" />
          <Redirect from="*" to="/app"/>
        </Switch>
      </IonRouterOutlet>

  );
};

export default AcademicTabs;
