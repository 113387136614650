import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios, { AxiosRequestConfig } from 'axios';
import { alertCircleOutline, attach } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import { Filesystem, Directory } from '@capacitor/filesystem';
import FileOpener from "../../../../components/FileOpener";
import SwitchBranch from '../../../../components/SwitchBranch';

interface Student {
    _id: string,
    name: string,
    certificate: Cert
}
interface ParamTypes {
    classID?: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInfo {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Cert {
    fileName: string,
    title: string,
    uploadedAt: Date
}


const Certificates: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [certificates, setCertificates] = useState < Student[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInfo[] > ([]);
    const [classi, setClassi] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [year, setYear] = useState < string > ("");
    const [session, setSession] = useState < string > ("");

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
        else
        {
            return t("pick_date");
        }
    };

    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        //const blob = new Blob([arrayBuff], { type: mime } );   
        reader.readAsDataURL(blob);
    });

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadCircular = async (fileName : string) => {
        const url = process.env.REACT_APP_STATIC_S3 + '/documents/' + fileName;
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
              }
          };

        const myArray2 = fileName.split(".");
        const mimeType2 = getMimeType(myArray2[1]);

        if (isPlatform('capacitor'))
        {
            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            try {

                                const result = await axios.get(url, config);
                               
                                if (result.data instanceof Blob)
                                {
                                    const base64 = await convertBlobToBase64(result.data) as string;

                                    const savedFile = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64,
                                        directory: Directory.External
                                    });

                                    if(savedFile.uri)
                                    {
                                        FileOpener.open({filePath: savedFile.uri, contentType: mimeType2});
                                    }
                                    else
                                    {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                               
                            }
                            catch(error: any) {
 
                                setMessage(t("download_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        
                        const result = await axios.get(url, config);

                        if (result.data instanceof Blob)
                        {
                            const base64 = await convertBlobToBase64(result.data) as string;

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: base64,
                                directory: Directory.External
                            });

                            if(savedFile.uri)
                            {
                                FileOpener.open({filePath: savedFile.uri, contentType: mimeType2});
                            }
                            else
                            {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                    }
                    catch(error: any) {
                        setMessage(t("download_error"));
                        setIserror(true);
                    }
                }
            }).catch(err => {
                setMessage(t("download_error"));
                setIserror(true);
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
                
            }
        }
    }

    const loadItems = async () => {

        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            
            const result = await api.post(`/students/listCertificates/${authInfo.user.instituteID}?skip=${limit}`, {year, classi, toDOB, fromDOB });
            if(result.data.certificates && result.data.certificates.length)
            {
                setCertificates([...certificates, ...result.data.certificates]);
            }
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSubmit = () => {

        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {

                const result = await api.post(`/students/listCertificates/${authInfo.user.instituteID}`, {year, classi, toDOB, fromDOB});
                setCertificates(result.data.certificates);
                setTotal(result.data.total);
               
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    const handleSession = (sessionID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        const sessionName = sessions.find(val => val._id === sessionID)!.name;
        setYear(sessionName);
        setSession(sessionID);
        setClassi("all");
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (classID)
                {
                    const classInfo = await api.get(`/classes/${classID}`);
                    setYear(classInfo.data.sessionID);
                    const resu = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                    setClasses(resu.data);
                    setClassi(classID);

                    const result = await api.post(`/students/listCertificates/${authInfo.user.instituteID}`, {'classi': classID, 'toDOB': '', 'fromDOB': ''});
                    setCertificates(result.data.certificates);
                    setTotal(result.data.total);
                }
                else
                {
                    setYear("");
                    setClasses([]);
                    setClassi("");
                    setCertificates([]);
                    setTotal(0);
                }
                setSkip(0);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_certificates")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_certificates")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-lg">
                    
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_batch")}
                                                    onIonChange={
                                                        (e) => setClassi(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_from")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("ok")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_to")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
               
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_certificates")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="3" size="8" className='table-heading'>
                                {t("name")}
                            </IonCol>

                            <IonCol sizeLg="5" size="0" className='table-heading ion-hide-lg-down'>
                            {t("title")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="1">
                                <IonIcon 
                                    icon={attach}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                           
                        </IonRow>
                       

                            {(certificates.length > 0) ? (certificates.map((certificate, i) => {
                                return (
                                    <IonRow key={certificate._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                   >
                                        
                                            <IonCol sizeLg="3" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className='text-capitalize'>{certificate.name}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {certificate.certificate.title}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            
                                            <IonCol sizeLg="5" size="0" className='table-field ion-hide-lg-down'>
                                                {certificate.certificate.title}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(certificate.certificate.uploadedAt)}
                                            </IonCol>

                                            <IonCol size="1" >
                                            {certificate.certificate.fileName && isPlatform("capacitor") && (
                                                <IonIcon 
                                                    icon={attach}
                                                    className='table-icon mt-10'
                                                    color="dark"
                                                    onClick={() => downloadCircular(certificate.certificate.fileName)}
                                                />
                                            )}
                                            {certificate.certificate.fileName && !isPlatform("capacitor") && (
                                                <a href={`${process.env.REACT_APP_STATIC_S3}/documents/${certificate.certificate.fileName}`} target="_blank" rel="noreferrer" download> 
                                                    <IonIcon 
                                                        icon={attach}
                                                        className='table-icon mt-10'
                                                    color="dark"
                                                    />
                                                </a>
                                            )}
                                            </IonCol>
                                           
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_certificates")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                            {(certificates.length > 0) &&
                            <IonRow>
                                <IonCol>
                                    <IonInfiniteScroll
                                        onIonInfinite={async (ev) => {
                                            await loadItems();
                                            ev.target.complete();
                                        }}
                                        >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                </IonCol>
                            </IonRow>
                            }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${certificates.length} ${t("of")} ${total}`} 
                                </IonCol>
                             </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/certificate/upload`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("upload_certificate")}
                                    </IonButton>


                                 </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Certificates;