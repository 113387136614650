import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios, { AxiosRequestConfig }  from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import {useAuth} from '../../../components/AuthContext';
import { Anchorme } from 'react-anchorme'
import { Filesystem, Directory } from '@capacitor/filesystem';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import FileOpener from "../../../components/FileOpener";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useTranslation } from 'react-i18next';

interface ParamTypes {
    circularID: string
}

const CircularDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {circularID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < Date > (new Date(Date.now()));
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
      };


    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }
 
    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadCircular = async (fileName : string) => {
        const url = process.env.REACT_APP_STATIC_S3 + '/circulars/' + fileName;
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
              }
          };

          const myArray = fileName.split(".");
          const mimeType = getMimeType(myArray[1]);

        if (isPlatform('capacitor'))
        {

            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            try {
                                const result = await axios.get(url, config);
                                if (result.data instanceof Blob)
                                {
                                    const base64 = await convertBlobToBase64(result.data) as string;

                                    const savedFile = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64,
                                        directory: Directory.External
                                    });

                                    if(savedFile.uri)
                                    {
                                        setShowLoading(false);
                                        FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                                    }
                                    else
                                    {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                               
                            }
                            catch(error: any) {
 
                                setMessage(t("download_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const result = await axios.get(url, config);
                        if (result.data instanceof Blob)
                        {
                            const base64 = await convertBlobToBase64(result.data) as string;

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: base64,
                                directory: Directory.External
                            });

                            if(savedFile.uri)
                            {
                                setShowLoading(false);
                                FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                            }
                            else
                            {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                       
                    }
                    catch(error: any) {

                        setMessage(t("download_error"));  
                        setIserror(true);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
                
            }
        }

    }

    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/circulars/' + circularID);

                setTitle(result.data.title);
                if(result.data.filename)
                {
                    setFileName(result.data.filename);
                    const myArray = result.data.filename.split(".");
                    setFileExt(myArray[1]);
                    setMode("Circular");
                }
                if(result.data.text)
                {
                    setText(result.data.text);
                    setMode("Announcement");
                }
                
                setSelectedDate(result.data.createdAt);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, circularID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

            <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonGrid className="mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">

                                                <IonRow className={(mode === "Circular") ? 'circular-row' : 'announce-row'}>
                                                    <IonCol size="11" offset="1" className='planner-col ion-padding-bottom'>
                                                        <IonBadge className={(mode === "Circular") ? 'circular-badge' : 'announce-badge'}>{mode === "Circular" ? t("circular") : t("announcement")}</IonBadge>
                                                            
                                                            <h3>{formatDate(selectedDate)}<br/>{t("title")}</h3>
                                                            <p>{title}</p>

                                                    </IonCol>
                                                </IonRow>
                                                 {(mode === "Announcement") && (
                                                    <IonRow>
                                                        <IonCol size="11" offset="1" className="ion-padding announcement-card">
                                                        
                                                                        <Anchorme>{text}</Anchorme>
                                                                
                                                        </IonCol>
                                                    </IonRow>
                                                                            
                                                    )}

                                                    {(mode === "Circular") && (fileName !== "") && (
                                                    <>
                                                    <IonRow>
                                                        <IonCol size="11" offset="1" className='ion-padding ion-text-center'>
                                                            
                                                                    {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                                                        (
                                                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`, title)}
                                                                            src={`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`} 
                                                                            alt="Circular Preview" 
                                                                            width="320" />
                                                                    )
                                                                    }
                                                                    {(fileExt) && (fileExt === 'pdf') &&
                                                                        (<div className="pdf-preview">
                                                                            <div className="vertical-container">
                                                                                <div className="vertical-center"> 
                                                                                    <img onClick={() => downloadCircular(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                            
                                                        </IonCol>
                                                    </IonRow>
                                                    </>
                                                    )}
                                        </IonCardContent>
                                    </IonCard>  
                                           
                              
                                </IonCol>
                                
                            </IonRow>


               
                </IonGrid>

            </IonContent>
            {fileName !== "" && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => downloadCircular(fileName)}
                                className="first-button"
                                fill='clear'
                                size="small">{t("download_circular")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
             )}
        </IonPage>
    );
};

export default CircularDetail;
