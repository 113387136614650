import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert,
    IonCard,
    IonCardContent,
    IonLoading,
    IonText,
    IonIcon
} from '@ionic/react';
import { lockClosedOutline, mailOutline } from 'ionicons/icons';
import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useAuth} from "../../components/AuthContext";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
    const {logIn} = useAuth()!;
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const [email, setEmail] = useState < string > ("");
    const [password, setPassword] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);

    const handleLogin = () => {

        if (!email) {
            setMessage(t("login_username"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (!password || password.length < 8) {
            setMessage(t("login_pass"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        setShowLoading(true);

        logIn(email, password).then(res => {
            history.push("/app");
            setShowLoading(false);
        }).catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("unable_to_connect"));
            setIserror(true);
            setShowLoading(false);
        });
    };

    return (
        <IonPage>

            <IonContent fullscreen={true}
                className="ion-text-center ion-padding grey-shade">

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />


                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                    
                <IonGrid className="ion-no-padding">
                   <IonRow>
                        <IonCol offsetXl="4" offsetLg="3" offsetSm="2" offset="0" sizeXl="4" sizeLg="6" sizeSm="8" size="12">

                                <IonRow className="mt-30">
                                    <IonCol className="ion-text-center">
                                        <img alt="Logo" src="assets/images/logo-trans.png" width="160" height="160" onClick={
                                                        () => history.push(`/home`)
                                                    }/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonText className="auth-title">{i18n.language === "hi" ? process.env.REACT_APP_NAME_HI : process.env.REACT_APP_NAME}</IonText>
                                    </IonCol>
                                </IonRow>
                                
                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                    icon={mailOutline}
                                                    color="primary" 
                                                    className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("username")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={email}
                                                        className="auth-input"
                                                        placeholder={t("username_place")}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                        icon={lockClosedOutline}
                                                        color="primary" 
                                                        className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("login_password")}</IonLabel>
                                                    <IonInput type="password"
                                                        value={password}
                                                        placeholder={t("your_password")}
                                                        className="auth-input"
                                                        onIonInput={
                                                            (e) => setPassword(e.detail.value !)
                                                    }
                                                    onKeyUp={(e) => (e.key === 'Enter') && handleLogin()}></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol className='ion-padding'>
                                        <IonButton expand="block" onClick={handleLogin}>{t("login")}</IonButton>
                                        <p>
                                            <Link to="/signup" className="auth-link">  {t("sign_up")}</Link>
                                        </p>

                                        <p>
                                            <Link to="/forgot"  className="auth-link">  {t("forgot_password")}</Link>
                                        </p>
                                    </IonCol>
                                </IonRow>
                               
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Login;
