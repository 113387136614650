import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../components/AuthContext";
import { addCircleOutline, alertCircleOutline, book, chevronForwardOutline, imageOutline, star, starOutline, trashOutline, } from 'ionicons/icons';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import { useParams } from 'react-router-dom';

interface Attach {
    caption: string,
    filename: string
}

interface FileUpload {
    caption: string,
    file: File,
    imageURL: string
}

interface ParamTypes {
    topicID: string
}

const TopicSubmission: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {topicID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [pop, setPop] = useState < boolean > (false);
    const [info, setInfo] = useState < string > ("");
    const [files, setFiles] = useState < Attach[] > ([]);
    const [fileUploads, setFileUploads] = useState < FileUpload[] > ([]);
    const [caption, setCaption] = useState < string > ("");
    const [upload, setUpload] = useState < File > ();
    const [imageURL, setImageURL] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [submissionID, setSubmissionID] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");
    const [rating, setRating] = useState < number > ();
    const [topicName, setTopicName] = useState < string > ("");
    const [studentID, setStudentID] = useState < string > ("");

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    })

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {
                    setUpload(file);
                    const blob = await fileToDataUri(file);
                    setImageURL(blob);
                   
                }
            } 
        }
    }

    const handleAdd = () => {

        if (!caption || caption === "") {
            setMessage(t("caption_mandatory"));
            setIserror(true);
            return;
        }

        if (!upload) {
            setMessage(t("file_mandatory"));
            setIserror(true);
            return;
        }

        if (!imageURL || imageURL === "") {
            setMessage(t("file_mandatory"));
            setIserror(true);
            return;
        }


        let uploads = [...fileUploads];
        uploads.push({caption, 'file': upload, imageURL});
        setFileUploads(uploads);
        setPop(false);

    }

    const deleteImage = (index: number) => {

        let uploads = [...fileUploads];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFileUploads(uploads);

    }

    const handleReport = () => {

        if (!info || info === "") {
            setMessage(t("summary_mandatory"));
            setIserror(true);
            return;
        }
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        //If submissionID !== "" && fileUploads.length === 0 => Just update work
        //If submissionID !== "" && fileUploads.length > 0 => update work & send files
        //If submissionID === "" && fileUploads.length === 0 -> add report with just work
        //If submissionID === "" && fileUploads.length > 0 -> add report with work and files

        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/evaluations/add', {studentID, topicID, topicName, info}).then(res => {
        
                return resolve(res.data.evaluation);
                
              }).catch(err => reject(err));
    
            });
          }

        const addReportAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/evaluations/attach/add', formData).then(res => {
                return resolve(res.data.evaluation);
              }).catch(err => reject(err));
            });
          }

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/evaluations/'+submissionID, { info }).then(res => {
        
                return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
            api.post('/evaluations/attach/update', formData).then(res => {
                return resolve(res.data.evaluation);
            }).catch(err => reject(err));
            });
        }


        setShowLoading(true);

        if (submissionID !== "")
        {
            if (fileUploads.length === 0)
            {
                upReport()
                .then(data => {
                    setPass(t("submission_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                let formData = new FormData();

                formData.append('evaluationID', submissionID);
                formData.append('info', info);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('submission[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                upAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setPass(t("submission_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
        }
        else
        {
            if (fileUploads.length === 0)
            {
                addReport()
                .then(data => {
                    setPass(t("submission_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                let formData = new FormData();

                formData.append('studentID', studentID);
                formData.append('topicID', topicID);
                formData.append('topicName', topicName);
                formData.append('info', info);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('submission[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                addReportAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setPass(t("submission_updated"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const top = await api.get(`/topics/${topicID}`);
                setTopicName(top.data.name);

                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudentID(student.data._id);

                const res = await api.post('/evaluations/getByStudentTopic', {'studentID': student.data._id, topicID});
                if (res.data)
                {
                    setSubmissionID(res.data._id);
                    setInfo(res.data.info);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                }
                
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);  
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, topicID, refreshed]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/planner`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("topic_submission")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("topic_submission")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
   

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="border-radius-10 ion-margin-bottom planner-card-3">
                                <IonCardContent>
                                    <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonIcon
                                                slot="start"
                                                className='tran-icon'
                                                icon={book}
                                                color="tertiary"
                                                />

                                            <IonLabel className='topic-label'>
                                                <h3>{topicName}</h3>
                                            </IonLabel>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(rating !== undefined) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("teacher_rating")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                   
                                        {(rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                     {(response !== undefined) && (response !== "") && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("teacher_response")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("review_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={response} readonly={true}></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                            {t("submission_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("submission_info")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={info} readonly={(rating !== undefined)} onIonInput={
                                                (e) => setInfo(e.detail.value!)
                                        }></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(rating === undefined) && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    onClick={() => setPop(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_file")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover size='cover' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("file_caption")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={caption}
                                                        placeholder={t("file_caption")}
                                                        onIonInput={
                                                            (e) => setCaption(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("select_file")}*</IonLabel>
                                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                                                
                                                </IonItem>
                                               
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleAdd}
                                            expand="block"
                                            color="secondary">{t("add_file")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(files.length > 0) && (files.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                            button={true}
                                            detail={true}
                                            detailIcon={chevronForwardOutline}
                                            onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/reports/${afile.filename}`, afile.caption)}
                                            >
                                                <IonIcon icon={imageOutline}
                                                    slot="start"
                                                    color="tertiary" />
                                                <IonLabel className="action-title-two"><h3>{afile.caption}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(fileUploads.length > 0) && (fileUploads.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                                button={false}
                                            >
                                                <IonIcon icon={imageOutline}
                                                    slot="start"
                                                    color="tertiary"
                                                    onClick={() => openImage(afile.imageURL, afile.caption)} />
                                                <IonLabel className="action-title-two" onClick={() => openImage(afile.imageURL, afile.caption)}><h3>{afile.caption}</h3></IonLabel>
                                                <IonIcon icon={trashOutline}
                                                    slot="end"
                                                    color="danger"
                                                    onClick={() => deleteImage(i)} />
                                                <IonIcon icon={chevronForwardOutline}
                                                    slot="end"
                                                    color="dark"
                                                    onClick={() => openImage(afile.imageURL, afile.caption)} />

                                            </IonItem>
                                            
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(fileUploads.length === 0) && (files.length === 0) && (
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start" 
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_files_uploaded")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            {(rating === undefined) && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton onClick={handleReport}
                                className="first-button"
                                fill="clear"
                                > {t("submit")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
}

export default TopicSubmission;
