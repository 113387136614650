import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, call, chatbubblesOutline, checkboxOutline, informationCircle, logoWhatsapp, mailOutline } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Inquiry {
    _id: string,
    source: string,
    type: string,
    status: string,
    userInfo: User,
    updates: Update[],
    createdAt: Date,
    updatedAt: Date
}

interface User {
    userID: string,
    name: string,
    phone: string,
    email: string
}

interface Update {
    _id: string,
    timestamp: Date,
    userInfo: UserInfo,
    update: UpdateInfo
}

interface UserInfo {
    userID: string,
    userName: string
}

interface UpdateInfo {
    text: string,
    status: string
}

interface AdmnClass {
    masterID: string,
    name: string,
    annualCost: number,
    applicationFees: number,
    isActive: boolean
}

const Inquiries: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [classes, setClasses] = useState < AdmnClass[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [status, setStatus] = useState < string > ("all");
    const [source, setSource] = useState < string > ("all");
    const [type, setType] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [searchText, setSearchText] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    function openURL(value: string) {

        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        return false;
    };
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };


    const handleSubmit = () => {


        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}`, {status, type, source, toDOB, fromDOB, searchText});
                setInquiries(result.data.inquiries);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;
       

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        try {
            const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}?skip=${limit}`, {status, type, source, toDOB, fromDOB, searchText});

            if(result.data.inquiries && result.data.inquiries.length)
            {
                setInquiries([...inquiries, ...result.data.inquiries]); 
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setInquiries(storedState.inquiries || []);
                setClasses(storedState.classes || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
                setStatus(storedState.status || "all");
                setSource(storedState.source || "all");
                setType(storedState.type || "all");
                setToDate(storedState.toDate || undefined);
                setFromDate(storedState.fromDate || undefined);
                setSearchText(storedState.searchText || "");
               
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {

                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                        const res = await api.get(`/institutes/${authInfo.user.instituteID}`);
                        
                        if (res.data.admnClasses)
                            setClasses(res.data.admnClasses);
                        else
                            setClasses([]);

                        setInquiries([]);
                        setTotal(0);
                        setSkip(0);
                        setType("all");

                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const res = await api.get(`/institutes/${authInfo.user.instituteID}`);
                    
                    if (res.data.admnClasses)
                        setClasses(res.data.admnClasses);
                    else
                        setClasses([]);

                    setInquiries([]);
                    setTotal(0);
                    setSkip(0);
                    setType("all");
                    
                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            inquiries,
            classes,
            total,
            skip,
            status,
            source,
            type,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [inquiries, classes, total, skip, status, source, type, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("enquiries_list")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("enquiries_list")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("inquiry_status")}</IonLabel>
                                            <IonSelect value={status}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("inquiry_status")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setStatus(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    <IonSelectOption value="new">{t("new")}</IonSelectOption>
                                                    <IonSelectOption value="unreachable">{t("unreachable")}</IonSelectOption>
                                                    <IonSelectOption value="shared">{t("shared")}</IonSelectOption>
                                                    <IonSelectOption value="followup">{t("followup")}</IonSelectOption>
                                                    <IonSelectOption value="meeting">{t("meeting")}</IonSelectOption>
                                                    <IonSelectOption value="converted">{t("converted")}</IonSelectOption>
                                                    <IonSelectOption value="failed">{t("failed")}</IonSelectOption>
                                                    <IonSelectOption value="disqualified">{t("disqualified")}</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("inquiry_type")}</IonLabel>
                                            <IonSelect value={type}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("inquiry_type")}
                                                    onIonChange={
                                                        (e) => setType(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                    {classes.map((classy, i) => (
                                                       
                                                        (<IonSelectOption key={i} value={classy.name}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("source")}
                                        </IonLabel>
                                        <IonSelect value={source}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("source")}
                                            onIonChange={
                                                (e) => setSource(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="google">{t("google")}</IonSelectOption>
                                            <IonSelectOption value="instagram">{t("instagram")}</IonSelectOption>
                                            <IonSelectOption value="facebook">{t("facebook")}</IonSelectOption>
                                            <IonSelectOption value="whatsapp">{t("whatsapp")}</IonSelectOption>
                                            <IonSelectOption value="youtube">{t("youtube")}</IonSelectOption>
                                            <IonSelectOption value="linkedin">{t("linkedin")}</IonSelectOption>
                                            <IonSelectOption value="justdial">{t("justdial")}</IonSelectOption>
                                            <IonSelectOption value="website">{t("website")}</IonSelectOption>
                                            <IonSelectOption value="walkin">{t("walkin")}</IonSelectOption>
                                            <IonSelectOption value="newspaper">{t("newspaper")}</IonSelectOption>
                                            <IonSelectOption value="magazine">{t("magazine")}</IonSelectOption>
                                            <IonSelectOption value="poster">{t("poster")}</IonSelectOption>
                                            <IonSelectOption value="reference">{t("reference")}</IonSelectOption>
                                            <IonSelectOption value="other">{t("other")}</IonSelectOption>
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("name")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("name")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_enquiries")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="3" size="5" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("admn_inquiry_details")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("type")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("leave_status")}
                            </IonCol>
                            <IonCol sizeLg="2" size="4" className='table-heading'>
                            {t("last_update")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("actions")}
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(inquiry._id)
                                    }
                                    onDoubleClick={() => history.push(`/app/admission/inquiry/${inquiry._id}`)}>
                                        
                                            <IonCol sizeLg="3" size="5" className='table-field' >
                                                <IonRow>
                                                    <IonCol size='2.5'>
                                                        <IonRadio value={inquiry._id} className='table-icon' color="secondary" />
                                                    </IonCol>
                                                    <IonCol size="9.5">
                                                        <IonText className='text-capitalize'>{inquiry.userInfo.name}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {inquiry.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                              
                                            </IonCol>
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            <IonBadge className='ml-05' color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                    {t(inquiry.status)}
                                                </IonBadge>
                                                        
                                            </IonCol>
                                            <IonCol sizeLg="2" size="4" className='table-field'>
                                                <IonText className='ion-hide-lg-up'>  <IonBadge className='ml-05' color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                    {t(inquiry.status)}
                                                </IonBadge> </IonText> 
                                                
                                                {t("on")} {formatDate(inquiry.updatedAt)}
                                                {(inquiry.updates.length > 0) && (<IonText> {t("marked_by")} {inquiry.updates[(inquiry.updates.length - 1)].userInfo.userName}</IonText>)}
                                            </IonCol>
                                            <IonCol size="3" >
                                                <IonIcon 
                                                    icon={informationCircle}
                                                    color="dark"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                    }
                                                />
                       
                                                <IonIcon 
                                                    icon={call}
                                                    color="tertiary"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => document.location.href = `tel:${inquiry.userInfo.phone}`
                                                    }
                                                />
                                                {inquiry.userInfo.email !== "" && (
                                                <IonIcon 
                                                    icon={mailOutline}
                                                    color="secondary"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => document.location.href = `mailto:${inquiry.userInfo.email}`
                                                    }
                                                />
                                                )}
                                                <IonIcon 
                                                    icon={logoWhatsapp}
                                                    color="success"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => openURL(`https://wa.me/${inquiry.userInfo.phone}`)
                                                    }
                                                />

                                                {(inquiry.userInfo.userID.match(/^[0-9a-fA-F]{24}$/)) && (
                                                    <IonIcon 
                                                    icon={chatbubblesOutline}
                                                    color="primary"
                                                    className='table-icon mt-10 ml-05'
                                                    onClick={
                                                        () => history.push(`/app/admission/inquiry/${inquiry._id}/chat`)
                                                    }
                                                />
                                                )}

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_open_enq")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${inquiries.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_enquiry"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/admission/inquiry/${current}`);
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_details")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/admission/inquiries/new`)
                                      
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("new_admn_inq")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/admission/inquiries/board`)
                                      
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_enq_board")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>

                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Inquiries;