import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';


const NewCourse: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File > ();
    const [dur, setDur] = useState < number > (0);
    const [durr, setDurr] = useState < string > ("");
    const [admnFees, setAdmnFees] = useState < number > (0);
    const [name, setName] = useState < string > ("");
    const [cost, setCost] = useState < string > ("");

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
        })

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {
                    setUpload(file);
                    
                     const blob = await fileToDataUri(file);
                     setImageURL(blob);
                    
                }
            } 
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("course_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("course_desc_mandatory"));
            setIserror(true);
            return;
        }

        if (!durr || durr === "" || dur === 0) {
            setMessage(t("course_dur_mandatory"));
            setIserror(true);
            return;
        }

        if (!cost || cost === "") {
            setMessage(t("annual_cost_mandatory"));
            setIserror(true);
            return;
        }
        
        if (!upload) {
            setMessage(t("course_format"));
            setIserror(true);
            return;
        }

        const duration = dur + " " + durr;

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const addCircular = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/admissionCourses/add', formData).then(res => {
                return resolve(res.data.course.name);
              }).catch(err => reject(err));
            });
          }

            
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('courseType', 'course');
            formData.append('name', name);
            formData.append('duration', duration);
            formData.append('admnFees', admnFees.toString());
            formData.append('cost', cost);
            formData.append('text', text);
            formData.append('course', upload, 'course');

            setShowLoading(true);
            addCircular(formData)
                .then(data => {
                    setPass(data+t("has_been_added"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
        
        
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/admission/class`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_course")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_course")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/admission/class`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("course_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("course_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("course_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("course_duration")}*
                                                    </IonLabel>
                                                    <IonRow>
                                                        <IonCol size="6">
                                                            <IonInput type="number"
                                                                className="input-field"
                                                                placeholder={t("course_duration")}
                                                                value={dur}
                                                                onIonInput={
                                                                    (e) => setDur(parseInt(e.detail.value!))
                                                            }></IonInput>
                                                        </IonCol>
                                                        <IonCol size="6">
                                                            <IonSelect value={durr}
                                                                style={{'maxWidth': '100%'}} 
                                                                className="input-field"
                                                                placeholder={t("course_duration")}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                onIonChange={
                                                                    (e) => setDurr(e.detail.value)
                                                                }>
                                                        
                                                                <IonSelectOption value="Day(s)">{t("day_s")}</IonSelectOption>
                                                                <IonSelectOption value="Month(s)">{t("month_s")}</IonSelectOption> 
                                                                <IonSelectOption value="Year(s)">{t("year_s")}</IonSelectOption> 
                                                            </IonSelect>
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                   
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" color="secondary">{t("application_fees")}*</IonLabel>
                                                <IonInput type="number"
                                                    value={admnFees}
                                                    placeholder={t("application_fees")}
                                                    onIonInput={
                                                        (e) => setAdmnFees(parseInt(e.detail.value!))
                                                }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("total_cost")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={cost}
                                                        placeholder={t("total_cost")}
                                                        onIonInput={
                                                            (e) => setCost(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                           <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                <IonLabel position="stacked" color="secondary" className="input-label">{t("course_file")}*</IonLabel>
                                                <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)}></input>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {imageURL && (
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                        
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="320" />
        
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("course_desc")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("course_desc")}*</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                           
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_course")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default NewCourse;
