import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios, { AxiosRequestConfig } from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { Filesystem, Directory } from '@capacitor/filesystem';
import FileOpener from "../../../components/FileOpener";
import { PhotoViewer } from '@capacitor-community/photoviewer';

interface ParamTypes {
    billID: string
}

const BillDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {billID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");


    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
      };

    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadBill = async (fileName : string) => {

        const url = process.env.REACT_APP_STATIC_S3 + '/bills/' + fileName;
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
              }
          };

          const myArray = fileName.split(".");
          const mimeType = getMimeType(myArray[1]);

        if (isPlatform('capacitor'))
        {

            try {
                Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
 
                        }
                        else {
                            try {
                                const result = await axios.get(url, config);
                                if (result.data instanceof Blob)
                                {
                                    const base64 = await convertBlobToBase64(result.data) as string;

                                    const savedFile = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64,
                                        directory: Directory.External
                                    });

                                    if(savedFile.uri)
                                    {
       
                                        FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                                    }
                                    else
                                    {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                            }
                            catch(error: any) {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const result = await axios.get(url, config);
                        if (result.data instanceof Blob)
                        {
                            const base64 = await convertBlobToBase64(result.data) as string;

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: base64,
                                directory: Directory.External
                            });

                            if(savedFile.uri)
                            {

                                FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                            }
                            else
                            {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                    }
                    catch(error: any) {
                        setMessage(t("download_error"));
                        setIserror(true);
                    }
                }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
                
            }
        }
    }

    const handleTags = (value : string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    }

    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage(t("tran_mode_mandatory"));
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage(t("tran_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        if(!tags.length)
        {
            setMessage(t("tags_mandatory"));
            setIserror(true);
            return;
        }

        if(!(typeof(selectedDate) === "string"))
        {
            setMessage(t("tran_date_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  

        const upBill = (mode: string, remarks: string, amount: number, tags: string, dated: string | string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/transactions/'+billID, { mode, remarks, tags, dated, amount }).then(res => {
        
                return resolve(res.data.remarks);
                
              }).catch(err => reject(err));
    
            });
          }
      
          setShowLoading(true);
        upBill(mode, remarks, -amount, JSON.stringify(tags), selectedDate)
          .then(data => {
    
              setPass(data+t("has_been_updated"));
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
          });
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/' + billID);

                setRemarks(result.data.remarks);
                setMode(result.data.mode);
                setAmount(Math.abs(result.data.amount));
                setTags(result.data.tags);

                setFileName(result.data.filename);
                const myArray = result.data.filename.split(".");
                setFileExt(myArray[1]);

                setSelectedDate(result.data.dated);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, billID]);

    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_bill")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_bill")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                       () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonGrid className="ion-no-padding mb-60">
                {fileName && (
                    <>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("bill_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                        (
                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`, remarks)}
                                            src={`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`} 
                                            alt={t("bill_preview")}
                                            width="320" />
                                       )
                                    }
                                    {(fileExt) && (fileExt === 'pdf') &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img onClick={() => downloadBill(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal">
                                {isPlatform("capacitor") && (
                                    <IonButton expand="block" color="dark" onClick={() => downloadBill(fileName)}>{t("download_bill")}</IonButton> 
                                )}
                                {!isPlatform("capacitor") && (
                                    <a href={`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`} target="_blank" rel="noreferrer" download className='font-none'> 
                                        <IonButton expand="block" color="dark">{t("download_bill")}</IonButton> 
                                    </a>
                                )}

                        </IonCol>
                    </IonRow>
                    </>
                )}  
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("bill_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_date")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_remarks")}*
                                            </IonLabel>
                                            <IonInput value={remarks} 
                                                placeholder={t("tran_remarks")}
                                                type="text"
                                                className="input-field" 
                                                onIonInput={ev => setRemarks(ev.detail.value!)}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_mode")}*
                                            </IonLabel>
                                            <IonSelect placeholder={t("tran_mode")}
                                                className='input-field' 
                                                style={{'maxWidth': '100%'}} 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                value={mode}
                                                onIonChange={
                                                    (e) => setMode(e.detail.value!)
                                                }
                                                >
                                                <IonSelectOption value="cash">{t("cash")}</IonSelectOption>
                                                <IonSelectOption value="cheque">{t("cheque")}</IonSelectOption>      
                                                <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_amount")}*
                                            </IonLabel>
                                        <IonInput type="number"
                                            value={amount}
                                            placeholder={t("tran_amount")}
                                            className="input-field" 
                                            onIonInput={
                                                (e) => setAmount(parseInt(e.detail.value!))
                                        }></IonInput>
                                    </IonItem>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_tags")}*
                                            </IonLabel>
                                        <IonInput type="text"
                                            placeholder={t("tran_tags")}
                                            className="input-field" 
                                            value={tags.join(' ')}
                                            onIonInput={
                                                (e) => handleTags(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_bill")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default BillDetail;