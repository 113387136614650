import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import SwitchBranch from '../../../components/SwitchBranch';

interface ClassInterface {
    _id: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    dob: Date,
    isChecked?: boolean
}

const AddInquiry: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File > ();
    const [type, setType] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [fromSession, setFromSession] = useState < string > ("");
    const [fromClasses, setFromClasses] = useState < ClassInterface[] > ([]);
    const [fromClass, setFromClass] = useState < string > ("");
    const [fromStudents, setFromStudents] = useState < Student[] > ([]);

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
        })

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {
                    setUpload(file);
                    if (file.type !== 'application/pdf')
                    {
                        const blob = await fileToDataUri(file);
                        setImageURL(blob);
                    }
                }
            } 
        }
    }

    const handleFromSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setFromClasses(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setFromSession(sessionName);
        setFromClass("");
        setFromStudents([]);
    }

    const handleFromClass = (classID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/nameByClass/${classID}`);
                setFromStudents(studs.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setFromClass(classID);
    }

    const handleChecked = (id : number) => {

            let studs = [...fromStudents];

            studs[id].isChecked = !studs[id].isChecked;

            setFromStudents(studs);
        
    }

    const handleAll = () => {

        let studs = [...fromStudents];

        for (let i=0; i < studs.length; i++)
        {
            studs[i].isChecked = true;
        }
        setFromStudents(studs);
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("inq_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("select_inq_type"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("inq_text_mandatory"));
            setIserror(true);
            return;
        }

        let students: Array < string > = [];
        for (let i = 0; i < fromStudents.length ; i++) {
            if (fromStudents[i].isChecked)
            students.push(fromStudents[i]._id);
        }

        if(!students.length)
        {
            setMessage(t("pick_students"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const addInq = () => {
            return new Promise((resolve, reject) => {
              api.post('/inquiries/admin/add', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, 'classID': fromClass, students, title, type, text }).then(res => {
        
                return resolve(res.data.inquiry.title);
                
              }).catch(err => reject(err));
    
            });
          }
  
          const addAttach = (formData: FormData) => {
              return new Promise((resolve, reject) => {
                api.post('/inquiries/admin/attach/add', formData).then(res => {
                  return resolve(res.data.inquiry.title);
                }).catch(err => reject(err));
              });
            }
  
          if (upload) {
              
              let formData = new FormData();

              formData.append('instituteID', authInfo.user.instituteID);
              formData.append('adminID', authInfo.user._id);
              formData.append('classID', fromClass);
              formData.append('students', JSON.stringify(students));
              formData.append('title', title);
              formData.append('type', type);
              formData.append('text', text);
              formData.append('attachment', upload, 'attachment');
  
              setShowLoading(true);
              addAttach(formData)
                  .then(data => {
                      setPass(data+t("has_been_issued"));
                      setIspass(true);
                      setShowLoading(false);
                  })
                  .catch(error => {
                      if (error.response !== undefined)
                          setMessage(error.response.data.message);
                      else
                          setMessage(t("something_went_wrong"));
          
                      setIserror(true);
                      setShowLoading(false);
                  });
              }
              else
              {
                  setShowLoading(true);
                  addInq()
                  .then(data => {
                  
                      setPass(data + t("has_been_issued"));
                      setIspass(true);
                      setShowLoading(false);
                  })
                  .catch(error => {
                      if (error.response !== undefined)
                          setMessage(error.response.data.message);
                      else
                          setMessage(t("something_went_wrong"));
              
                      setIserror(true);
                      setShowLoading(false);
                  });
              }
  
        }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setFromClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    setFromSession("");
                    setFromClasses([]);
                    setFromClass("");
                    setFromStudents([]);
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("make_inquiry")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("make_inquiry")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/communication`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_students")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                                       <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={fromSession}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleFromSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    )}
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={fromClass}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleFromClass(e.detail.value)
                                                                }>
                                                                    
                                                                    {fromClasses.map((classy) => (
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol className='ion-padding-horizontal'>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("students")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <div className="ion-table">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="7" className='table-heading'>
                                            <IonIcon 
                                                icon={checkboxOutline}
                                                color="light"
                                                className='table-icon'
                                                onClick={handleAll}
                                            />
                                            <IonText className='ion-margin-start'>{t("name")}</IonText>
                                        </IonCol>
                                        <IonCol size="4" className='table-heading'>
                                        {t("dob")}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="light"
                                                className='table-icon'
                                                />
                                        </IonCol>
                                    </IonRow>
                                {(fromStudents.length > 0) ? (fromStudents.map((stud, i) => {
                                return (
                                    <IonRow key={stud._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="7" className='table-field text-capitalize' >
                                            <IonCheckbox labelPlacement="end" checked={stud.isChecked} 
                                                onClick={(e) => handleChecked(i) } 
                                            >
                                                    {stud.name}
                                            </IonCheckbox>
                                        
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {formatDated(stud.dob)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${stud._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                
                                    </IonRow>
                                    )})) : (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonCard>
                                <IonCardHeader>
                                        <IonCardSubtitle className='info-subtitle'>{t("chat_info")}</IonCardSubtitle>
                                    </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonList className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("select_type")}</IonLabel>
                                                        <IonSelect value={type}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                            placeholder={t("select_type")}
                                                            onIonChange={
                                                                (e) => setType(e.detail.value)
                                                        }>
                                                                
                                                            <IonSelectOption value="general">{t("general")}</IonSelectOption>  
                                                            <IonSelectOption value="classwork">{t("classwork")}</IonSelectOption>
                                                            <IonSelectOption value="homework">{t("homework")}</IonSelectOption>
                                                            <IonSelectOption value="query">{t("query")}</IonSelectOption>  
                                                            <IonSelectOption value="feedback">{t("feedback")}</IonSelectOption>  
                                                        </IonSelect>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={title}
                                                            onIonInput={
                                                                (e) => setTitle(e.detail.value!)
                                                        }></IonInput>
                                                </IonItem>
                                                
                                                <IonItem detail={false}
                                                            lines='full'
                                                            button={false}>
                                                            <IonLabel position="floating" color="secondary">{t("write_to_us")}</IonLabel>
                                                            <IonTextarea rows={4} value={text} onIonInput={
                                                                    (e) => setText(e.detail.value!)
                                                            }></IonTextarea>
                                                        
                                                        </IonItem>

                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonLabel position="stacked" color="secondary">{t("attachement")}</IonLabel>
                                                        <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={ev => onFileChange(ev)}></input>
                                                    </IonItem>


                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                    {imageURL && (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                
                                                            <img src={imageURL} 
                                                                alt={t("image_preview")}
                                                                width="320" />
                
                                                </IonCardContent>        
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("open_new_chat")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddInquiry;
