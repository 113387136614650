import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { arrowBackOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { PhotoViewer } from '@capacitor-community/photoviewer';

interface AdmnField {
    name: string,
    category: string,
    isMandatory: boolean,
    isActive: boolean,
    order: number
}

interface FormProps {
    onSubmit: (data: any) => void,
    onPrevious: (data: any) => void,
    formData?: any,
    allFields: AdmnField[],
    studentID: string
  }

const CommonFormFour: React.FC<FormProps> = ({ onSubmit, allFields, onPrevious, formData, studentID }) => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [formDataOne, setFormDataOne] = useState < any > ({});
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [theProp, setTheProp] = useState < string > ("");

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  

                if (file && file.name && file.type) {

                    setFormDataOne({
                        ...formDataOne, // Copy the old fields
                        [theProp]: file // But override this one
                    });
                    
                    setPopoverOpen(false);
                }
            } 
        }
    }

    const pickFiles = async (property: string) => {

        setTheProp(property);
        setPopoverOpen(true);

    };

    const getURL = (blob: File) => {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        return imageUrl;
    }

    const handleSubmit = () => {

        for (let i = 0; i < admnFields.length ; i++) {
            if (admnFields[i].isActive && admnFields[i].isMandatory) {

                if (formDataOne[admnFields[i].name] === "") {
                    setMessage(`${admnFields[i].name} ${t("is_mandatory")}`);
                    setIserror(true);
                    return;
                }

            }
        }
        onSubmit(formDataOne);
        
      }

      const handlePrevious = () => {
        onPrevious(formDataOne);
      }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const admnUser = await api.get(`/admissionUsers/${authInfo.user._id}`);

                let parentFields: AdmnField[] = [];
                let parentData: any = {};

                for (let i = 0; i < allFields.length ; i++) {

                    if (allFields[i].category === "ChildDocument") {
                        parentFields.push(allFields[i]);

                        if(formData[allFields[i].name] !== undefined)
                        {
                            parentData[allFields[i].name] = formData[allFields[i].name];
                        }
                        else
                        {
                            if (admnUser.data.children.find((val: any) => val._id === studentID)!.childFields !== undefined && admnUser.data.children.find((val: any) => val._id === studentID)!.childFields.find((val: any) => val.name === allFields[i].name) !== undefined)
                            {
                                parentData[allFields[i].name] = admnUser.data.children.find((val: any) => val._id === studentID)!.childFields.find((val: any) => val.name === allFields[i].name).value;
                            }
                            else
                            {
                                parentData[allFields[i].name] = "";
                            }
                        }
                    }


                    if (allFields[i].category === "Document") {
                        parentFields.push(allFields[i]);

                        if(formData[allFields[i].name] !== undefined)
                        {
                            parentData[allFields[i].name] = formData[allFields[i].name];
                        }
                        else
                        {
                            if (admnUser.data.formFields !== undefined && admnUser.data.formFields.find((val: any) => val.name === allFields[i].name) !== undefined)
                            {
                                parentData[allFields[i].name] = admnUser.data.formFields.find((val: any) => val.name === allFields[i].name).value;
                            }
                            else
                            {
                                parentData[allFields[i].name] = "";
                            }
                        }
                    }


                }


                setAdmnFields(parentFields);
                setFormDataOne(parentData);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [allFields, authInfo, formData, studentID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonButton color="secondary" onClick={handlePrevious}>
                            <IonIcon icon={arrowBackOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("upload_documents")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("upload_documents")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                

                <IonGrid className="ion-no-padding">

                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(admnFields.length > 0) && admnFields.map((admnField, i) => (
                                             (admnField.isActive) &&
                                             (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>

                                                    <IonLabel position="stacked" color="secondary">{admnField.name}{admnField.isMandatory && "*"}</IonLabel>
                                                    <IonText className='ion-padding-vertical'>
                                                        <IonButton onClick={() => pickFiles(admnField.name)}>{formDataOne[admnField.name] !== "" ? t("change_image") : t("pick_image")}</IonButton>
                                                        <IonText className='file-text' onClick={() => {
                                                            if (formDataOne[admnField.name] !== "" && typeof(formDataOne[admnField.name]) === "string")
                                                                openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${formDataOne[admnField.name]}`, admnField.name)
                                                            if (formDataOne[admnField.name] instanceof File)
                                                                openImage(getURL(formDataOne[admnField.name]), admnField.name)
                                                        }}>{formDataOne[admnField.name] !== "" ? t("view_image") : t("no_file_chosen")}</IonText>
                                                    </IonText>
                                                </IonItem>
                                             )
                                        ))}
                                          <IonPopover isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)} side="top">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonCard>
                                                            <IonCardContent className="ion-no-padding">
                                                                <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}
                                                                    >
                                                                    
                                                                    <IonLabel position="stacked" color="secondary">{t("select_image")}*</IonLabel>
                                                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                                                                
                                                                </IonItem>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    </IonCol>
                                                </IonRow>
                                            
                                        </IonPopover>
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={handlePrevious}
                                className="first-button"
                                fill="clear"
                                >&lt; {t("previous")}</IonButton>

                        </IonCol>
                        <IonCol className="ion-text-right">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("next")} &gt;</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CommonFormFour;
