import {
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { calendar, cash, chatboxEllipses, chatbubbles, chevronForward, create, documentText, notifications, time } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { SendIntent } from "send-intent";
import {useAuth} from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import CircularsSwiper from '../../components/ui/CircularsSwiper';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Event {
    _id: string,
    childInfo: Child,
    classInfo: ClassInfo,
    appointmentInfo: Appoint,
    amount: number,
    paidAt: Date
}

interface Inquiry {
    _id: string,
    status: string,
    type: string,
    userInfo: User,
    updatedAt: Date
}

interface User {
    userID: string,
    name: string,
    phone: string
}

interface Child {
    childID: string,
    childName: string
}
interface ClassInfo{
    classID: string,
    className: string
}

interface Appoint {
    dated: Date,
    info: string
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

const AdmissionHeadHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [applications, setApplications] = useState < number > (0);
    const [fees, setFees] = useState < number > (0);
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [events, setEvents] = useState < Event[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [isSuspended, setIsSuspended] = useState < boolean > (false);
    const [attendance, setAttendance]= useState < Attendance > ();
    const [unread, setUnread] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };
   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upUser = (push: Token) => {
            return new Promise((resolve, reject) => {
              api.put('/users/'+authInfo.user._id, { 'push': push.value }).then(res => {
        
                return resolve(res.data);
                
              }).catch(err => reject(err));
    
            });
          }

        const register = () => {
            
            // If new device, this will run everytime
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
    
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: Token) => {
                    if(!authInfo.user.push)
                    {
                        upUser(token);
                    }
                    else if (authInfo.user.push !== token.value)
                    {
                        upUser(token);
                    }
                  
                }
            );
    
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    setMessage(t("push_error"));
                    setIserror(true);
                }
            );
    
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {

                }
            );
    
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    
                }
            );
        }

        const fetchData = async () => {
            setShowLoading(true);
            try {

                if (authInfo.institute.admission)
                {
                    const eve = await api.get(`/applications/getSomeUpcoming/${authInfo.user.instituteID}`);
                    setEvents(eve.data);
    
                    const inq = await api.get(`/admissionInquiries/getSomePendingInstitute/${authInfo.user.instituteID}`);
                    setInquiries(inq.data);
    
                    const result = await api.get(`/institutes/admission/${authInfo.user.instituteID}`);
                    setApplications(result.data.applications);
                    setFees(result.data.payments);
    
                    const circ = await api.get(`/circulars/getSomeByRole/${authInfo.user.instituteID}/${authInfo.user.role}`);
                    setCirculars(circ.data);

                    const chat = await api.get(`/staffChats/getOneByEmployee/${authInfo.user._id}`);
                    setChats(chat.data);

                    const task = await api.get(`/staffChats/getHomeByEmployee/${authInfo.user._id}`);
                    setTasks(task.data);

                    const entry = await api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`);
                    setAttendance(entry.data);

                    const count = await api.get(`/notifications/getCountUser/${authInfo.user._id}`);
                    setUnread(count.data);

                }
                else
                {
                    setIsSuspended(true);
                }


                setShowLoading(false);

            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
                setShowLoading(false);

            }
            
        };

        const checkIntent = () => {
           
            SendIntent.checkSendIntentReceived().then((result : any) => {
                if (result) {
                        
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                    
                }
                else
                {
                    fetchData();
                }
            })
            .catch((err: any) => {
                fetchData();
            });

        }

     
        const fetchVersion = async () => {

            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                else
                {
                    if (isPlatform('android') && isPlatform('capacitor'))
                    {
                        checkIntent();
                    }
                    else
                    {
                        fetchData();
                    }
                
                    if (isPlatform('capacitor'))
                    {
                        PushNotifications.checkPermissions().then((res) => {
                            if (res.receive !== 'granted') {
                            PushNotifications.requestPermissions().then((ress) => {
                                if (ress.receive === 'denied') {
                                    setMessage(t("push_denied"));
                                    setIserror(true);
                                }
                                else if (ress.receive === 'granted') {
                                    register();
                                }
                            });
                            }
                            else {
                                register();
                            }
                        });
                    }
                }


            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
                    

            }
            
        };

        fetchVersion();   

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isSuspended}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("suspended")}
                    message={t("account_suspended")}
                    buttons={[`${t("logout")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>

                {(isPlatform("capacitor")) && (
                    <IonRow className='mt-30'>
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/setup`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("admn_setup")} src="assets/images/school.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("admn_setup")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/crm`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("admn_inquiries")} src="assets/images/inquiries.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("admn_inquiries")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/applications`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("applications_appointments")} src="assets/images/admn.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("applications_appointments")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}

                {(attendance) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("attendance_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    (attendance.status === 'in') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attendance.status === 'in') ? 'success' : 'danger'
                                                    }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{t("your_out_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{t("your_in_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {(attendance.status === 'in') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={() => history.push('/app/user/attendance')}>{t("mark_exit")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                </>
                )}
               
               {circulars && (circulars.length > 0) && 
                (<CircularsSwiper circulars={circulars} />)}
                
                    <IonRow className="ion-padding-vertical max-lg">

                        <IonCol size="12" sizeMd='6' className='ion-padding-horizontal mt-30'>
                            <IonCard className="stat-card-7">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {applications}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                            {t("total")} {t("applications")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                                <IonIcon 
                                                                    icon={documentText}
                                                                    className='reports-icon'
                                                                />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeMd='6' className='ion-padding-horizontal mt-30'>
                            <IonCard className="stat-card-6">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {fees}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                            {t("fees_collected")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                            <IonIcon 
                                                                icon={cash}
                                                                className='reports-icon'
                                                            />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                   
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>

                    {(tasks.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_tasks")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/user/tasks`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(tasks.map((chat, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={chat._id}
                                                            onClick={
                                                                () => history.push(`/app/user/task/${chat._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={create}
                                                                color='danger'/>
                                                            <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                <h3>{chat.title}
                                                                <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                    {t(chat.taskInfo!.priority)}
                                                                </IonBadge>
                                                                <br/> 
                                                                {t(chat.status)} <br/>
                                                                {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                </h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                
                                    
                                </IonCardContent>
                            </IonCard>
                        )}

                    {(chats.length > 0) && (
                        <IonCard className='ion-margin'>
                            <IonCardHeader>
                                <IonRow>
                                    <IonCol size="8">
                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("my_chats")}</IonCardSubtitle>
                                    </IonCol>
                                    <IonCol size="4" className='ion-text-right'>
                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/user/chats`)}>{t("view_all")}</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>

                                        <IonList className='ion-no-padding'>
                                            {(chats.map((chat, i) => {
                                                    return (
                                                        <IonItem detail={true}
                                                        detailIcon={chevronForward}
                                                        lines='full'
                                                        button={true}
                                                        key={chat._id}
                                                        onClick={
                                                            () => history.push(`/app/user/chat/${chat._id}`)
                                                        }>
                                                        <IonIcon slot="start"
                                                            icon={chatbubbles}
                                                            color='secondary'/>
                                                        <IonLabel className="list-label text-capitalize">
                                                            <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                        </IonLabel>
                                                    </IonItem>
                                                    )
                                                }))
                                                } 
                                        </IonList>
                                    </IonCol>
                                </IonRow>     
                            
                                
                            </IonCardContent>
                        </IonCard>
                    )}

                    {(inquiries.length > 0) && (
                            <IonCard className="ion-margin">
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("pending_admn_inq")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/admission/inquiries/board`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(inquiries.map((inquiry, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={inquiry._id}
                                                            onClick={
                                                                () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={chatboxEllipses}
                                                                color='primary'/>
                                                            <IonLabel className="list-label text-capitalize">
                                                            <IonBadge color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                                ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                            {t(inquiry.status)}
                                                            </IonBadge>
                                                            <h3>{inquiry.userInfo.name} ({inquiry.type})<br/>{formatDate(inquiry.updatedAt)}</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                  
                                    
                                </IonCardContent>
                            </IonCard>
                    )}

                    {(events.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_appoint")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="tertiary" size="small" className='pr-16' onClick={() => history.push(`/app/admission/appointments`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>

                                {events.map((event) => {
                                return (
                                    <IonCard className="planner-card-3" key={event._id}>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonItem lines="none"
                                                        button={false}
                                                        detail={false}
                                                        >
                                                            <IonIcon icon={calendar}
                                                                slot="start"
                                                                color="tertiary" />
                                                            <IonLabel className="action-title-one">
                                                                <h3>{event.childInfo.childName} - ({event.classInfo.className})</h3>
                                                                <p>{formatDate(event.appointmentInfo.dated)}</p>
                                                                </IonLabel>
                                                        
                                                        </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonText>{event.appointmentInfo.info}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                        );
                                    })
                                }
                                        </IonCol>
                                    </IonRow>          
                                    
                                </IonCardContent>
                            </IonCard>
                    )}
                                </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AdmissionHeadHome;