import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, book, chatbubbleEllipses, documentText, lockClosed, sparkles } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useHistory } from 'react-router-dom';
import SwitchStudent from '../../../components/SwitchStudent';

interface DaySchedule {
    timeSlot: Slot,
    subject: Subject,
    teacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface ClassInfo {
    classID: string,
    className: string
}

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    mon: DaySchedule[],
    tue: DaySchedule[],
    wed: DaySchedule[],
    thu: DaySchedule[],
    fri: DaySchedule[],
    sat: DaySchedule[]
}

interface Segment {
    day: string,
    date: Date
}

interface Student {
    _id: string,
    classID: string,
    name: string
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface Batch {
    _id: string,
    name: string,
    courseID?: string,
    topics: string[],
    isActive: boolean
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}

interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number,
    questions?: Question[],
}
interface Question {
    _id: string,
    title: string,
    isActive: boolean,
    order?: number
}

interface ResponseInterface {
    data: Batch
}

const Planner: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [timetable, setTimeTable] = useState < TimeTable > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [day, setDay] = useState < string > ("");
    const [segments, setSegments] = useState <Segment[]> ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [events, setEvents] = useState < Event[] > ([]);

    const [batch, setBatch] = useState < Batch > ();
    const [month, setMonth] = useState < string > ("");
    const [segmentsB, setSegmentsB] = useState < Chapter[] > ([]);
    const [topics, setTopics] = useState < Topic[] > ([]);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleDay = (selectedDay: string) => {
        setDay(selectedDay);

        const d = segments.find(val => val.day === selectedDay)!.date;
        const day = {year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()};

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const eve = await api.post(`/feeds/getByDay`, {instituteID: authInfo.user.instituteID, day});
                setEvents(eve.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    const handleMonth = (chapter : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        
        fetchUp();
        setMonth(chapter);
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                if (authInfo.institute.timetable)
                {
                    const result = await api.get(`/timeTables/getCurrentByClass/${student.data.classID}`);

                    setTimeTable(result.data);

                    const weekday = ["sun","mon","tue","wed","thu","fri","sat"];

                    const d = new Date(Date.now());

                    if (d.getDay() === 0)
                    {
                        d.setDate(d.getDate() + 1);
                    }
                    setDay(weekday[d.getDay()]);

                    const day = {year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()};
                    const eve = await api.post(`/feeds/getByDay`, {instituteID: authInfo.user.instituteID, day});
                    setEvents(eve.data);

                    let segs: Segment[] = [];
                    for (let i=0; i<6; i++)
                    {
                        let theday = weekday[d.getDay()];
                        if(theday === 'sun')
                        {
                            d.setDate(d.getDate() + 1);
                            theday = weekday[d.getDay()];
                        }
                        segs.push({'day': theday, 'date': new Date(d)});
                        d.setDate(d.getDate() + 1);
                    }
                    setSegments(segs);
                }
                else
                {
                    const classInfo: ResponseInterface = await api.get(`/classes/${student.data.classID}`);

                    setBatch(classInfo.data);

                    if(classInfo.data.courseID)
                    {
                        const course = await api.get('/courses/' + classInfo.data.courseID);
                        //sort chapters based on order
                        const resu: Chapter[] = course.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);

                        setSegmentsB(resu);

                        setMonth(resu[0]._id);

                        const tops = await api.get(`/topics/getByChapter/${resu[0]._id}`);
                        setTopics(tops.data);
                    
                    }
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_time_table")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_time_table")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding">

                {authInfo.institute.timetable && (
                    <>
                    <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={day} onIonChange={(e) => handleDay(e.detail.value!.toString())} className="planner-segment">

                            {segments && segments.map((seg, i) => (
                                <IonSegmentButton key={i} value={seg.day} className='planner-button'>
                                    <IonLabel>{t(seg.day)}<br/>{seg.date.getDate().toString()}</IonLabel>
                                </IonSegmentButton>
                            ))}
                               
                
                            </IonSegment>
                        </IonCol>
                    </IonRow>
                    {events && events.map((event) => {
                    return (
                        <IonRow key={event._id} className="ion-padding">
                            <IonCol>
                                <IonCard className="planner-card-2">
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>            
                                                <IonItem lines="none"
                                                    button={false}
                                                    detail={false}
                                                    >
                                                        <IonIcon icon={sparkles}
                                                            slot="start"
                                                            color="primary" />
                                                        <IonLabel className="action-title-one">
                                                            <h3>{event.title}</h3>
                                                            <p>{formatDate(event.calendarDate)}</p>
                                                            </IonLabel>
                                                    
                                                    </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>            
                                                <IonText>{event.description}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        
                            );
                        })
                    }
                  
                    {(day === 'mon') && (timetable && timetable.mon.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.mon.map((entry, i) => (
                                    <IonRow key={i}>
                                        <IonCol size="2" className='planner-timeline'>
                                            <h1>{entry.timeSlot.startTime}</h1>
                                            <h2>{entry.timeSlot.endTime}</h2>
                                        </IonCol>
                                        <IonCol size="10">
                                            <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                   
                                                        <IonRow>
                                                            <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                           
                                                                <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                            </IonCol>
                                                        </IonRow>

                                                    </IonCardContent>
                                                </IonCard>
                                        </IonCol>
                                    </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'mon') && (!timetable || (timetable && timetable.mon.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'tue') && (timetable && timetable.tue.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.tue.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                   
                                                    <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'tue') && (!timetable || (timetable && timetable.tue.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'wed') && (timetable && timetable.wed.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.wed.map((entry, i) => (
                                 <IonRow key={i}>
                                 <IonCol size="2" className='planner-timeline'>
                                     <h1>{entry.timeSlot.startTime}</h1>
                                     <h2>{entry.timeSlot.endTime}</h2>
                                 </IonCol>
                                 <IonCol size="10">
                                     <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                         <IonCardContent className="ion-no-padding ion-no-margin">
                                            
                                                 <IonRow>
                                                     <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                    
                                                     <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                     </IonCol>
                                                 </IonRow>

                                             </IonCardContent>
                                         </IonCard>
                                 </IonCol>
                             </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'wed') && (!timetable || (timetable && timetable.wed.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'thu') && (timetable && timetable.thu.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.thu.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                   
                                                    <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'thu') && (!timetable || (timetable && timetable.thu.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'fri') && (timetable && timetable.fri.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.fri.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                   
                                                    <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'fri') && (!timetable || (timetable && timetable.fri.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'sat') && (timetable && timetable.sat.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.sat.map((entry, i) => (
                               <IonRow key={i}>
                               <IonCol size="2" className='planner-timeline'>
                                   <h1>{entry.timeSlot.startTime}</h1>
                                   <h2>{entry.timeSlot.endTime}</h2>
                               </IonCol>
                               <IonCol size="10">
                                   <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                       <IonCardContent className="ion-no-padding ion-no-margin">
                                          
                                               <IonRow>
                                                   <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                  
                                                   <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>{t("teacher")}</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                   </IonCol>
                                               </IonRow>

                                           </IonCardContent>
                                       </IonCard>
                               </IonCol>
                           </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'sat') && (!timetable || (timetable && timetable.sat.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                </>)}
                {!authInfo.institute.timetable && (
                    <>
                     <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={month} onIonChange={(e) => handleMonth(e.detail.value!.toString())} className="planner-segment">

                            {segmentsB && segmentsB.map((seg, i) => seg.isActive && (
                                <IonSegmentButton key={i} value={seg._id} className='planner-button'>
                                    <IonLabel>{seg.chapterName}</IonLabel>
                                </IonSegmentButton>
                            ))}
                               
                
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                        {topics.length > 0 ? (
                                            
                            <IonCard className='border-radius-10 ion-margin-bottom planner-card-3'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonList className='ion-no-margin ion-no-padding'>
                                    {topics.map((topic) => 
                                        (topic.isActive) &&
                                        ( <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={topic._id}
                                                        className="topic-item"
                                                        
                                                        >

                                                        <IonIcon
                                                            slot="start"
                                                            className='tran-icon'
                                                            icon={book}
                                                            color="tertiary"
                                                            />

                                                        <IonLabel className='topic-label ion-text-wrap'>
                                                            <h3>{topic.name}</h3>
                                                        </IonLabel>
                                                        {batch!.topics.includes(topic._id) && (
                                                        <IonButtons slot="end">
                                                            <IonButton onClick={() => history.push(`/app/newinquiry/${topic.name}`)}>
                                                                <IonIcon icon={chatbubbleEllipses} color="danger" />
                                                            </IonButton>
                                                            <IonButton onClick={() => (topic.questions && topic.questions.length > 0) ? history.push(`/app/assessments/topic/${topic._id}`) : history.push(`/app/submissions/topic/${topic._id}`)}>
                                                                <IonIcon icon={documentText} color="primary" />
                                                            </IonButton>
                                                        </IonButtons>
                                                        )}
                                                        {!(batch!.topics.includes(topic._id)) && (
                                                            <IonIcon
                                                                slot="end"
                                                                className='tran-icon'
                                                                icon={lockClosed}
                                                                color="danger"
                                                            />
                                                        )}
                                            </IonItem> )
                                       )}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                                
                            ) : (
                            <IonCard className='red-card ion-margin'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_schedule")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )
                        }

                        </IonCol>
                    </IonRow>

                    </>)}

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Planner;
