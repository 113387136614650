import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface ParamTypes {
    itemID: string
}

interface Store {
    _id: string,
    name: string,
    isActive: boolean
}

const ItemDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {itemID} = useParams < ParamTypes > ();
    const [stores, setStores] = useState < Store[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [price, setPrice] = useState < number > (0);
    const [buyPrice, setBuyPrice] = useState < number > (0);
    const [storeID, setStoreID] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [stock, setStock] = useState < boolean > (false);

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    })

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {

                    const api = axios.create({
                        baseURL: process.env.REACT_APP_API_URL,
                        timeout: parseInt(process.env.REACT_APP_API_TO!),
                        headers: {
                            'Authorization': 'Bearer ' + authInfo.token
                          }
                      });

                    const upImg = ( formData: FormData) => {
                        return new Promise((resolve, reject) => {
                          api.post('/items/updatePhoto', formData).then(res => {
                            return resolve(res.data.item.name);
                          }).catch(err => reject(err));
                
                        });
                      }
            
                    let formData = new FormData();
                    formData.append('item', file, 'item');
                    formData.append('itemID', itemID);
              
                    setShowLoading(true);
                    upImg(formData)
                    .then(async (data) => {
                        
                        const blob = await fileToDataUri(file);
                        setImageURL(blob);   
                        setShowLoading(false);
                    })
                    .catch(error => {
                        if (error.response !== undefined)
                            setMessage(error.response.data.message);
                        else
                            setMessage(t("something_went_wrong"));
            
                        setIserror(true);
                        setShowLoading(false);
                    });
                    
                    
                }
            } 
        }
    }
  
    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const toggleItem = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/items/${itemID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

      if ((active !== "") && (section !== active))
      {
        setShowLoading(true);

        toggleItem(section)
        .then(data => {
            let msg: string = "";
          if (section === 'enable')
          {
              setActive("enable");
              msg = data+t("now_active");
          }
              
          if (section === 'disable')
          {
              setActive("disable");
              msg = data+t("now_inactive");
          }
              
  
          setPass(msg);
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
      }

    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!storeID || storeID === "") {
            setMessage(t("store_mandatory"));
            setIserror(true);
            return;
        }

        if (price < 1) {
            setMessage(t("price_mandatory"));
            setIserror(true);
            return;
        }
        if (stock)
        {
            if (buyPrice < 1) {
                setMessage(t("price_mandatory"));
                setIserror(true);
                return;
            }
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const storeName = stores.find((val) => val._id === storeID)!.name;
  
        const upItem = () => {
          return new Promise((resolve, reject) => {
            api.put('/items/'+itemID, { name, price, buyPrice, storeID, storeName, stock }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    

        setShowLoading(true);
        upItem()
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get('/items/' + itemID);
                setName(res.data.name);
                setPrice(res.data.price);
                setBuyPrice(res.data.buyPrice);
                setStock(res.data.stock);

                if (res.data.photo)
                    setImageURL(`${process.env.REACT_APP_STATIC_S3}/images/${res.data.photo}`);

                if (res.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                const result = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                setStores(result.data);
                setStoreID(res.data.store.storeID);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, itemID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/inventory/items/${storeID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_item")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_item")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("item_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("item_store")}*
                                        </IonLabel>
                                            <IonSelect value={storeID}
                                                    className="input-field"
                                                    placeholder={t("item_store")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => setStoreID(e.detail.value)
                                                }>

                                                    {stores.length > 0 && stores.map((store) => 
                                                        (<IonSelectOption key={store._id} value={store._id}>{store.name}</IonSelectOption>)
                                                    )}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("item_name")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("item_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("sell_price")}*
                                        </IonLabel>
                                            <IonInput type="number"
                                                className="input-field"
                                                placeholder={t("sell_price")}
                                                value={price}
                                                onIonInput={
                                                    (e) => setPrice(parseInt(e.detail.value!))
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        color="primary"
                                        >
                                        <IonLabel className="input-label">{t("maintain_stock")}</IonLabel>
                                        <IonToggle color="secondary" checked={stock} onIonChange={e => setStock(e.detail.checked)} />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {stock && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("buy_price")}*
                                        </IonLabel>
                                            <IonInput type="number"
                                                className="input-field"
                                                placeholder={t("buy_price")}
                                                value={buyPrice}
                                                onIonInput={
                                                    (e) => setBuyPrice(parseInt(e.detail.value!))
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        >
                                        <IonLabel position="stacked" color="secondary" className="input-label">{t("item_image")}</IonLabel>
                                        <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)}></input>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {imageURL && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                
                                            <img src={imageURL} 
                                                alt={t("image_preview")}
                                                width="320" />

                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">

                            <IonButton 
                                onClick={handleSubmit}
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("update_item")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ItemDetail;
