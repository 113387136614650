import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

const AddChat: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [upload, setUpload] = useState < File > ();
    
    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
            if (null !== (fileChangeEvent.target.files))
            {
                if(fileChangeEvent.target.files[0]){
                    const file = fileChangeEvent.target.files[0];  
                    if (file && file.name && file.type) {
                        setUpload(file);
                        
                    }
                } 
            }
        }


    const handleSubmit = () => {

        if (!type || type === "") {
            setMessage(t("select_inq_type"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("inq_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("inq_text_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addInq = () => {
          return new Promise((resolve, reject) => {
            api.post('/staffChats/add', { 'employeeID': authInfo.user._id, title, type, text }).then(res => {
      
              return resolve(res.data.staffChat.title);
              
            }).catch(err => reject(err));
  
          });
        }

        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/staffChats/attach/add', formData).then(res => {
                return resolve(res.data.staffChat.title);
              }).catch(err => reject(err));
            });
          }

        if (upload) {
            
            let formData = new FormData();

            formData.append('employeeID', authInfo.user._id);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('text', text);
            formData.append('attachment', upload, 'attachment');

            setShowLoading(true);
            addAttach(formData)
                .then(data => {
                    setPass(data+t("has_been_submitted"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                setShowLoading(true);
                addInq()
                .then(data => {
                
                    setPass(data + t("has_been_submitted"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
            
                    setIserror(true);
                    setShowLoading(false);
                });
            }

      }

     
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("new_chat")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("new_chat")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/home`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                           
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-no-margin">
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle'>{t("contact_info")}</IonCardSubtitle>
                                            </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonList className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("select_type")}</IonLabel>
                                                                <IonSelect value={type}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                    placeholder={t("select_type")}
                                                                    onIonChange={
                                                                        (e) => setType(e.detail.value)
                                                                }>
                                                                     
                                                                    <IonSelectOption value="general">{t("general")}</IonSelectOption>  
                                                                    <IonSelectOption value="query">{t("query")}</IonSelectOption>  
                                                                    <IonSelectOption value="feedback">{t("feedback")}</IonSelectOption>  
                                                                </IonSelect>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                            <IonInput type="text"
                                                                    value={title}
                                                                    onIonInput={
                                                                        (e) => setTitle(e.detail.value!)
                                                                }></IonInput>
                                                        </IonItem>
                                                        
                                                        <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}>
                                                                    <IonLabel position="floating" color="secondary">{t("write_to_us")}</IonLabel>
                                                                    <IonTextarea rows={4} value={text} onIonInput={
                                                                            (e) => setText(e.detail.value!)
                                                                    }></IonTextarea>
                                                                
                                                                </IonItem>

                                                        <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">{t("attachement")}</IonLabel>
                                                                <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={ev => onFileChange(ev)}></input>
                                                            </IonItem>


                                                    </IonList>
                                                </IonCol>
                                            </IonRow>
                                          
                                            <IonRow className='ion-margin-vertical'>
                                                <IonCol className='ion-text-center'>
                                                    <IonButton onClick={handleSubmit}
                                                        className="first-button"
                                                        fill="clear"
                                                        size="small"
                                                        >{t("submit")}</IonButton>

                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
           
        </IonPage>
    );
};

export default AddChat;
