import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    isPlatform,
} from '@ionic/react';
import axios, { AxiosRequestConfig } from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { attach, send } from 'ionicons/icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import FileOpener from "../../../components/FileOpener";

interface ParamTypes {
    inquiryID: string
}
interface Message {
    timestamp: Date,
    name: string,
    userInfo: UserInterface,
    message: Chat
}
interface UserInterface {
    userID: string,
    userName: string,
    userRole: string,
    userPhoto: string
}
interface Chat {
    text: string,
    filename: string
}

const InquiryDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {inquiryID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [userName, setUserName] = useState < string > ("");
    const [userRole, setUserRole] = useState < string > ("");
    const [userPhoto, setUserPhoto] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [createdAt, setCreatedAt] = useState < Date > ();
    const [messages, setMessages] = useState < Message[] > ([]);
    const [status, setStatus] = useState < string > ("");
    const [reply, setReply] = useState < string > ("");
    const [upload, setUpload] = useState < File > ();
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return  valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
      };

    const onFileChange = async (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                const file = fileChangeEvent.target.files[0];  
                if (file && file.name && file.type) {
                    setUpload(file);
                    
                }
            } 
        }
    }

      const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadCircular = async (fileName : string) => {
        const url = process.env.REACT_APP_STATIC_S3 + '/conversations/' + fileName;
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
              }
          };

          const myArray = fileName.split(".");
          const mimeType = getMimeType(myArray[1]);

        if (isPlatform('capacitor'))
        {

            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            try {
                                const result = await axios.get(url, config);
                                if (result.data instanceof Blob)
                                {
                                    const base64 = await convertBlobToBase64(result.data) as string;

                                    const savedFile = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64,
                                        directory: Directory.External
                                    });

                                    if(savedFile.uri)
                                    {
                                        setShowLoading(false);
                                        FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                                    }
                                    else
                                    {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                               
                            }
                            catch(error: any) {
 
                                setMessage(t("download_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const result = await axios.get(url, config);
                        if (result.data instanceof Blob)
                        {
                            const base64 = await convertBlobToBase64(result.data) as string;

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: base64,
                                directory: Directory.External
                            });

                            if(savedFile.uri)
                            {
                                setShowLoading(false);
                                FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                            }
                            else
                            {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                       
                    }
                    catch(error: any) {

                        setMessage(t("download_error"));  
                        setIserror(true);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
                
            }
        }

    }

    const handleSubmit = () => {

        if (!reply || reply === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
            api.post('/inquiries/attach/update', formData).then(res => {
                return resolve(res.data.inquiry.title);
            }).catch(err => reject(err));
            });
        }
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/inquiries/'+inquiryID, { reply, 'adminID': authInfo.user._id }).then(res => {
        
                return resolve(res.data.title);
                
              }).catch(err => reject(err));
    
            });
          }

          if (upload) {
            
            let formData = new FormData();

            formData.append('adminID', authInfo.user._id);
            formData.append('reply', reply);
            formData.append('inquiryID', inquiryID);
            formData.append('attachment', upload, 'attachment');

            setShowLoading(true);
            upAttach(formData)
                .then(data => {
                    setPass(t("message_sent"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            else
            {
                setShowLoading(true);
                upApli()
                .then(data => {
                
                    setPass(t("message_sent"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
            
                    setIserror(true);
                    setShowLoading(false);
                });
            }
      
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/inquiries/' + inquiryID);

                if (result.data.student)
                {
                    setUserName(result.data.student.name);
                    setUserRole("Parent");
                    if (result.data.student.photo)
                    {
                        setUserPhoto(result.data.student.photo);
                    }
                    
                }

                if (result.data.admin)
                {
                    setUserName(result.data.admin.name);
                    setUserRole("Admin");
                    if (result.data.admin.photo)
                    {
                        setUserPhoto(result.data.admin.photo);
                    }
                }

                setTitle(result.data.title);
                setText(result.data.text);
                setType(result.data.type);
                setStatus(result.data.status);
                setCreatedAt(result.data.createdAt);
                
                if (result.data.filename)
                {
                    setFileName(result.data.filename);
                }
                if (result.data.messages)
                {
                    setMessages(result.data.messages);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, inquiryID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{type}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{type}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setReply("");
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">

                    {userRole === "Admin" && (
                    <IonRow className="ion-margin-top chat-admin-row">
                        
                        <IonCol size="2" className="chat-left-img">
                        {(userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${userPhoto}`} alt={userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={userName} /></IonAvatar>)
                            }
                        </IonCol>
                        <IonCol offset="2" size="7">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-admin-card ion-no-margin">
                                        <IonCardHeader>
                                            <IonCardSubtitle>
                                                {title}
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {text}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chat-timestamp">
                                    {createdAt && formatDate(createdAt)}
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2">
                            {(fileName !== "") && isPlatform("capacitor") && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName)}
                                />
                            )}
                             {(fileName !== "") && !isPlatform("capacitor") && (
                                <a href={`${process.env.REACT_APP_STATIC_S3}/conversations/${fileName}`} target="_blank" rel="noreferrer" download> 
                                    <IonIcon 
                                        icon={attach}
                                        size="large"
                                        color="medium"
                                        className="chat-right-attach"
                                    />
                                </a>
                            )}
                        </IonCol>
                        
                    </IonRow>
                    )}

                    {userRole === "Parent" && (
                    <IonRow className="ion-margin-top chat-parent-row">


                        <IonCol offset="1" size="2">
                            {(fileName !== "") && isPlatform("capacitor") && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName)}
                                />
                            )}
                            {(fileName !== "") && !isPlatform("capacitor") && (
                                <a href={`${process.env.REACT_APP_STATIC_S3}/conversations/${fileName}`} target="_blank" rel="noreferrer" download> 
                                    <IonIcon 
                                        icon={attach}
                                        size="large"
                                        color="medium"
                                        className="chat-right-attach"
                                    />
                                </a>
                            )}
                        </IonCol>
                        <IonCol size="7" className="ion-text-right">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-parent-card ion-no-margin">
                                        <IonCardHeader>
                                            <IonCardSubtitle>
                                                {title}
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {text}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chat-timestamp">
                                    {createdAt && formatDate(createdAt)}
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className="chat-right-img">
                        {(userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${userPhoto}`} alt={userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={userName} /></IonAvatar>)
                            }
                        </IonCol>
                        
                    </IonRow>
                    )}

                {(messages.length > 0) && (messages.map((message, i) => {
                    if (message.userInfo.userRole === "Parent")
                    {
                    return (
                    <IonRow key={i} className="ion-margin-top chat-parent-row">
                        <IonCol offset="1" size="2">
                            {(message.message.filename && message.message.filename !== "") && isPlatform("capacitor") && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    color="medium"
                                    className="chat-left-attach"
                                    onClick={() => downloadCircular(message.message.filename)}
                                />
                            )}
                             {(message.message.filename && message.message.filename !== "") && !isPlatform("capacitor") && (
                                <a href={`${process.env.REACT_APP_STATIC_S3}/conversations/${message.message.filename}`} target="_blank" rel="noreferrer" download> 
                                    <IonIcon 
                                        icon={attach}
                                        size="large"
                                        color="medium"
                                        className="chat-right-attach"
                                    />
                                </a>
                            )}
                        </IonCol>
                        <IonCol size="7" className="ion-text-right">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {message.userInfo.userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-parent-card ion-no-margin">
                                       
                                        <IonCardContent>
                                            {message.message.text}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chat-timestamp">
                                    {message.timestamp && formatDate(message.timestamp)}
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className="chat-right-img">
                        {(message.userInfo.userPhoto && message.userInfo.userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${message.userInfo.userPhoto}`} alt={message.userInfo.userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={message.userInfo.userName} /></IonAvatar>)
                            }
                        </IonCol>
                        
                    </IonRow>);
                    }
                    else
                    {
                    return (
                    <IonRow key={i} className="ion-margin-top chat-admin-row">
                        
                        <IonCol size="2" className="chat-left-img">
                        {(message.userInfo.userPhoto && message.userInfo.userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${message.userInfo.userPhoto}`} alt={message.userInfo.userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={message.userInfo.userName} /></IonAvatar>)
                            }
                        </IonCol>
                        <IonCol offset="2" size="7">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {message.userInfo.userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-admin-card ion-no-margin">
                                     
                                        <IonCardContent>
                                        {message.message.text}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chat-timestamp">
                                {message.timestamp && formatDate(message.timestamp)}
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2">
                            {(message.message.filename && message.message.filename !== "") && isPlatform("capacitor") && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    color="medium"
                                    className="chat-right-attach"
                                    onClick={() => downloadCircular(message.message.filename)}
                                />
                            )}
                             {(message.message.filename && message.message.filename !== "") && !isPlatform("capacitor") && (
                                <a href={`${process.env.REACT_APP_STATIC_S3}/conversations/${message.message.filename}`} target="_blank" rel="noreferrer" download> 
                                    <IonIcon 
                                        icon={attach}
                                        size="large"
                                        color="medium"
                                        className="chat-right-attach"
                                    />
                                </a>
                            )}
                        </IonCol>
                        
                    </IonRow>
                        );
                    }
                }))}

                    
                </IonGrid>
            </IonContent>
            {(status === "pending") && (
            <IonFooter className='ion-no-padding footer-shade-grey'>
                    <IonRow>
                        <IonCol>
                            <IonItem detail={false}
                                lines='none'
                                button={false}
                                >
                                <IonIcon
                                    slot="start"
                                    icon={attach}
                                    id="open-upload-input"
                                />
                                <IonInput
                                    value={reply}
                                    placeholder={t("type_a_message")}
                                    onIonInput={ev => setReply(ev.detail.value!)} 
                                    onKeyUp={(e) => (e.key === 'Enter') && handleSubmit()}/>
                                <IonIcon
                                    slot="end"
                                    icon={send}
                                    onClick={handleSubmit}
                                />
                            </IonItem>
                                
                        </IonCol>
                    </IonRow>
                    <IonPopover trigger="open-upload-input" className='offset-y-fix'>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("attachement")}*</IonLabel>
                                                <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                    </IonPopover>
            </IonFooter>
            )}
        </IonPage>
    );
};

export default InquiryDetail;
