import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { Filesystem } from '@capacitor/filesystem';
import { SendIntent } from "send-intent";
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    uri?: string
}

const AddBill: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {uri} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [imageURL, setImageURL] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");

    const onFileChange = (fileChangeEvent : React.ChangeEvent<HTMLInputElement>) => {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
        }    
    }

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
      };

    const handleTags = (value : string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    }

    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage(t("tran_mode_mandatory"));
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage(t("tran_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        if (!uploadFile) {
            setMessage(t("bill_format"));
            setIserror(true);
            return;
        }

        if(!tags.length)
        {
            setMessage(t("tags_mandatory"));
            setIserror(true);
            return;
        }

        if(!(typeof(selectedDate) === "string"))
        {
            setMessage(t("tran_date_mandatory"));
            setIserror(true);
            return;
        }


        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  

        const uploadBill = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/transactions/addBill', formData).then(res => {
                return resolve(res.data.transaction.remarks);
              }).catch(err => reject(err));
    
            });
          }
          
      // Change uploadFile to blob in native implementation using capacitor plugin?
      let formData = new FormData();
      formData.append('bill', uploadFile, uploadFile.name);
      formData.append('instituteID', authInfo.user.instituteID);
      formData.append('remarks', remarks);
      formData.append('mode', mode);
      formData.append('amount', amount.toString());
      formData.append('dated', selectedDate);
      formData.append('tags', JSON.stringify(tags));
      formData.append('adminID', authInfo.user._id);

        setShowLoading(true);
        uploadBill(formData)
          .then(data => {
            
            setPass(data+t("has_been_uploaded"));
            setIspass(true);
            setShowLoading(false);
          })
          .catch(error => {
          
            if (uri)
            {
                if (error.response !== undefined)
                    setMessage1(error.response.data.message);
                else
                    setMessage1(t("invalid_bill"));
                    
                setIserror1(true);
            }
            else
            {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));

                setIserror(true);
            }

            setShowLoading(false);
          });
        
    }

    useEffect(() => {

        const getMimeType = (ext: string) => {
            let mime = "";
            if (ext === 'jpg')
                mime = 'image/jpeg';
            if (ext === 'jpeg')
                mime = 'image/jpeg';
            if (ext === 'png')
                mime = 'image/png';
            if (ext === 'pdf')
                mime = 'application/pdf';
            return mime;
        };

        const b64toBlob = (b64Data: any, contentType: string ) => {
            const sliceSize = 512;
            const byteCharacters = window.atob(b64Data);
            const byteArrays = [];
          
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);
          
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
          
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
          
            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
          }

          const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
            })

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (uri)
                {
                    const sharedURI = decodeURIComponent(uri);
                    const myArray = sharedURI.split(".");
                    const len = myArray.length;
                    const ext = myArray[len-1];
                    const name = myArray[len-2]+'.'+ext;
                    const mimeType = getMimeType(ext);

                    setFileExt(ext);

                    if (mimeType === "")
                    {
                        setMessage1(t("bill_format"));
                        setIserror1(true);
                    }
                    else
                    {
                        const contents = await Filesystem.readFile({
                            path: sharedURI
                          });

                        const blob = b64toBlob(contents.data, mimeType);
                        
                        const sharedFile = new File( [blob], name, { type: mimeType } );
                        setUploadFile(sharedFile);

                        if (ext !== 'pdf')
                        {
                            const imageUrl = await fileToDataUri(sharedFile);
                            setImageURL(imageUrl);
                        }

                    }
                }
                setShowLoading(false);

            } catch (error : any) {

                setMessage1(t("invalid_bill"));
                setIserror1(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [uri]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_bill")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_bill")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => uri ? SendIntent.finish() : history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("bill_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>

                    {uri && (
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("bill_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(imageURL) &&
                                        (
                                            <img src={imageURL} 
                                                alt={t("bill_preview")} 
                                                width="320" />
                                       )
                                    }

                                     {(fileExt) && (fileExt === 'pdf') &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                  
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                  
                    {!uri && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        >
                                        <IonLabel position="stacked" color="secondary" className="input-label">{t("bill_file")}*</IonLabel>
                                        <input className="ion-padding" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={ev => onFileChange(ev)}></input>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_date")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_remarks")}*
                                            </IonLabel>
                                            <IonInput value={remarks} 
                                                placeholder={t("tran_remarks")}
                                                type="text"
                                                className="input-field" 
                                                onIonInput={ev => setRemarks(ev.detail.value!)}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_mode")}*
                                            </IonLabel>
                                            <IonSelect placeholder={t("tran_mode")}
                                                className='input-field' 
                                                style={{'maxWidth': '100%'}} 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                value={mode}
                                                onIonChange={
                                                    (e) => setMode(e.detail.value!)
                                                }
                                                >
                                                <IonSelectOption value="cash">{t("cash")}</IonSelectOption>
                                                <IonSelectOption value="cheque">{t("cheque")}</IonSelectOption>      
                                                <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_amount")}*
                                            </IonLabel>
                                        <IonInput type="number"
                                            value={amount}
                                            placeholder={t("tran_amount")}
                                            className="input-field" 
                                            onIonInput={
                                                (e) => setAmount(parseInt(e.detail.value!))
                                        }></IonInput>
                                    </IonItem>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_tags")}*
                                            </IonLabel>
                                        <IonInput type="text"
                                            placeholder={t("tran_tags")}
                                            className="input-field" 
                                            value={tags.join(' ')}
                                            onIonInput={
                                                (e) => handleTags(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal">
                            <IonText className='info-text'>
                                {t("tags_info")}<br/><br/>
                                {t("sample_tags")}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_bill")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default AddBill;