import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import InventoryHome from "../pages/Home/InventoryHome";
import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import ManageStores from "../pages/Employee/Dashboard/ManageStores";
import ManageVendors from "../pages/Employee/Dashboard/ManageVendors";
import TransactItems from "../pages/Employee/Dashboard/TransactItems";

import ItemDetail from "../pages/Admin/Inventory/Item/ItemDetail";
import ItemLedger from "../pages/Admin/Inventory/Item/ItemLedger";
import ItemReports from "../pages/Admin/Inventory/Item/ItemReports";
import Items from "../pages/Admin/Inventory/Item/Items";
import AddItem from "../pages/Admin/Inventory/Item/AddItem";
import AddCombinedItem from "../pages/Admin/Inventory/Item/AddCombinedItem";
import CombinedItems from "../pages/Admin/Inventory/Item/CombinedItems";
import CombinedItemDetails from "../pages/Admin/Inventory/Item/CombinedItemDetails";
import AddStore from "../pages/Admin/Inventory/Store/AddStore";
import StoreDetail from "../pages/Admin/Inventory/Store/StoreDetail";
import Stores from "../pages/Admin/Inventory/Store/Stores";
import BuyItem from "../pages/Admin/Inventory/Transact/BuyItem";
import ItemTransactions from "../pages/Admin/Inventory/Transact/ItemTransactions";
import SellCombinedItem from "../pages/Admin/Inventory/Transact/SellICombinedItem";
import SellItem from "../pages/Admin/Inventory/Transact/SellItem";
import AddVendor from "../pages/Admin/Inventory/Vendor/AddVendor";
import VendorDetail from "../pages/Admin/Inventory/Vendor/VendorDetail";
import VendorLedger from "../pages/Admin/Inventory/Vendor/VendorLedger";
import VendorPayment from "../pages/Admin/Inventory/Vendor/VendorPayment";
import VendorReports from "../pages/Admin/Inventory/Vendor/VendorReports";
import Vendors from "../pages/Admin/Inventory/Vendor/Vendors";

import MyTasks from "../pages/Employee/Task/MyTasks";
import MyTaskDetail from "../pages/Employee/Task/MyTaskDetail";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const InventoryTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={InventoryHome} />
            <Route path="/app/news" exact={true} component={News} />
            <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

            <Route path="/app/stores" exact={true} component={ManageStores} />
            <Route path="/app/manage/vendors" exact={true} component={ManageVendors} />
            <Route path="/app/transact" exact={true} component={TransactItems} />

            <Route path="/app/inventory/stores/add" exact={true} component={AddStore} />
            <Route path="/app/inventory/stores" exact={true} component={Stores} />
            <Route path="/app/store/:storeID" exact={true} component={StoreDetail} />
            <Route path="/app/inventory/item/add/:store?" exact={true} component={AddItem} />
            <Route path="/app/inventory/items/:storeID?" exact={true} component={Items} />
            <Route path="/app/item/:itemID" exact={true} component={ItemDetail} />
            <Route path="/app/item/ledger/:itemID" exact={true} component={ItemLedger} />
            <Route path="/app/inventory/combos" exact={true} component={CombinedItems} />
            <Route path="/app/inventory/combos/add" exact={true} component={AddCombinedItem} />   
            <Route path="/app/combo/:comboID" exact={true} component={CombinedItemDetails} />
            <Route path="/app/inventory/reports" exact={true} component={ItemReports} />
            <Route path="/app/vendors/add" exact={true} component={AddVendor} />
            <Route path="/app/vendors" exact={true} component={Vendors} />
            <Route path="/app/vendor/:vendorID" exact={true} component={VendorDetail} />
            <Route path="/app/vendor/ledger/:vendorID" exact={true} component={VendorLedger} />
            <Route path="/app/vendors/payment/:vendorID?" exact={true} component={VendorPayment} />
            <Route path="/app/vendors/reports" exact={true} component={VendorReports} />   
            <Route path="/app/inventory/transact/buy/:itemID?" exact={true} component={BuyItem} />
            <Route path="/app/inventory/transact/sell/:itemID?" exact={true} component={SellItem} />
            <Route path="/app/inventory/combos/sell/:comboID?" exact={true} component={SellCombinedItem} />
            <Route path="/app/inventory/transactions" exact={true} component={ItemTransactions} />   

            <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
            <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
            <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />

            <Route path="/app/user/tasks" exact={true} component={MyTasks} />
            <Route path="/app/user/task/:taskID" exact={true} component={MyTaskDetail} />
            <Route path="/app/user/reports" exact={true} component={DailyReports} />
            <Route path="/app/user/chats" exact={true} component={MyChats} />
            <Route path="/app/user/chat" exact={true} component={AddChat} />
            <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />
            
            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />
            
            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
  );
};

export default InventoryTabs;
