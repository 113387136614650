import {
  IonIcon,
  IonLabel,
  IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { calendarNumber, chatbubbleEllipses, home, newspaper } from "ionicons/icons";

import ParentHome from "../pages/Home/ParentHome";
import AdmissionHome from "../pages/Home/AdmissionHome";

import StudentAttendance from "../pages/Parent/Attendance/StudentAttendance";
import Incidents from "../pages/Parent/Medical/Incidents";
import IncidentDetail from "../pages/Parent/Medical/IncidentDetail";

import Planner from "../pages/Parent/Academics/Planner";
import MySubmissions from "../pages/Parent/Academics/MySubmissions";
import TopicSubmission from "../pages/Parent/Academics/TopicSubmission";
import TopicAssessment from "../pages/Parent/Academics/TopicAssessment";
import ChildStats from "../pages/Parent/Academics/ChildStats";
import Circulars from "../pages/Parent/Circular/Circulars";
import CircularDetail from "../pages/Parent/Circular/CircularDetail";

import CompletedTrips from "../pages/Parent/Trip/CompletedTrips";
import OngoingTrip from "../pages/Parent/Trip/OngoingTrip";
import TripDetail from "../pages/Parent/Trip/TripDetail";

import Inquiries from "../pages/Parent/Inquiry/Inquiries";
import AddInquiry from "../pages/Parent/Inquiry/AddInquiry";
import InquiryDetail from "../pages/Parent/Inquiry/InquiryDetail";

import Dues from "../pages/Parent/Tran/Dues";
import PayNow from "../pages/Parent/Tran/PayNow";
import TranSuccess from "../pages/Parent/Tran/TranSuccess";
import TranFailed from "../pages/Parent/Tran/TranFailed";
import ReceiptDetail from "../pages/Admin/Transaction/ReceiptDetail";

import SchoolProfile from '../pages/General/SchoolProfile';
import CourseDetail from '../pages/General/CourseDetail';
import AllWorkshops from "../pages/General/AllWorkshops";
import WorkshopDetail from '../pages/General/WorkshopDetail';

import BookWorkshop from "../pages/Admission/Workshop/BookWorkshop";
import Workshops from "../pages/Admission/Workshop/Workshops";
import WorkshopHistory from "../pages/Admission/Workshop/WorkshopHistory";
import Dashboard from "../pages/Admission/Dashboard";
import Shortlisted from "../pages/Admission/Shortlisted";
import Apply from "../pages/Admission/Application/Apply";
import CommonForm from "../pages/Admission/Form/CommonForm";
import ApplicationDetail from "../pages/Admission/Application/ApplicationDetail";
import PendingApplications from "../pages/Admission/Application/PendingApplications";
import SubmittedApplications from "../pages/Admission/Application/SubmittedApplications";
import StudentProfile from "../pages/Admission/Student/StudentProfile";
import Students from "../pages/Admission/Student/Students";
import AddStudent from "../pages/Admission/Student/AddStudent";
import Payments from "../pages/Admission/Payment/Payments";
import Checkout from "../pages/Admission/Payment/Checkout";
import AppSuccess from "../pages/Admission/Payment/AppSuccess";
import AppFailed from "../pages/Admission/Payment/AppFailed";

import AdmnInquiries from "../pages/Admission/Inquiry/AdmnInquiries";
import AdmnInquiryDetail from "../pages/Admission/Inquiry/AdmnInquiryDetail";
import Appointments from "../pages/Admission/Application/Appointments";

import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdateStudent from "../pages/User/UpdateStudent";
import StudentLocation from "../pages/User/StudentLocation";
import StudentGuardians from "../pages/User/StudentGuardians";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { useTranslation } from "react-i18next";

const ParentTabs: React.FC = () => {
  const {t} = useTranslation();
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={ParentHome} />
            <Route path="/app/admission" exact={true} component={AdmissionHome} />

            <Route path="/app/planner" exact={true} component={Planner} />
            <Route path="/app/user/submissions" exact={true} component={MySubmissions} />
            <Route path="/app/submissions/topic/:topicID" exact={true} component={TopicSubmission} />
            <Route path="/app/assessments/topic/:topicID" exact={true} component={TopicAssessment} />
            <Route path="/app/child/stats" exact={true} component={ChildStats} />
            <Route path="/app/attendance" exact={true} component={StudentAttendance} />

            <Route path="/app/pay" exact={true} component={PayNow} />
            <Route path="/app/success/:tranID" exact={true} component={TranSuccess} />
            <Route path="/app/failed/:tranID" exact={true} component={TranFailed} />
            <Route path="/app/dues" exact={true} component={Dues} />
            <Route path="/app/receipt/:receiptID" exact={true} component={ReceiptDetail} />

            <Route path="/app/medical" exact={true} component={Incidents} />
            <Route path="/app/incident/:incidentID" exact={true} component={IncidentDetail} />

            <Route path="/app/circulars" exact={true} component={Circulars} />
            <Route path="/app/circular/:circularID" exact={true} component={CircularDetail} />
            <Route path="/app/inquiries" exact={true} component={Inquiries} />
            <Route path="/app/newinquiry/:topicName?" exact={true} component={AddInquiry} />
            <Route path="/app/inquiry/:inquiryID" exact={true} component={InquiryDetail} />

            <Route path="/app/trip/completed" exact={true} component={CompletedTrips} />
            <Route path="/app/trip/ongoing/:tripID/:studentID" exact={true} component={OngoingTrip} />
            <Route path="/app/trip/detail/:tripID/:studentID" exact={true} component={TripDetail} />

            <Route path="/app/dashboard" exact={true} component={Dashboard} />
            <Route path="/app/shortlisted" exact={true} component={Shortlisted} />
            <Route path="/app/school/:schoolID" exact={true} component={SchoolProfile}/>
            <Route path="/app/school/:schoolID/course/:courseID" exact={true} component={CourseDetail}/>
            <Route path="/app/workshops" exact={true} component={AllWorkshops} />
            <Route path="/app/workshop/:workshopID" exact={true} component={WorkshopDetail}/>
            <Route path="/app/workshop/:workshopID/book" exact={true} component={BookWorkshop}/>
            <Route path="/app/workshops/upcoming" exact={true} component={Workshops} />
            <Route path="/app/workshops/attended" exact={true} component={WorkshopHistory} />
            
            <Route path="/app/applications/pending" exact={true} component={PendingApplications} />
            <Route path="/app/applications/submitted" exact={true} component={SubmittedApplications} />
            <Route path="/app/form/:applicationID" exact={true} component={ApplicationDetail} />
            <Route path="/app/payments" exact={true} component={Payments} />
            <Route path="/app/students" exact={true} component={Students} />
            <Route path="/app/students/add" exact={true} component={AddStudent} />
            <Route path="/app/student/:studentID" exact={true} component={StudentProfile} />
            <Route path="/app/application/:schoolID/:classID" exact={true} component={Apply} />
            <Route path="/app/application/:schoolID/:classID/:studentID" exact={true} component={CommonForm} />
            <Route path="/app/application/:applicationID" exact={true} component={Checkout} />
            <Route path="/app/checkout/success/:tranID" exact={true} component={AppSuccess} />
            <Route path="/app/checkout/failed/:tranID" exact={true} component={AppFailed} />
            <Route path="/app/admission/inquiries" exact={true} component={AdmnInquiries} />
            <Route path="/app/admission/inquiry/:inquiryID" exact={true} component={AdmnInquiryDetail} />
            <Route path="/app/appointments" exact={true} component={Appointments} />

            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/student" exact={true} component={UpdateStudent} />
            <Route path="/app/user/profile/student/location" exact={true} component={StudentLocation} />
            <Route path="/app/user/profile/student/guardians" exact={true} component={StudentGuardians} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />
            
            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="circular" href="/app/circulars" className="tab-button" target="_blank">
          <IonIcon icon={newspaper} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("circulars")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="child" href="/app/inquiries" className="tab-button" target="_blank">
          <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_inquiries")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="planner" href="/app/planner" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_time_table")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default ParentTabs;
