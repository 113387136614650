import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Project {
    _id: string,
    name: string,
    status: string,
    startDate: Date,
    endDate: Date,
    tasksAssigned: number,
    tasksCompleted: number,
    isActive: boolean,
    createdAt: Date
}

const Projects: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [projects, setProjects] = useState < Project[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const handleScrollEnd = async () => {
        
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
        
      }

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;

        try {
            const result = await api.get(`/projects/listInstitute/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data.projects && result.data.projects.length)
            {
                setProjects([...projects, ...result.data.projects]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        } 
    }

    useEffect(() => {

        if (history.action === "POP") {
            // Check if data is present in local storage
            const storedState = JSON.parse(sessionStorage.getItem(history.location.pathname) || '{}');

            if (Object.keys(storedState).length > 0) 
            {
                // Data is present in local storage, use it to initialize state
                setProjects(storedState.projects || []);
                setTotal(storedState.total || 0);
                setSkip(storedState.skip || 0);
               
                setTimeout(() => storedState.scrollPosition && scrollContent.current?.scrollToPoint(0, parseInt(storedState.scrollPosition, 10)), 0);
            } 
            else 
            {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    timeout: parseInt(process.env.REACT_APP_API_TO!),
                    headers: {
                        'Authorization': 'Bearer ' + authInfo.token
                    }
                });

                const fetchUp = async () => {
                    
                    setShowLoading(true);
                    try {
                        const result = await api.get(`/projects/listInstitute/${authInfo.user.instituteID}`);
                        setProjects(result.data.projects);
                        setTotal(result.data.total);
                        setSkip(0);
                        setShowLoading(false);

                    } catch (error : any) {
                        if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(t("something_went_wrong"));
                        setIserror(true);
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
        }
        else
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {
                
                setShowLoading(true);
                try {
                    const result = await api.get(`/projects/listInstitute/${authInfo.user.instituteID}`);
                    setProjects(result.data.projects);
                    setTotal(result.data.total);
                    setSkip(0);
                    setShowLoading(false);

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            projects,
            total,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [projects, total, skip, scrollPosition, history.location.pathname, initialRender]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_projects")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_projects")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="4" size="7" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("project_name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("current_status")}
                            </IonCol>
                            <IonCol size="4" className='table-heading'>
                            {t("dates")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(projects.length > 0) ? (projects.map((project, i) => {
                            return (
                                <IonRow key={project._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => setCurrent(project._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/project/${project._id}`)
                                }>
                                    
                                        <IonCol sizeLg="4" size="7" className='table-field'>
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={project._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{project.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {t(project.status)} 
                                                        {(project.tasksAssigned > 0) && (
                                                        <>
                                                        <IonText> &#8226; {Math.round((project.tasksCompleted/project.tasksAssigned)*100)} % </IonText>
                                                        <IonProgressBar value={(project.tasksCompleted/project.tasksAssigned)} color="secondary" className='project-progress'></IonProgressBar>
                                                        </>)}
                                                        
                                        
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>
                                            
                                        </IonCol>
                                        <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                            {t(project.status)}
                                            {(project.tasksAssigned > 0) && (
                                            <>
                                                <IonText> &#8226; {Math.round((project.tasksCompleted/project.tasksAssigned)*100)} % </IonText>
                                                <IonProgressBar value={(project.tasksCompleted/project.tasksAssigned)} color="secondary" className='project-progress'></IonProgressBar>
                                            </>
                                            )}
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {formatDated(project.startDate)} {t("to")} {formatDated(project.endDate)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color={project.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/project/${project._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_projects")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(projects.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${projects.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_project"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/tasks/manage/${current}`)
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_tasks")}</IonButton>

                                <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_project"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/tasks/add/${current}`)
                                            }
                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("add_task")}</IonButton>
                                    
                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_project"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/project/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("manage_project")}</IonButton>

                           <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>


                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Projects;