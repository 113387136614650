import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { addCircle, alertCircleOutline, closeCircle } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    classID?: string
}

interface Component {
    head?: FeeHead,
    frequency?: string,
    duration?: string,
    remarks?: string,
    amount: number,
    isChecked?: boolean
}

interface FeeHead {
    headID: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Student {
    _id: string,
    name: string,
    dueDate?: string,
    feeComponents?: Component[]
}

const AddManyDues: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [invoiceDate, setInvoiceDate] = useState < string | string[] | undefined > ();
    const [monthly, setMonthly] = useState < boolean > (false);
    const [quaterly, setQuaterly] = useState < boolean > (false);
    const [annually, setAnnually] = useState < boolean > (false);
    const [mDuration, setMduration] = useState < string > ("");
    const [qDuration, setQduration] = useState < string > ("");
    const [aDuration, setAduration] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            setSelectedDate(value);
            let studs = [...students];
            for (let j = 0; j < studs.length; j++)
            {
               studs[j].dueDate = value;
            }
            setStudents(studs);
        }
    }

    const handleChecked = (value: string) => {

        if (value === "monthly")
        {
            //already true set to false
            if(monthly) 
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "monthly")
                            {
                                studs[j].feeComponents![i].isChecked = false;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setMonthly(false);
            }
            if(!monthly)
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "monthly")
                            {
                                studs[j].feeComponents![i].isChecked = true;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setMonthly(true);
            }
        }

        if (value === "quaterly")
        {
            //already true set to false
            if(quaterly) 
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "quaterly")
                            {
                                studs[j].feeComponents![i].isChecked = false;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setQuaterly(false);
            }
            if(!quaterly)
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "quaterly")
                            {
                                studs[j].feeComponents![i].isChecked = true;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setQuaterly(true);
            }
        }

        if (value === "annually")
        {
            //already true set to false
            if(annually) 
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "annually")
                            {
                                studs[j].feeComponents![i].isChecked = false;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setAnnually(false);
            }
            if(!annually)
            {
                let studs = [...students];
                for (let j = 0; j < studs.length; j++)
                {
                    if (studs[j].feeComponents)
                    {
                        for (let i = 0; i < studs[j].feeComponents!.length; i++)
                        {
                            if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "annually")
                            {
                                studs[j].feeComponents![i].isChecked = true;
                            }
                        }
                    }
                    
                }
                setStudents(studs);
                setAnnually(true);
            }
        }

    }

    const handleMduration = (value: string) => {
  
        let studs = [...students];
        for (let j = 0; j < studs.length; j++)
        {
            if (studs[j].feeComponents)
            {
                for (let i = 0; i < studs[j].feeComponents!.length; i++)
                {
                    if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "monthly")
                    {
                        studs[j].feeComponents![i].duration = value;
                    }
                }
            }
            
        }
        setStudents(studs);
        setMduration(value);
            
    }

    const handleQduration = (value: string) => {
  
        let studs = [...students];
        for (let j = 0; j < studs.length; j++)
        {
            if (studs[j].feeComponents)
            {
                for (let i = 0; i < studs[j].feeComponents!.length; i++)
                {
                    if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "quaterly")
                    {
                        studs[j].feeComponents![i].duration = value;
                    }
                }
            }
            
        }
        setStudents(studs);
        setQduration(value);
    
    }


    const handleAduration = (value: string) => {
    
        let studs = [...students];
        for (let j = 0; j < studs.length; j++)
        {
            if (studs[j].feeComponents)
            {
                for (let i = 0; i < studs[j].feeComponents!.length; i++)
                {
                    if (studs[j].feeComponents![i].frequency && studs[j].feeComponents![i].frequency === "annually")
                    {
                        studs[j].feeComponents![i].duration = value;
                    }
                }
            }
            
        }
        setStudents(studs);
        setAduration(value);

    }

    const handleIsChecked = (index: number, j: number) => {
        let studs = [...students];
        studs[j].feeComponents![index].isChecked = !studs[j].feeComponents![index].isChecked;
        setStudents(studs);
    }

    const handleAmount = (amt: string, index: number, j: number) => {
        let studs = [...students];
        studs[j].feeComponents![index].amount = parseInt(amt);
        setStudents(studs);
    }

    const handleDueDate = (dated: string | string[], j: number) => {
        if (typeof dated === "string") 
        {
            let studs = [...students];
            studs[j].dueDate = dated;
            setStudents(studs);
        }
    }

    const handleDuration = (dur: string, index: number, j: number) => {
        let studs = [...students];
        studs[j].feeComponents![index].duration = dur;
        setStudents(studs);
    }
        
    const handleRemarks = (freq: string, index: number, j: number) => {
        let studs = [...students];
        studs[j].feeComponents![index].remarks = freq;
        setStudents(studs);
    }

    const handleDelete = (index: number, j: number) => {
        let studs = [...students];
        if (index > -1) {
            studs[j].feeComponents!.splice(index, 1); // 2nd parameter means remove one item only
          }
          setStudents(studs);
    }

    const handleAdd = (j: number) => {
        let studs = [...students];
        if(studs[j].feeComponents)
        {
            studs[j].feeComponents!.push({remarks: "", amount: 0, isChecked: true});
        }
        else
        {
            studs[j].feeComponents = [{remarks: "", amount: 0, isChecked: true}];
        }
        setStudents(studs);
    }

    const handleSubmit = () => {

        if (students.length < 1)
        {
            setMessage(t("no_students_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof invoiceDate !== "string")
        {
            setMessage(t("invoice_date_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < students.length; i++)
        {
            if (students[i].feeComponents)
            {

                for (let j = 0; j < students[i].feeComponents!.length; j++)
                {
                    if (students[i].feeComponents![j].isChecked)
                    {
                        if (!students[i].dueDate)
                        {
                            setMessage(t("due_date_mandatory"));
                            setIserror(true);
                            return;
                        }
                        if (!students[i].feeComponents![j].amount)
                        {
                            setMessage(t("dues_invalid"));
                            setIserror(true);
                            return;
                        }
                        if (students[i].feeComponents![j].head)
                        {
                            if (!students[i].feeComponents![j].duration || students[i].feeComponents![j].duration === "") {
                                setMessage(t("dues_invalid"));
                                setIserror(true);
                                return;
                            } 
                        }
                        if (!students[i].feeComponents![j].head)
                        {
                            if (!students[i].feeComponents![j].remarks || students[i].feeComponents![j].remarks === "") {
                                setMessage(t("dues_invalid"));
                                setIserror(true);
                                return;
                            }
                        }
                    }
                }
            }
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const classDue = () => {
          return new Promise((resolve, reject) => {
            api.post(`/invoices/addMany`, { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, students, invoiceDate }).then(res => {
      
              return resolve(res.data);
              
            }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
        classDue()
        .then((data: any) => {
        
            setPass(t("total_added")+data.sum+"\n"+t("added_students")+data.num);
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));

            setIserror(true);
            setShowLoading(false);
        });
        
      }

      const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setStudents([]);
    }

    const handleClassi = (classID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/fee/getByClass/${classID}`);
                setStudents(studs.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
    }


      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (classID)
                {
                    const result = await api.get(`/classes/${classID}`);
                    setSession(result.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                    setClasses(query.data);
                    setClassi(classID);
                    const studs = await api.get(`/students/fee/getByClass/${classID}`);
                    setStudents(studs.data);
                }
                else
                {
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("add_dues_class") : t("add_dues_batch")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("add_dues_class") : t("add_dues_batch")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder= {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                           
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("invoice_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-invoice-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("invoice_date")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(invoiceDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-invoice-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("invoice_date")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={invoiceDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setInvoiceDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("due_date")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("due_date")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => handleDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("fee_heads")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="6" className='table-heading'>
                                                {t("frequency")}
                                        </IonCol>
                                        <IonCol size="6" className='table-heading'>
                                                {t("due_duration")}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="monthly" checked={monthly} 
                                                onClick={e => handleChecked("monthly")} >
                                                {t("monthly")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field' >
                                            <IonItem>
                                                
                                                <IonSelect placeholder={t("due_duration")}
                                                    className='ion-no-padding' 
                                                    style={{'maxWidth': '100%'}} 
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    value={mDuration}
                                                    onIonChange={
                                                        (e) => handleMduration(e.detail.value!)
                                                    }
                                                    >
                                                    <IonLabel>{t("due_duration")}</IonLabel>
                                                    <IonSelectOption value="Jan">{t("jan")}</IonSelectOption>
                                                    <IonSelectOption value="Feb">{t("feb")}</IonSelectOption>      
                                                    <IonSelectOption value="Mar">{t("mar")}</IonSelectOption>   
                                                    <IonSelectOption value="Apr">{t("apr")}</IonSelectOption>   
                                                    <IonSelectOption value="May">{t("may")}</IonSelectOption>
                                                    <IonSelectOption value="Jun">{t("jun")}</IonSelectOption>      
                                                    <IonSelectOption value="Jul">{t("jul")}</IonSelectOption>   
                                                    <IonSelectOption value="Aug">{t("aug")}</IonSelectOption>   
                                                    <IonSelectOption value="Sep">{t("sep")}</IonSelectOption>
                                                    <IonSelectOption value="Oct">{t("oct")}</IonSelectOption>      
                                                    <IonSelectOption value="Nov">{t("nov")}</IonSelectOption>   
                                                    <IonSelectOption value="Dec">{t("dec")}</IonSelectOption>   
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="quaterly" checked={quaterly} 
                                                onClick={e => handleChecked("quaterly")} >
                                                {t("quaterly")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field'>
                                            <IonItem>
                                                
                                                <IonSelect placeholder={t("due_duration")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={qDuration}
                                                        onIonChange={
                                                            (e) => handleQduration(e.detail.value!)
                                                        }
                                                        >
                                                        <IonLabel>{t("due_duration")}</IonLabel>
                                                            <IonSelectOption value="Jan-Mar">{t("q4")}</IonSelectOption>
                                                            <IonSelectOption value="Apr-Jun">{t("q1")}</IonSelectOption>
                                                            <IonSelectOption value="Jul-Sep">{t("q2")}</IonSelectOption>      
                                                            <IonSelectOption value="Oct-Dec">{t("q3")}</IonSelectOption>
                                                            <IonSelectOption value="Feb-Apr">{t("q5")}</IonSelectOption>
                                                            <IonSelectOption value="Mar-May">{t("q6")}</IonSelectOption>      
                                                            <IonSelectOption value="May-Jul">{t("q7")}</IonSelectOption>   
                                                            <IonSelectOption value="Jun-Aug">{t("q8")}</IonSelectOption> 
                                                            <IonSelectOption value="Aug-Oct">{t("q9")}</IonSelectOption>
                                                            <IonSelectOption value="Sep-Nov">{t("q10")}</IonSelectOption>
                                                            <IonSelectOption value="Nov-Jan">{t("q11")}</IonSelectOption>      
                                                            <IonSelectOption value="Dec-Feb">{t("q12")}</IonSelectOption>  
                                                        
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='row-table ion-padding-horizontal'>
                                        <IonCol size="6" className='table-field ion-padding-top'>
                                            <IonCheckbox labelPlacement="end" value="annually" checked={annually} 
                                                onClick={e => handleChecked("annually")} >
                                                {t("annually")} {t("heads")}
                                            </IonCheckbox>
                                        </IonCol>
                                        <IonCol size="6" className='table-field'>
                                            <IonItem>
                                                
                                                <IonSelect placeholder={t("due_duration")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={aDuration}
                                                        onIonChange={
                                                            (e) => handleAduration(e.detail.value!)
                                                        }
                                                        >
                                                        <IonLabel>{t("due_duration")}</IonLabel>
                                                        {sessions.map((session) => (
                                                            (session.isActive) && 
                                                            (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                        ))}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    </div>
                                
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol className='ion-padding-horizontal'>
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("students")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        {(students.length > 0) ? (students.map((stud, j) => {
                        return (
                                <IonCol size="12" sizeLg='6' className='ion-padding' key={stud._id} >
                                    <div className="ion-table">
                                    <IonRow className="table-title-1 ion-padding">
                                        <IonCol size="6" className='table-heading-1 mt-05 text-capitalize'>
                                                {stud.name}
                                        </IonCol>
                                        <IonCol size="6" className="ion-text-right">
                                            <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    id={`open-date-${stud._id}`}
                                                    >
                                                    <IonLabel position="fixed">
                                                    {formatDate(stud.dueDate)}
                                                    </IonLabel>
                                                    
                                                    <IonModal trigger={`open-date-${stud._id}`} showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle className="text-capitalize">{stud.name} - {t("due_date")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={stud.dueDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => handleDueDate(ev.detail.value!, j)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="4" className='table-heading mt-05'>
                                                {t("due_head")}
                                        </IonCol>
                                        <IonCol size="4" className='table-heading mt-05'>
                                                {t("due_duration")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("due_amount")}
                                        </IonCol>

                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={addCircle}
                                                color="light"
                                                className='table-icon ml-05'
                                                size="large"
                                                onClick={() => handleAdd(j)}
                                                />
                                        </IonCol>
                                    </IonRow>
                                
                                    {stud.feeComponents && (stud.feeComponents.length > 0) ? (stud.feeComponents.map((comp, i) => {
                                        return (
                                            <IonRow key={i} className='row-table ion-padding-horizontal'>
                                                {comp.head && (
                                                    <>
                                                    <IonCol size="4" className='table-field field-fixed ion-padding-top overflow-hide' >
                                                        <IonCheckbox labelPlacement="end" value={`${comp.head.headID}${i}`} checked={comp.isChecked} 
                                                            onClick={e => handleIsChecked(i, j)} >
                                                                {comp.head.name}
                                                        </IonCheckbox>
                                                    
                                                    </IonCol>
                                                    <IonCol size="4" className='table-field ion-padding-end' >
                                                        <IonItem>
                                                            {(comp.frequency === "monthly") && (
                                                            <IonSelect placeholder={t("due_duration")}
                                                                className='ion-no-padding' 
                                                                style={{'maxWidth': '100%'}} 
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                value={comp.duration}
                                                                onIonChange={
                                                                    (e) => handleDuration(e.detail.value!, i, j)
                                                                }
                                                                >
                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                <IonSelectOption value="Jan">{t("jan")}</IonSelectOption>
                                                                <IonSelectOption value="Feb">{t("feb")}</IonSelectOption>      
                                                                <IonSelectOption value="Mar">{t("mar")}</IonSelectOption>   
                                                                <IonSelectOption value="Apr">{t("apr")}</IonSelectOption>   
                                                                <IonSelectOption value="May">{t("may")}</IonSelectOption>
                                                                <IonSelectOption value="Jun">{t("jun")}</IonSelectOption>      
                                                                <IonSelectOption value="Jul">{t("jul")}</IonSelectOption>   
                                                                <IonSelectOption value="Aug">{t("aug")}</IonSelectOption>   
                                                                <IonSelectOption value="Sep">{t("sep")}</IonSelectOption>
                                                                <IonSelectOption value="Oct">{t("oct")}</IonSelectOption>      
                                                                <IonSelectOption value="Nov">{t("nov")}</IonSelectOption>   
                                                                <IonSelectOption value="Dec">{t("dec")}</IonSelectOption>   
                                                            </IonSelect>
                                                            )}
                                                            {(comp.frequency === "quaterly") && (
                                                            <IonSelect placeholder={t("due_duration")}
                                                                className='ion-no-padding' 
                                                                style={{'maxWidth': '100%'}} 
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                value={comp.duration}
                                                                onIonChange={
                                                                    (e) => handleDuration(e.detail.value!, i, j)
                                                                }
                                                                >
                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                <IonSelectOption value="Jan-Mar">{t("q4")}</IonSelectOption>
                                                                <IonSelectOption value="Apr-Jun">{t("q1")}</IonSelectOption>
                                                                <IonSelectOption value="Jul-Sep">{t("q2")}</IonSelectOption>      
                                                                <IonSelectOption value="Oct-Dec">{t("q3")}</IonSelectOption>
                                                                <IonSelectOption value="Feb-Apr">{t("q5")}</IonSelectOption>
                                                                <IonSelectOption value="Mar-May">{t("q6")}</IonSelectOption>      
                                                                <IonSelectOption value="May-Jul">{t("q7")}</IonSelectOption>   
                                                                <IonSelectOption value="Jun-Aug">{t("q8")}</IonSelectOption> 
                                                                <IonSelectOption value="Aug-Oct">{t("q9")}</IonSelectOption>
                                                                <IonSelectOption value="Sep-Nov">{t("q10")}</IonSelectOption>
                                                                <IonSelectOption value="Nov-Jan">{t("q11")}</IonSelectOption>      
                                                                <IonSelectOption value="Dec-Feb">{t("q12")}</IonSelectOption>  
                                                                
                                                            </IonSelect>
                                                            )}
                                                            {(comp.frequency === "annually") && (
                                                            <IonSelect placeholder={t("due_duration")}
                                                                className='ion-no-padding' 
                                                                style={{'maxWidth': '100%'}} 
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                value={comp.duration}
                                                                onIonChange={
                                                                    (e) => handleDuration(e.detail.value!, i, j)
                                                                }
                                                                >
                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                {sessions.map((session) => (
                                                                    (session.isActive) && 
                                                                    (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                                ))}
                                                            </IonSelect>
                                                            )}
                                                             {(comp.frequency === "once") && (
                                                            <IonInput type="text"
                                                                placeholder={t("due_remarks")}
                                                                value={comp.duration}
                                                                onIonInput={
                                                                    (e) => handleDuration(e.detail.value!, i, j)
                                                            }></IonInput>
                                                           
                                                            )}
                                                            
                                                        </IonItem>
                                                    
                                                    </IonCol>
                                                    <IonCol size="3" className='table-field'>
                                                        <IonItem className='fee-comp-height'>
                                                            <IonInput type="number"
                                                                className='ion-text-right'
                                                                placeholder='0'
                                                                value={comp.amount}
                                                                onIonInput={
                                                                    (e) => e.detail.value && handleAmount(e.detail.value, i, j)
                                                            }></IonInput>
                                                        </IonItem>
                                                    </IonCol>
                                                    </>
                                                )}
                                                {!comp.head && (
                                                <>
                                                <IonCol size="8" className='table-field ion-padding-end'>
                                                    <IonItem className='fee-comp-height'>
                                                        <IonInput type="text"
                                                            placeholder={t("due_remarks")}
                                                            value={comp.remarks}
                                                            onIonInput={
                                                                (e) => handleRemarks(e.detail.value!, i, j)
                                                        }></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    <IonItem className='fee-comp-height'>
                                                        <IonInput type="number"
                                                            className='ion-text-right'
                                                            placeholder='0'
                                                            value={comp.amount}
                                                            onIonInput={
                                                                (e) => e.detail.value && handleAmount(e.detail.value, i, j)
                                                        }></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                                <IonCol size="1" >
                                                        <IonIcon 
                                                        className='mt-15 ml-05'
                                                            icon={closeCircle}
                                                            color='dark'
                                                            onClick={() => handleDelete(i, j)}
                                                            size="large"
                                                        />

                                                </IonCol>
                                                </>
                                                )}
                                                
                                            
                                            </IonRow>
                                        )
                                    })) : ( 
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_dues_added")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )
                                } 

                                {stud.feeComponents && (stud.feeComponents.length > 0) && (
                                <IonRow className="table-title ion-padding">
                                    <IonCol size="8" className='table-heading heading-fixed mt-05'>
                                            {t("total")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                            {stud.feeComponents.reduce((acc, curr) => { 
                                                if (curr.isChecked) 
                                                    return (acc + curr.amount);
                                                else
                                                    return acc;
                                                }, 0)}
                                    </IonCol>
                                </IonRow>
                                )}
                                </div>
                                
                            </IonCol>
                           
                          )
                        })) : ( 

                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                        )
                    } 
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("generate_invoices")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddManyDues;